import styled from 'styled-components';
import { grid, gridMobile } from '../Variables';

const remW = '0.4rem';
const remH = '0.15rem';

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: calc(${grid.col15});
  margin-top: 30px;
  position: relative;
  z-index: 1;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    flex-direction: column;
    width: calc(${gridMobile.col6});
    margin-top: 40px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
  }
`;

export const ListItem = styled.li`
  flex: 0 1 auto;
  transition: opacity 300ms ease;
  word-break: break-word;

  &:hover {
    @media (min-width: 1024px) {
      opacity: 0.6;
    }
  }

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    ${List} > & + li {
      margin-top: 10px;
    }
  }

  ${List} > & {
    margin-right: calc(${grid.col1} / 2);

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-left: 0;
    }
  }

  & > button {
    text-align: left;
  }

  & > button span {
    font-family: RobotoSlab-Regular, sans-serif;
    font-size: calc(${grid.col7} / 8.5);
    line-height: 1.5;
    color: #fff;
    cursor: pointer;
    background-image: linear-gradient(to right, #fff ${remH}, rgba(255, 255, 255, 0) 0%);
    background-position: 0 93%;
    background-size: ${remW} ${remH};
    background-repeat: repeat-x;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 36px;
      line-height: 69px;
      color: #fff;
      background-size: ${remW} 2px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      font-size: 45px;
      line-height: 76px;
    }
  }
`;
