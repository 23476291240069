import React, { useContext, useEffect, useState } from 'react';
//import Prismic from 'prismic-javascript';
//import { Document } from 'prismic-javascript/types/documents';
import { RouteComponentProps } from '@reach/router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { LocaleContext } from '../layout';
import { MainText, TopSectionHeading } from '../Layout.styles';
import {
  MediaImage,
  MediaList,
  MediaListItem,
  MediaSection,
  MediaTextWrapper,
  Date,
} from './Media.styles';
import Link from '../Link';
import SEO from '../seo';
import { graphql, useStaticQuery } from 'gatsby';
//import { array } from 'prop-types';

//const Client = Prismic.client('https://albufeira.prismic.io/api/v2');
const langs: any = { de: 'de-de', en: 'en-gb', fr: 'fr-fr', pt: 'pt-pt' };

const Media: React.FC<RouteComponentProps> = ({ location }) => {
//  const [listMedias, setListMedias] = useState<Document[]>([]);
  const { t } = useTranslation('media');
  const [locale] = useContext(LocaleContext);

  // useEffect(() => {
     const clang = locale === '' ? 'en' : locale.replace('/', '');
     const lang = langs[clang] ? langs[clang] : null;
  //   const fetchData = async () => {
  //     const response = await Client.query(Prismic.Predicates.at('document.type', 'media'), {
  //       orderings: '[my.media.date desc]',
  //       lang,
  //     });
  //     if (response && response.results) {
  //       setListMedias(response.results);
  //     }
  //   };
  //   fetchData();
  // }, [locale]);

  //console.log(listMedias);
  //console.log(teste.allPrismicMedia.edges);
  //console.log(lang);

  const teste = useStaticQuery(graphql`
  {
   allPrismicMedia(sort: {fields: data___date, order: DESC}) {
     edges {
        node {
          alternate_languages {
            id
            uid
            type
            lang
            slug
          }
          data {
            date
            images {
              image {
                alt
                copyright
                url
              }
            }
            text {
              raw
            }
            title {
              raw
            }
          }
          first_publication_date
          href
          id
          lang
          last_publication_date
          tags
          type
          uid
        }
      }
    }
  }
  `);

const localizedQuery = [];

  for (let node of teste.allPrismicMedia.edges){
    if (node.node.lang == lang) {
      //console.log (node);
      localizedQuery.push(node);
    }
  }
//console.log (localizedQuery);

const currentPath = location?.pathname ? location?.pathname : '';
  
  return (
    <>
      <SEO title={t('Media')} description={t('description')} path={currentPath}/>
      <MediaSection>
        <TopSectionHeading>
          <strong>{t('Media')}</strong>
        </TopSectionHeading>


        {/* <MediaList>
          {listMedias &&
            listMedias.map((item: any, index: number) => (
              <MediaListItem key={index}>
                <Link to={`/media/${item.uid}`}>
                  {item.data.images && item.data.images[0] && item.data.images[0].image.url && (
                    <MediaImage>
                      <img
                        alt={
                          item.data.images[0].image && item.data.images[0].image.alt
                            ? item.data.images[0].image.alt
                            : ''
                        }
                        src={
                          item.data.images[0].image && item.data.images[0].image.url
                            ? item.data.images[0].image.url
                            : ''
                        }
                      />
                    </MediaImage>
                  )}
                  <MediaTextWrapper>
                    {item.data.date && <Date>{moment(`${item.data.date}`).format('LL')}</Date>}
                    {item.data.title[0].text && <MainText>{item.data.title[0].text}</MainText>}
                  </MediaTextWrapper>
                </Link>
              </MediaListItem>
            ))}
        </MediaList> */}

<MediaList>
  {localizedQuery &&
  localizedQuery.map((item: any, index: number) => (
<MediaListItem key={index}>
                <Link to={`/news/${item.node.uid}/`}>
                  {item.node.data.images && item.node.data.images[0] && item.node.data.images[0].image.url && (
                    <MediaImage>
                      <img
                        alt={
                          item.node.data.images[0].image && item.node.data.images[0].image.alt
                            ? item.node.data.images[0].image.alt
                            : ''
                        }
                        src={
                          item.node.data.images[0].image && item.node.data.images[0].image.url
                            ? item.node.data.images[0].image.url
                            : ''
                        }
                      />
                    </MediaImage>
                  )}
                  <MediaTextWrapper>
                    {item.node.data.date && <Date>{moment(`${item.node.data.date}`).format('LL')}</Date>}
                    {item.node.data.title.raw[0].text && <MainText>{item.node.data.title.raw[0].text}</MainText>}
                  </MediaTextWrapper>
                </Link>
              </MediaListItem>
            ))}
</MediaList>


      </MediaSection>
    </>
  );
};



export default Media;
