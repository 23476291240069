import { useStaticQuery, graphql } from 'gatsby';

export const useBeforeAndAfterImages = () => {
  const { image2_1, image2_2, image3_1, image3_2 } = useStaticQuery(graphql`
    query BeforeAndAfterImages {
      image2_1: file(relativePath: { eq: "clinical-cases/before-and-after-1-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2_2: file(relativePath: { eq: "clinical-cases/before-and-after-1-02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3_1: file(relativePath: { eq: "clinical-cases/before-and-after-2-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3_2: file(relativePath: { eq: "clinical-cases/before-and-after-2-02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return [
    //[image2_1.childImageSharp.fluid, image2_2.childImageSharp.fluid], PROVISORIA REMOCAO DO PACIENTE QUE PEDIU PARA DEPOIS SUBSTITUIR POR NOVO
    [image3_1.childImageSharp.fluid, image3_2.childImageSharp.fluid],
  ];
};
