import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { MainText, SectionHeading } from '../Layout.styles';
import { colors, gridMobile, grid, gridTablet } from '../Variables';

export const Heading = styled.h1`
  position: relative;
  font-family: RobotoSlab-Regular, sans-serif;
  font-size: 70px;
  line-height: 78px;
  color: ${colors.black};
  white-space: pre-line;
  z-index: 1;
  margin-top: 40vh;


  @media (max-width: 767.98px){
    width: calc(${gridMobile.col5});
    font-size: calc(${gridMobile.col5} / 7);
    line-height: 1;
    margin-top: 55vh;
  }

  @media (min-width: 2100px) {
    font-size: 95px;
    line-height: 107px;
  }

  @media (min-width: 1920px) {
    font-size: 133px;
    line-height: 150px;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  margin-top: 60px;
  z-index: 1;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    margin-top: 20px;
    margin-bottom: 100px;
    flex-wrap: wrap;
  }

  @media (min-width: 1280px) {
    margin-top: 70px;
  }

  @media (min-width: 1400px) {
    margin-top: 80px;
  }

  a {
    padding: 11px 41px;
    margin-right: 30px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 20px;
      margin-right: 20px;
    }

    @media (min-width: 1440px) {
      margin-right: 40px;
    }
  }

  button {
    font-family: RobotoSlab-Regular, sans-serif;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    color: ${colors.black};
    border-bottom: 1px solid ${colors.blue};
    transition: color 300ms ease, border 300ms ease;
    cursor: pointer;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 20px;
    }

    @media (min-width: 1440px) {
      margin-left: 40px;
      font-size: 15px;
      line-height: 20px;
    }

    @media (min-width: 1920px) {
      font-size: 20px;
      line-height: 26px;
    }

    &:hover {
      color: ${colors.blue};
      border-bottom: 1px solid transparent;

      @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
        color: ${colors.black};
        border-bottom: 1px solid ${colors.blue};
      }

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        color: ${colors.black};
        border-bottom: 1px solid ${colors.blue};
      }
    }
  }
`;

export const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding-right: ${grid.gridMargin};
  padding-left: calc(${grid.navWidth} + ${grid.gridMargin});
  background-color: #f2f2f2;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  overscroll-behavior: none;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    padding-right: ${gridMobile.gridMargin};
    padding-left: ${gridMobile.gridMargin};
    scroll-snap-align: unset;
    scroll-snap-stop: unset;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    padding-top: 190px;
    padding-left: calc(${grid.navWidth} + ${gridTablet.gridMargin});
  }
`;

export const Section = styled(({ isIE11, ...rest }) => <section {...rest} />)`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  overscroll-behavior: none;

  & > div {
    position: ${(props) => (props.isIE11 ? 'static' : 'sticky')};
    top: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    min-height: 100vh;
  }

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    position: static;
    scroll-snap-align: unset;
    scroll-snap-stop: unset;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    position: static;
  }

  & > div > ${MainText} {
    width: calc(${grid.col13});
    margin: 30px 0 80px calc(${grid.navWidth} + ${grid.gridMargin});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: calc(${gridMobile.col6});
      margin: 30px ${gridMobile.gridMargin} 50px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: calc(${gridTablet.col11});
      margin: 30px 0 50px calc(${grid.navWidth} + ${gridTablet.gridMargin});
    }
  }

  & > div > ${SectionHeading} {
    padding: 81px ${grid.gridMargin} 0 calc(${grid.navWidth} + ${grid.gridMargin});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      padding: 60px ${gridMobile.gridMargin} 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      padding: 50px ${gridTablet.gridMargin} 0 calc(${grid.navWidth} + ${gridTablet.gridMargin});
    }
  }
`;

export const ServicesSubItem = styled.div`
  position: relative;
  height: calc(50% - 10px);

  & + div {
    margin-top: ${grid.gridGutter};
  }

  &:hover::before {
    background: rgba(2, 2, 2, 0.15);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(2, 2, 2, 0);
    transition: background 700ms ease;
    z-index: 1;
    pointer-events: none;
  }
`;

export const ServicesItem = styled(({ double, ...rest }) => <div {...rest} />)`
  position: relative;
  flex: 0 0 auto;
  width: calc(${grid.col4});
  cursor: pointer;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(2, 2, 2, 0);
    transition: background 700ms ease;
    z-index: 1;
    pointer-events: none;
  }

  &:hover::before {
    background: rgba(2, 2, 2, 0.15);
  }

  &::before {
    display: ${(props) => (!props.double ? 'block' : 'none')};
  }

  & + div {
    margin-left: ${grid.gridGutter};

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-left: ${gridTablet.gridGutter};
    }
  }

  p {
    position: relative;
    padding: 30px;
    font-family: RobotoSlab-Regular, sans-serif;
    font-size: 19px;
    line-height: 25px;
    color: #fff;
    white-space: pre-line;
    z-index: 2;

    @media (max-width: 1280px) {
      font-size: 17px;
      line-height: 27px;
    }

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      padding: 10px;
      font-size: 12px;
      line-height: 19px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      padding: ${gridTablet.gridMargin};
    }

    @media (min-width: 1920px) {
      font-size: 43px;
      line-height: 58px;
    }
  }
`;

export const ServicesItemBbImage = styled(Img)`
  position: absolute;
  transform: scale(1);
  transition: transform 700ms ease;

  div:hover > & {
    transform: scale(1.2);
  }
`;

export const Services = styled(({ isIE11, isTablet, ...rest }) => <div {...rest} />)`
  flex: 1 0 auto;
  display: flex;
  min-height: calc(${grid.col4});
  margin-top: auto;
  padding-left: calc(${grid.navWidth} + ${grid.gridMargin});
  overflow: hidden;
  backface-visibility: hidden;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  overscroll-behavior: none;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    min-height: unset;
    padding: 0 ${gridMobile.gridMargin} 0px;
    scroll-snap-align: unset;
    scroll-snap-stop: unset;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    min-height: unset;
    max-height: unset;
    padding-right: ${gridTablet.gridMargin};
    padding-bottom: 0;
    padding-left: calc(${grid.navWidth} + ${gridTablet.gridMargin});
  }

  @media (min-width: 1025px) {
    max-height: ${(props) => (props.isIE11 || props.isTablet ? 'none' : '400px')};
  }

  @media (min-width: 1280px) {
    max-height: ${(props) => (props.isIE11 ? 'none' : '450px')};
  }

  @media (min-width: 1400px) {
    max-height: ${(props) => (props.isIE11 ? 'none' : '560px')};
  }

  @media (min-width: 1920px) {
    max-height: ${(props) => (props.isIE11 ? 'none' : '800px')};
  }

  & ${ServicesItem} {
    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: calc(${gridMobile.col3});
      height: calc(${gridMobile.col3} * 1.58);
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      width: calc(${gridTablet.col4});
      height: calc(${gridTablet.col4} * 1.55);
    }
  }

  & ${ServicesItem}:hover > ${ServicesItemBbImage} {
    ${(props) => props.isIE11 && `transform: translate(-50%, -50%) scale(1.2)`};
  }

  ${ServicesItemBbImage} {
    ${(props) => props.isIE11 && `transform: translate(-50%, -50%) scale(1)`};
  }

  & > div {
    flex: 1 0 auto;
    display: flex;
    flex-wrap: ${(props) => (props.isIE11 ? 'wrap' : 'nowrap')};
    width: fit-content;

    & > div:nth-child(1n + 5) {
      margin-top: ${(props) => (props.isIE11 ? grid.gridMargin : 0)};
    }

    & > div:nth-child(4n + 0) + div {
      margin-left: ${(props) => (props.isIE11 ? 0 : '')};
    }

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      flex-wrap: wrap;

      & > div:nth-child(2n + 0) + div {
        margin-left: 0;
      }

      & > div:nth-child(1n + 3) {
        margin-top: ${gridTablet.gridMargin};
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      flex-wrap: wrap;

      & > div:nth-child(3n + 0) + div {
        margin-left: 0;
      }

      & > div:nth-child(1n + 4) {
        margin-top: ${gridTablet.gridMargin};
      }
    }
  }
`;
