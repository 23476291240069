import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConsultationRoom from './ConsultationRoom';
import Room from './Room';
import { useRoomsImages } from './Rooms.images';
import { MainRoomsSection, RoomsWrapper } from './Rooms.styles';
import RoomsTabs from './RoomsTabs';
import { LeftSectionsItemsContext, ScrollContainerContext } from '../layout';
import { SectionHeading } from '../Layout.styles';
import { useMobile, usePrevious } from '../../hooks';

export interface RoomData {
  name: string;
  slug: string;
  text?: string;
  title: string;
}

const Rooms = () => {
  const [activeRoom, setActiveRoom] = useState(0);
  const prevActiveRoom = usePrevious(activeRoom);
  const isMobile = useMobile();
  const roomsImages = useRoomsImages();
  const roomsContainer = useRef<HTMLDivElement>(null);
  const scrollContainer = useContext(ScrollContainerContext);
  const setLeftSectionsItems = useContext(LeftSectionsItemsContext)[1];
  const { t } = useTranslation('about');
  const { listRooms } = t('rooms', { returnObjects: true });

  const handleChangeRoom = (roomIndex: number, slug: string) => {
    if (!isMobile) {
      setLeftSectionsItems(['/about', slug]);
      const el = document.getElementById(slug);

      if (el && scrollContainer.current) {
        scrollContainer.current.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
      }
    } else {
      setActiveRoom(roomIndex);
    }
  };

  useEffect(() => {
    if (!roomsContainer.current) return;

    if (!isMobile) {
      roomsContainer.current.style.transform = ``;
    } else {
      roomsContainer.current.style.transform = `translateX(-${activeRoom * 100}vw)`;

      const el = document.getElementById('rooms');
      if (prevActiveRoom !== activeRoom && el) el.scrollIntoView();
    }
  }, [activeRoom, isMobile, prevActiveRoom]);

  return (
    <div id="the-clinic">
      <MainRoomsSection id="the-clinic-main">
        <SectionHeading>
          <strong>{t('About us')}</strong> — <span>{t('rooms.title')}</span>
        </SectionHeading>

        {!isMobile && <h6>{t('rooms.description')}</h6>}

        <RoomsTabs activeRoom={activeRoom} onChangeRoom={handleChangeRoom} />
      </MainRoomsSection>

      <RoomsWrapper>
        <div
          ref={roomsContainer}
          style={{
            height: isMobile
              ? (roomsContainer.current &&
                  roomsContainer.current.children[activeRoom].clientHeight + 'px') ||
                ''
              : '',
          }}
        >
          <ConsultationRoom />

          {listRooms &&
            listRooms
              .filter((_d: RoomData, i: number) => i !== 0)
              .map((d: RoomData, i: number) => (
                <Room images={roomsImages[i]} roomData={d} key={d.slug} />
              ))}
        </div>
      </RoomsWrapper>
    </div>
  );
};

export default Rooms;
