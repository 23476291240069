import React from 'react';
import styled from 'styled-components';
import { MainText, SectionHeading } from './components/Layout.styles';
import { colors, gridMobile, grid, gridTablet } from './components/Variables';

export const Heading = styled.h1`
  position: relative;
  font-family: RobotoSlab-Regular, sans-serif;
  font-size: 70px;
  line-height: 78px;
  color: ${colors.black};
  white-space: pre-line;
  z-index: 1;
  margin-top: 40vh;


  @media (max-width: 767.98px){
    width: calc(${gridMobile.col5});
    font-size: calc(${gridMobile.col5} / 9);
    line-height: 1;
    margin-top: 35vh;
  }

  @media (min-width: 2100px) {
    font-size: 95px;
    line-height: 107px;
  }

  @media (min-width: 1920px) {
    font-size: 133px;
    line-height: 150px;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;
  margin-top: 60px;
  z-index: 1;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    margin-top: 20px;
    margin-bottom: 100px;
    flex-wrap: wrap;
  }

  @media (min-width: 1280px) {
    margin-top: 70px;
  }

  @media (min-width: 1400px) {
    margin-top: 80px;
  }

  a {
    padding: 11px 41px;
    margin-right: 30px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 20px;
      margin-right: 20px;
    }

    @media (min-width: 1440px) {
      margin-right: 40px;
    }
  }

  button {
    font-family: RobotoSlab-Regular, sans-serif;
    font-size: 14px;
    line-height: 18px;
    text-decoration: none;
    color: ${colors.black};
    border-bottom: 1px solid ${colors.blue};
    transition: color 300ms ease, border 300ms ease;
    cursor: pointer;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 20px;
    }

    @media (min-width: 1440px) {
      margin-left: 40px;
      font-size: 15px;
      line-height: 20px;
    }

    @media (min-width: 1920px) {
      font-size: 20px;
      line-height: 26px;
    }

    &:hover {
      color: ${colors.blue};
      border-bottom: 1px solid transparent;

      @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
        color: ${colors.black};
        border-bottom: 1px solid ${colors.blue};
      }

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        color: ${colors.black};
        border-bottom: 1px solid ${colors.blue};
      }
    }
  }
`;

export const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding-right: ${grid.gridMargin};
  padding-left: calc(${grid.navWidth} + ${grid.gridMargin});
  background-color: #f2f2f2;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  overscroll-behavior: none;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    padding-right: ${gridMobile.gridMargin};
    padding-left: ${gridMobile.gridMargin};
    scroll-snap-align: unset;
    scroll-snap-stop: unset;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    padding-top: 190px;
    padding-left: calc(${grid.navWidth} + ${gridTablet.gridMargin});
  }
`;

export const Section = styled(({ isIE11, ...rest }) => <section {...rest} />)`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  overscroll-behavior: none;

  & > div {
    position: ${(props) => (props.isIE11 ? 'static' : 'sticky')};
    top: 0;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    min-height: 100vh;
  }

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    position: static;
    scroll-snap-align: unset;
    scroll-snap-stop: unset;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    position: static;
  }

  & > div > ${MainText} {
    width: calc(${grid.col13});
    margin: 30px 0 80px calc(${grid.navWidth} + ${grid.gridMargin});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: calc(${gridMobile.col6});
      margin: 30px ${gridMobile.gridMargin} 50px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: calc(${gridTablet.col11});
      margin: 30px 0 50px calc(${grid.navWidth} + ${gridTablet.gridMargin});
    }
  }

  & > div > ${SectionHeading} {
    padding: 81px ${grid.gridMargin} 0 calc(${grid.navWidth} + ${grid.gridMargin});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      padding: 60px ${gridMobile.gridMargin} 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      padding: 50px ${gridTablet.gridMargin} 0 calc(${grid.navWidth} + ${gridTablet.gridMargin});
    }
  }
`;