import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { FluidObject } from 'gatsby-image';
import BeforeAndAfter from './BeforeAndAfter';
import { useBeforeAndAfterImages } from './BeforeAndAfter.images';
import { MainSection, Container } from './Clinical-cases.styles';
import Testimonials from './Testimonials';
import { MainText, TopSectionHeading } from '../Layout.styles';
import SEO from '../seo';

const ClinicalCases: React.FC<RouteComponentProps> = ({ location }) => {
  const images = useBeforeAndAfterImages();
  const { t } = useTranslation('clinical-cases');
  const currentPath = location?.pathname ? location?.pathname : '';

  return (
    <>
      <SEO title={t('title')} description={t('text')} path= {currentPath} />
      <MainSection id="before-and-after">
        <TopSectionHeading>
          <strong>{t('complains.title')}</strong>
        </TopSectionHeading>

        <MainText>{t('text')}</MainText>
        <Container>
          {images.map((imgArray: FluidObject[], index: number) => (
            <BeforeAndAfter
              complaints={t(`complains.listComplains.${index}`)}
              images={imgArray}
              key={index}
            />
          ))}
        </Container>
      </MainSection>

      <Testimonials />
    </>
  );
};

export default ClinicalCases;
