import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, WhatsappIcon } from './WhatsappButton.styles';
import { ScrollbarWidthContext } from '../layout';
import { useMobile } from '../../hooks';

const WhatsappButton = () => {
  const isMobile = useMobile();
  const scrollbarWidth = useContext(ScrollbarWidthContext);
  const { t } = useTranslation('menu');

  return (
    <>
      <WhatsappIcon scrollbarWidth={scrollbarWidth}>
        <a
          href="https://api.whatsapp.com/send?phone=+351966017783"
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg
            aria-label="whatsapp"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27 48C39.7025 48 50 37.7025 50 25C50 12.2975 39.7025 2 27 2C14.2975 2 4 12.2975 4 25C4 37.7025 14.2975 48 27 48Z"
                fill="#69E282"
              />
            </g>
            <path
              d="M12.6406 39.3993L14.6691 31.9919C13.4178 29.8239 12.7597 27.3647 12.7606 24.8452C12.7638 16.9633 19.1779 10.5508 27.0596 10.5508C30.8844 10.5524 34.4744 12.0411 37.1741 14.7431C39.8736 17.4451 41.3597 21.0368 41.3584 24.8567C41.3549 32.7383 34.9399 39.1517 27.0596 39.1517C27.0592 39.1517 27.0598 39.1517 27.0596 39.1517H27.0534C24.6604 39.1508 22.3091 38.5506 20.2207 37.4116L12.6406 39.3993ZM20.5717 34.8238L21.006 35.081C22.8303 36.1638 24.922 36.7364 27.055 36.7375H27.0596C33.6098 36.7375 38.9412 31.4071 38.9439 24.8557C38.9451 21.6809 37.7102 18.6954 35.4663 16.4497C33.2222 14.204 30.2386 12.9666 27.064 12.9652C20.5085 12.9652 15.1773 18.295 15.1748 24.8461C15.1739 27.0911 15.8019 29.2773 16.9918 31.1692L17.2745 31.6188L16.0737 36.0031L20.5717 34.8238Z"
              fill="white"
            />
            <path
              d="M13.1387 38.9007L15.097 31.7494C13.8888 29.6566 13.2536 27.2821 13.2543 24.8502C13.2573 17.2412 19.4496 11.0508 27.0579 11.0508C30.7507 11.0524 34.2166 12.4896 36.8223 15.098C39.4287 17.7065 40.8631 21.1738 40.8617 24.861C40.8585 32.4703 34.666 38.6614 27.0586 38.6614C27.0579 38.6614 27.0588 38.6614 27.0586 38.6614H27.0524C24.7423 38.6605 22.4723 38.0807 20.4562 36.9819L13.1387 38.9007Z"
              fill="#69E282"
            />
            <path
              d="M12.6406 39.3993L14.6691 31.9919C13.4178 29.8239 12.7597 27.3647 12.7606 24.8452C12.7638 16.9633 19.1779 10.5508 27.0596 10.5508C30.8844 10.5524 34.4744 12.0411 37.1741 14.7431C39.8736 17.4451 41.3597 21.0368 41.3584 24.8567C41.3549 32.7383 34.9399 39.1517 27.0596 39.1517C27.0592 39.1517 27.0598 39.1517 27.0596 39.1517H27.0534C24.6604 39.1508 22.3091 38.5506 20.2207 37.4116L12.6406 39.3993ZM20.5717 34.8238L21.006 35.081C22.8303 36.1638 24.922 36.7364 27.055 36.7375H27.0596C33.6098 36.7375 38.9412 31.4071 38.9439 24.8557C38.9451 21.6809 37.7102 18.6954 35.4663 16.4497C33.2222 14.204 30.2386 12.9666 27.064 12.9652C20.5085 12.9652 15.1773 18.295 15.1748 24.8461C15.1739 27.0911 15.8019 29.2773 16.9918 31.1692L17.2745 31.6188L16.0737 36.0031L20.5717 34.8238Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.4851 18.8699C23.2175 18.2749 22.9357 18.263 22.6809 18.2527C22.4727 18.2437 22.2343 18.2441 21.9964 18.2441C21.7582 18.2441 21.3711 18.3338 21.0437 18.6913C20.7162 19.0487 19.793 19.9131 19.793 21.671C19.793 23.4291 21.0734 25.1277 21.252 25.3663C21.4306 25.6047 23.7237 29.3273 27.3551 30.7595C30.3732 31.9498 30.9872 31.713 31.6424 31.6535C32.2975 31.594 33.7561 30.7891 34.0538 29.9549C34.3517 29.1207 34.3517 28.4058 34.2623 28.2561C34.1729 28.1074 33.9347 28.0179 33.5775 27.8393C33.2203 27.6607 31.4637 26.7961 31.1362 26.6771C30.8086 26.5577 30.5704 26.4984 30.3323 26.8561C30.0939 27.2134 29.4098 28.0179 29.2013 28.2561C28.9928 28.4949 28.7843 28.5248 28.427 28.346C28.0698 28.1669 26.919 27.7899 25.554 26.5727C24.4919 25.6258 23.7747 24.4562 23.5662 24.0985C23.3579 23.7413 23.5628 23.5647 23.7232 23.3695C24.0122 23.0181 24.497 22.3861 24.6161 22.148C24.7352 21.9093 24.6756 21.7008 24.5864 21.522C24.497 21.3434 23.8025 19.5765 23.4851 18.8699Z"
              fill="white"
            />
            <defs>
              <filter
                id="filter0_d"
                x="0"
                y="0"
                width="54"
                height="54"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
              </filter>
            </defs>
          </svg>
        </a>
      </WhatsappIcon>
      {!isMobile && (
        <Tooltip scrollbarWidth={scrollbarWidth}>
          {t('Go to Whatsapp website to launch the application')}
        </Tooltip>
      )}
    </>
  );
};

export default WhatsappButton;
