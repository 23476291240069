import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TopContactsHeading } from './Contacts.styles';
import { BtnSubmit, Form, MessageIsSended } from './ContactsForm.styles';
import { time, Option } from './ContactsFormData';
import ContactsFormField from './ContactsFormField';
import ContactsFormSelect from './ContactsFormSelect';
import { MainText } from '../Layout.styles';

const ContactsForm = () => {
  const [email, setEmail] = useState<{ value: string }>({ value: '' });
  const [date, setDate] = useState<{ value: string }>({ value: '' });
  const [fieldDateIsValid, setFieldDateIsValid] = useState(false);
  const form = useRef<HTMLFormElement>(null);
  const [formIsValid, setFormValid] = useState(false);
  const [fullName, setFullname] = useState<{ value: string }>({ value: '' });
  const [messageSended, setMessageSended] = useState(false);
  const [selectedService, setService] = useState<{ value: string }>({ value: '' });
  const [selectedTime, setTime] = useState<{ value: string }>({ value: '' });
  const [phone, setPhone] = useState<{ value: string }>({ value: '' });
  const { t } = useTranslation('contacts');

  const servicesList: Option[] = Array.from(t('contacts:servicesList', { returnObjects: true }));

  const getFieldDateIsValid = (isValid: boolean) => {
    setFieldDateIsValid(isValid);
  };

  /** Handle change Email */
  const handleChangeEmail = (event: React.FormEvent<HTMLInputElement>) => {
    setEmail({ value: event.currentTarget.value });
  };

  /** Handle change Date */
  const handleChangeDate = (value: string) => {
    setDate({ value: value });
  };

  /** Handle change Full Name */
  const handleChangeFullName = (event: React.FormEvent<HTMLInputElement>) => {
    setFullname({ value: event.currentTarget.value });
  };

  /** Handle change Phone */
  const handleChangePhone = (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length === 16) return;

    setPhone({
      value: !phone.value.length ? '+' + event.currentTarget.value : event.currentTarget.value,
    });
  };

  const handleFocusPhone = (value: string) => {
    setPhone({ value: value });
  };

  /** Handle change Service */
  const handleChangeService = (event: React.FormEvent<HTMLSelectElement>) => {
    setService({ value: event.currentTarget.value });
  };

  /** Handle change Time */
  const handleChangeTime = (event: React.FormEvent<HTMLSelectElement>) => {
    setTime({ value: event.currentTarget.value });
  };

  const handleChangeForm = () => {
    if (!form.current) return;
    setFormValid(form.current.checkValidity());
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const form = event.target as HTMLFormElement;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();

    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
      }
    };

    xhr.send(data);
    setMessageSended(true);
  };

  const handleClearForm = () => {
    setDate({ value: '' });
    setEmail({ value: '' });
    setFullname({ value: '' });
    setPhone({ value: '' });
    setService({ value: '' });
    setTime({ value: '' });
    setMessageSended(false);
    setFormValid(false);
  };

  return !messageSended ? (
    <Form
      action="https://formspree.io/mbjprpow"
      method="POST"
      ref={form}
      onSubmit={handleSubmit}
      onChange={handleChangeForm}
    >
      <TopContactsHeading>
        {t('Make an appointment')}
        </TopContactsHeading>

      <ContactsFormSelect
        options={servicesList}
        placeholder={t('contacts:Service')}
        value={selectedService.value}
        onChange={handleChangeService}
      />

      <ContactsFormField
        label={t('contacts:Date')}
        name={t('contacts:Date')}
        placeholder={'DD MM YY'}
        type={'date'}
        value={date.value}
        setFieldDateIsValid={getFieldDateIsValid}
        onChangeDate={handleChangeDate}
      />

      <ContactsFormSelect
        options={time}
        placeholder={t('contacts:Time')}
        type={'time'}
        value={selectedTime.value}
        onChange={handleChangeTime}
      />

      <ContactsFormField
        name={t('contacts:Full name')}
        label={t('contacts:Full name')}
        type={'text'}
        value={fullName.value}
        onChange={handleChangeFullName}
      />

      <ContactsFormField
        name={t('contacts:Email')}
        label={t('contacts:Email')}
        type={'email'}
        value={email.value}
        onChange={handleChangeEmail}
      />

      <ContactsFormField
        name={t('contacts:Phone')}
        label={t('contacts:Phone')}
        type={'phone'}
        value={phone.value}
        onChange={handleChangePhone}
        onFocus={handleFocusPhone}
      />

      <BtnSubmit disabled={!formIsValid || !fieldDateIsValid} type="submit">
        {t('contacts:Send')}
      </BtnSubmit>
      <Trans components={{a:<a target="_blank"/>, span:<span />}}>
      {t('contacts:privacyAgreed')}<br /><br /><br />
      </Trans>
    </Form>
  ) : (
    <MessageIsSended>
      <MainText>{t('contacts:messageIsSended')}</MainText>

      <button onClick={handleClearForm}>{t('contacts:goBack')}</button>
    </MessageIsSended>
  );
};

export default ContactsForm;
