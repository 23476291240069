import React, { useState } from 'react';
import { useMobile, useTablet } from '../../hooks';
import { isAndroid, isTablet } from 'react-device-detect';

const colors = { hovered: '#00a0cc', default: '#d6d6d6' };

const Facebook = () => {
  const isMobile = useMobile();
  const isTouch = useTablet();
  const [color, setColor] = useState(colors.default);

  const handleMouseEnter = () => {
    if (isMobile || isTouch) return;

    setColor(colors.hovered);
  };

  const handleMouseLeave = () => {
    if (isMobile || isTouch) return;

    setColor(colors.default);
  };

  return (
    <a
      href={isMobile || isTablet ? (isAndroid? "fb://page/189213132560148" : "fb://page/?id=189213132560148") : "https://www.facebook.com/albufeira.health.institute"}
      rel="noopener noreferrer"
      target="_blank"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg
        aria-label="Facebook"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.58833 15.44H10.8363V9.99933H12.336L12.5347 8.12467H10.8363L10.8387 7.186C10.8387 6.69733 10.8853 6.435 11.5867 6.435H12.524V4.56H11.024C9.22233 4.56 8.58833 5.46967 8.58833 6.999V8.12467H7.46533V9.99967H8.58833V15.44V15.44ZM10 20C4.47733 20 0 15.5227 0 10C0 4.477 4.47733 0 10 0C15.5227 0 20 4.477 20 10C20 15.5227 15.5227 20 10 20Z"
          fill={color}
        />
      </svg>
    </a>
  );
};

export default Facebook;
