import styled from 'styled-components';
import { grid, gridTablet } from '../Variables';

export const MapWrapper = styled.div`
  position: relative;
  width: calc(${grid.col4} + ${grid.gridGutter} * 2);

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(100% + ${grid.navWidth} + ${gridTablet.gridMargin});
    min-height: 335px;
    margin-left: calc(-1 * ${grid.navWidth} - ${gridTablet.gridMargin});
  }
`;
