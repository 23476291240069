import React from 'react';
import Link from '../Link';
import styled from 'styled-components';
import { colors, gridMobile } from '../Variables';

export const StyledLogo = styled(({ collapsed, hidden, isBlue, ...rest }) => <Link {...rest} />)`
  position: fixed;
  top: 20px;
  left: 0;
  cursor: pointer;
  z-index: 11;

  opacity: ${(props) => (props.hidden ? 0 : 1)};
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
  transition: top 300ms ease, visibility 300ms linear, opacity 300ms ease, transform 300ms ease;
  pointer-events: ${(props) => (props.hidden ? 'none' : 'auto')};

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    top: 24px;
    z-index: 10;

    &::before {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      display: block;
      height: 58px;
      opacity: ${(props) => (!props.collapsed ? 0 : 1)};
      background-color: ${(props) => (props.isBlue ? colors.blue : '#fff')};
      transition: opacity 300ms ease, top 300ms ease, background 300ms ease;
      pointer-events: none;
    }
  }

  svg {
    position: relative;
    vertical-align: top;
  }

  & > svg:nth-child(1) {
    margin-left: 16px;
    width: 38px;
    height: 56px;
    transform: translateX(${(props) => (props.collapsed ? 0 : 28)}px);
    transition: transform 400ms ease;

    path {
      transition: fill 300ms ease;
    }

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-left: ${gridMobile.gridMargin};
      transform: translateY(${(props) => (props.collapsed ? -10 : 0)}px)
        scale(${(props) => (props.collapsed ? 0.710526 : 1)});
      transform-origin: top left;
    }
  }

  & > svg:nth-child(2) {
    width: 160px;
    height: 47px;
    margin-left: 36px;
    opacity: ${(props) => (props.collapsed ? 0 : 1)};
    pointer-events: ${(props) => (props.collapsed ? 'none' : 'auto')};
    transition: opacity 400ms ease;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-left: 8px;
    }
  }
`;
