import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BigText,
  Caption,
  PlainText,
  PlainTextContainer,
  TestimonialsSection,
  Quotes,
} from './Testimonials.styles';
import { MainText, SectionHeading } from '../Layout.styles';

const Testimonials = () => {
  const { t } = useTranslation('clinical-cases');

  return (
    <TestimonialsSection id="testimonials">
      <SectionHeading>
        <strong>{t('testimonials.title')} (by Google Reviews)</strong>
      </SectionHeading>
      <BigText>
        <Quotes />
        <MainText>{t('testimonials.reviews.0.text')}</MainText>

        <Caption>
          <b>{t('testimonials.reviews.0.patient')}</b>
          <br />
          {t('testimonials.reviews.0.date')}
        </Caption>
      </BigText>
      <PlainTextContainer>
        <PlainText>
          <Quotes />
          <p>{t('testimonials.reviews.1.text')}</p>
          <Caption>
            <b>{t('testimonials.reviews.1.patient')}</b>
            <br />
            {t('testimonials.reviews.1.date')}
          </Caption>
        </PlainText>
        <PlainText>
          <Quotes />
          <p>{t('testimonials.reviews.2.text')}</p>
          <Caption>
            <b>{t('testimonials.reviews.2.patient')}</b>
            <br />
            {t('testimonials.reviews.2.date')}
          </Caption>
        </PlainText>
      </PlainTextContainer>

      <PlainTextContainer>
        <PlainText>
          <Quotes />
          <p>{t('testimonials.reviews.3.text')}</p>
          <Caption>
            <b>{t('testimonials.reviews.3.patient')}</b>
            <br />
            {t('testimonials.reviews.3.date')}
          </Caption>
        </PlainText>
        <PlainText>
          <Quotes />
          <p>{t('testimonials.reviews.4.text')}</p>
          <Caption>
            <b>{t('testimonials.reviews.4.patient')}</b>
            <br />
            {t('testimonials.reviews.4.date')}
          </Caption>
        </PlainText>
      </PlainTextContainer>
      <br/>
      <br/>
      <br/>
      <br/>
      <h4>
        <a href="https://g.page/r/CcNezZtYSMCgEBM/review"
        rel="noopener noreferrer"
        target="_blank"><span>{t('testimonials.readMore')}</span></a>
     </h4>
     <br/>
      <br/>
      <br/>
      <br/>
      {t('testimonials.redacted')}
    </TestimonialsSection>
  );
};

export default Testimonials;
