import React from 'react';
import styled from 'styled-components';
import { grid, colors } from '../Variables';

export const SecondaryNav = styled(({ scrollbarWidth, visible, ...rest }) => <ul {...rest} />)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(
    ${(props) => `${props.scrollbarWidth ? props.scrollbarWidth : 0}px + ${grid.navWidth}`}
  );
  padding-right: ${(props) => props.scrollbarWidth || 0}px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: visibility 500ms linear, opacity 500ms ease;
  transition-delay: ${(props) => (props.visible ? 0 : 500)}ms, 0ms;
  pointer-events: none;
  z-index: 9;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    display: none;
  }
`;

export const SecondaryNavItem = styled(({ active, ...rest }) => <li {...rest} />)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 100%;
  height: 40px;
  cursor: pointer;
  pointer-events: auto;

  &:hover p {
    opacity: 1;
    pointer-events: auto;
  }

  p {
    position: absolute;
    right: 43px;
    flex: 0 0 auto;
    margin-right: 12px;
    padding: 4px 8px;
    font-family: RobotoSlab-Regular, sans-serif;
    font-size: 10px;
    line-height: 13px;
    color: ${colors.blue};
    opacity: 0;
    transition: opacity 300ms ease;
    background: #fafafa;
    border-radius: 100px;
    white-space: nowrap;
    pointer-events: none;
    z-index: 2;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      display: none;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      display: none;
    }
  }

  &::after {
    content: '';
    flex: 0 0 auto;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 1px solid ${colors.blue};
    background-color: ${(props) => (props.active ? colors.blue : 'transparent')};
    transition: background 300ms ease;
  }
`;
