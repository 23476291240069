import styled from 'styled-components';
import { MainText, SecondaryText, Section } from '../Layout.styles';
import { grid, gridMobile, gridTablet, colors } from '../Variables';

export const ServiceContent = styled.div`
  flex: 0 0 auto;
  width: calc(${grid.col9});

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    order: 1;
    width: calc(${gridMobile.col6});

    h5 {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    order: 1;
    width: calc(${gridTablet.col12});
    margin-top: 45px;
  }
`;

export const ServiceSection = styled(Section)`
  position: relative;
  display: flex;
  padding-top: 90px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: ${gridMobile.gridMargin};
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    min-height: auto;
    padding-top: 100px;
    padding-bottom: 40px;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-top: 120px;
  }

  ${MainText} {
    width: calc(${grid.col8});
    margin-top: 50px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: 100%;
      margin-top: 30px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: calc(${gridTablet.col10});
    }

    @media (min-width: 1280px) {
      margin-top: 50px;
    }

    @media (min-width: 1400px) {
      margin-top: 40px;
    }

    @media (min-width: 1920px) {
      margin-top: 60px;
    }
  }

  ${SecondaryText} {
    max-width: 500px;
    margin-top: 30px;

    strong {
      font-family: RobotoSlab-bold, sans-serif;
    }

    a {
      position: relative;
      display: inline-block;
      text-decoration: none;
      transition: color 300ms ease;
      font-family: RobotoSlab-light, sans-serif;
      font-size: 14px;
      line-height: 16px;
      color: ${colors.black};
      border-bottom: 1px solid ${colors.blue};

      &:hover {
        color: ${colors.blue};
        border-bottom: 1px solid transparent;
  
        @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
          color: ${colors.black};
          border-bottom: 1px solid ${colors.blue};

        }
  
        @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
          color: ${colors.black};
          border-bottom: 1px solid ${colors.blue};

        }
      }
    }
  }
`;
