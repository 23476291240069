import styled from 'styled-components';
import { colors, gridTablet, grid } from '../Variables';

export const WrapperText = styled.div`
  margin-top: 60px;
  z-index: 1;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    margin-top: 50px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col10});
  }

  @media (min-width: 1400px) {
    margin-top: 80px;
  }

  @media (min-width: 1920px) {
    margin-top: 120px;
  }

  p {
    display: inline-block;
    width: calc(${grid.col6});
    font-family: RobotoSlab-Light, sans-serif;
    font-size: 14px;
    line-height: 25px;
    color: ${colors.black};
    vertical-align: top;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      display: block;
      width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      display: block;
      width: 100%;
    }

    @media (min-width: 1920px) {
      font-size: 22px;
      line-height: 38px;
    }
  }

  p + p {
    margin-left: calc(${grid.col1} + ${grid.gridGutter});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 20px;
      margin-left: 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-top: 30px;
      margin-left: 0;
    }
  }
`;
