import React, { useContext, useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Link from '../Link';
import { MenuList, MenuListItem, MenuSubList, Nav, MenuSubListItem } from './Menu.styles';
import { useMobile } from '../../hooks';
import { PageSectionContext, Pages, Sections, ScrollbarWidthContext } from '../layout';

interface Props {
  isOpened: boolean;
  onClickMenuItem: () => void;
}

const IOS_ARRAY = [
  'iPad Simulator',
  'iPhone Simulator',
  'iPod Simulator',
  'iPad',
  'iPhone',
  'iPod',
];

const usePages = (): Array<Pages> => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          pages {
            media
            employee
            name
            slug
            sections {
              name
              slug
              onlyLeftSide
            }
          }
        }
      }
    }
  `);

  return site.siteMetadata.pages.filter((p: Pages) => p.name !== 'Home' && !p.employee && !p.media);
};

const Menu = ({ isOpened, onClickMenuItem }: Props) => {
  const [isIOS, setIsIOS] = useState(
    typeof window !== 'undefined' && IOS_ARRAY.includes(navigator.platform)
  );
  const nav = useRef<HTMLElement>(null);
  const isMobile = useMobile();
  const pages = usePages();
  const [pageSections, setPageSection] = useContext(PageSectionContext);
  const scrollbarWidth = useContext(ScrollbarWidthContext);
  const { t } = useTranslation('menu');
  const menuList = Array.from(t('list', { returnObjects: true })) as any;

  useEffect(() => {
    if (isMobile) {
      setIsIOS(IOS_ARRAY.includes(navigator.platform));
    } else {
      setIsIOS(false);
    }
  }, [isMobile]);

  /**
   * Scrolls the menu up when opened
   */
  useEffect(() => {
    if (isOpened && nav.current) nav.current.scrollTo({ top: 0 });
  }, [isOpened]);

  return (
    <Nav forwardRef={nav} opened={isOpened} scrollbarWidth={scrollbarWidth}>
      <MenuList IOSPadding={isMobile && isIOS}>
        {pages
          .filter((p: Pages) => (!isMobile ? p.name !== 'Contacts' : p))
          .map((p: Pages) => (
            <MenuListItem key={p.slug} onClick={onClickMenuItem}>
              <Link to={p.slug} onClick={() => setPageSection([p.slug+"/", ''])}>
                {menuList
                  ? menuList.filter((page: Pages) => page.slug === p.slug)[0] &&
                    menuList.filter((page: Pages) => page.slug === p.slug)[0].name
                  : p.name}
              </Link>

              {(p.sections && !isMobile) || (p.sections && isMobile && p.slug !== '/services') ? (
                <MenuSubList>
                  {p.sections
                    .filter((s: Sections) => !s.onlyLeftSide)
                    .map(
                      (s: Sections, key: number) =>
                        key < 3 && (
                          <MenuSubListItem active={s.slug === pageSections[1]} key={s.slug}>
                            <button onClick={() => setPageSection([p.slug+"/", s.slug])}>
                              <span>
                                {menuList.filter((page: Pages) => page.slug === p.slug)[0] &&
                                  menuList
                                    .filter((page: Pages) => page.slug === p.slug)[0]
                                    .sections.filter(
                                      (section: Sections) => section.slug === s.slug
                                    )[0] &&
                                  menuList
                                    .filter((page: Pages) => page.slug === p.slug)[0]
                                    .sections.filter(
                                      (section: Sections) => section.slug === s.slug
                                    )[0].name}
                              </span>
                            </button>
                          </MenuSubListItem>
                        )
                    )}

                  {!isMobile &&
                    p.sections.filter((s: Sections) => !s.onlyLeftSide).length >
                      (isMobile ? 4 : 3) && (
                      <MenuSubListItem
                        active={
                          p.sections.findIndex((s: Sections) => s.slug === pageSections[1]) > 2
                        }
                      >
                        <Link to={p.slug} onClick={() => setPageSection([p.slug+"/", ''])}>
                          <span>
                            +{p.sections.length - 3} {t('more')}
                          </span>
                        </Link>
                      </MenuSubListItem>
                    )}
                </MenuSubList>
              ) : null}
            </MenuListItem>
          ))}
      </MenuList>
    </Nav>
  );
};

export default Menu;
