import { useContext, useEffect, useState } from 'react';
import { ScrollContainerContext } from '../components/layout';


//parece lidar apenas com o scroll horizontal nao com o smooth/chaining

export const useScrollPosition = () => {
  const [position, setPosition] = useState(0);
  const scrollContainer = useContext(ScrollContainerContext);

  useEffect(() => {
    if (!scrollContainer.current) return;

    const SC = scrollContainer.current;

    function handleScroll(e: Event) {
      const el = e.target as HTMLElement;
      const Y = el.scrollTop;
      setPosition(Y);
    }

    SC && SC.addEventListener('scroll', handleScroll);

    return () => SC.removeEventListener('scroll', handleScroll);
  }, [scrollContainer]);

  return position;
};

export default useScrollPosition;
