import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useLocation } from '@reach/router';
import { Language, StyledLanguagePanel } from './LanguagePanel.styles';
import { LocaleContext, ScrollbarWidthContext } from '../layout';
import { locales } from '../i18n';

interface Props {
  visible: boolean;
  onOpenMenu: (close?: boolean) => void;
}

const LanguagePanel = ({ visible, onOpenMenu }: Props) => {
  const { i18n } = useTranslation('home');
  const [locale, [, changeLocale]] = useContext(LocaleContext);
  const location = useLocation();
  const scrollbarWidth = useContext(ScrollbarWidthContext);

  const handleChangeLanguage = (event: any) => {
    i18n.changeLanguage(event.target.value, () => {
      const newLocale = i18n.language === `en` ? `` : `/${i18n.language}`;
      if (location.pathname.indexOf(locale) === 0) {
        navigate(newLocale + location.pathname.substring(locale.length));
      }
      changeLocale({});
    });
    onOpenMenu(true); //close menu
  };

  return (
    <StyledLanguagePanel scrollbarWidth={scrollbarWidth} visible={visible}>
      {locales.map((locale: string, index: number) => (
        <Language active={locale === i18n.language} key={index}>
          <input name="language" type="radio" value={locale} onClick={handleChangeLanguage} />
          <span>{locale.toUpperCase()}</span>
        </Language>
      ))}
    </StyledLanguagePanel>
  );
};

export default LanguagePanel;
