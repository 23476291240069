import styled from 'styled-components';
import { Section } from '../Layout.styles';
import { gridTablet, gridMobile, grid } from '../Variables';

export const MainSection = styled(Section)`
  padding-bottom: 60px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    overflow-x: hidden;
  }

  @media (min-width: 1280px) {
    padding-bottom: 80px;
  }

  @media (min-width: 1400px) {
    padding-bottom: 50px;
  }

  @media (min-width: 1920px) {
    padding-bottom: 150px;
  }

  & > p {
    margin-top: 50px;
    width: calc(${grid.col9});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: calc(${gridMobile.col6});
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: calc(${gridTablet.col10});
    }
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 50px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    flex-direction: column;
    margin-top: 30px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    margin-top: 45px;
  }

  & > div {
    flex: 0 0 auto;
  }

  & > div + div {
    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 40px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-top: 30px;
    }
  }

  & > div:nth-child(n + 3) {
    margin-top: 50px;
  }

  & > div:nth-child(2n) {
    margin-left: ${grid.gridGutter};

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-left: 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-left: 0;
    }
  }
`;
