import React, { useContext } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { LocaleContext } from '../layout';

const Link = ({ to, ...props }: any) => {
  const [locale] = useContext(LocaleContext);

  return <GatsbyLink to={`${locale}${to}`} {...props} />;
};

export default Link;
