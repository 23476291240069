import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import ContactsForm from './ContactsForm';
import Map from './ContactsMap';
import { ContactsData, ContactsHeading, Section } from './Contacts.styles';
import Vimeo from '../Icons/Vimeo';
import Twitter from '../Icons/Twitter';
import Instagram from '../Icons/Instagram';
import Facebook from '../Icons/Facebook';
import Linkedin from '../Icons/Linkedin';
import Youtube from '../Icons/Youtube';
import SEO from '../seo';
import { useMobile, useResize } from '../../hooks';
import { Icons } from '../Icons/Icons.styles';

const Contacts: React.FC<RouteComponentProps> = ({ location }) => {
  const isMobile = useMobile();
  const [W, H] = useResize();
  const { t } = useTranslation('contacts');

  const currentPath = location?.pathname ? location?.pathname : '';


  return (
    <>
      <SEO title={t('Contact Us')} description={t('Make an Appointment')} path={currentPath}/>
      <Section>

      {!isMobile && <ContactsForm />} 

        <ContactsData>
          <ContactsHeading style={{ marginTop: (isMobile || W < 1024 ) ? `60px` : '' }}>
            {t('Address')}
            </ContactsHeading>

          <p>
            {t('Working hours')}:
            <br />
            {t('Monday-Saturday')} 09:00 - 19:00
          </p>

          <p>
            <a href="tel:+351289589080"><span>+351 289 589 080</span></a>
            <br /> {t('localCall')}
            <br />
            <br />
            <a href="tel:+351966017783"> <span>+351 966 017 783</span></a>
            <br /> {t('mobileCall')}
            <br />
            <br />
          <br />
          </p>

          <a href="mailto:info@albufeira-health-institute.pt">
            <span>info@albufeira-health-institute.pt</span>
          </a>
          <br />
          <br />

          <a
            href="https://maps.app.goo.gl/8Sqx1D7jRspAefX27?g_st=ic"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>{t('Find us on Google Maps')}</span>
          </a>

          <Icons>
            <Instagram />
            <Facebook />
            <Linkedin />
            <Youtube />
            <Twitter />
            <Vimeo />
          </Icons>
        </ContactsData>

        {isMobile && <ContactsForm />} 
        {!isMobile && <Map />}
      </Section>
    </>
  );
};

export default Contacts;
