import React from 'react';
import styled from 'styled-components';
import { grid } from '../Variables';

export const NextSection = styled(({ visible, ...rest }) => <button {...rest} />)`
  position: fixed;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${grid.navWidth};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: visibility 500ms linear, opacity 500ms ease;
  transition-delay: ${(props) => (props.visible ? 0 : 500)}ms, 0ms;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};
  cursor: pointer;
  z-index: 9;

  p {
    margin-bottom: 30px;
    font-family: RobotoSlab-Regular, sans-serif;
    font-size: 10px;
    line-height: 13px;
    color: #000;
    writing-mode: vertical-rl;
    text-orientation: sideways-right;
    transform: scale(-1);
  }
`;
