import isNode from 'detect-node';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const locales = ['en', 'pt', 'de', 'fr'];

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  name: 'whitelist',
  lookup(options) {
    let found = '';
    if (typeof window !== 'undefined') {
      const language = window.location.pathname.match(/\/([a-zA-Z-]*)/g);
      if (language instanceof Array) {
        if (typeof options.lookupFromPathIndex === 'number') {
          if (typeof language[options.lookupFromPathIndex] !== 'string') {
            return undefined;
          }
          found = language[options.lookupFromPathIndex].replace('/', '');
        } else {
          found = language[0].replace('/', '');
        }
      }
    } else if (typeof options === 'string') {
      const language = (options as string).match(/\/([a-zA-Z-]*)/g);
      if (language instanceof Array) {
        found = language[0].replace('/', '');
      }
    }

    return locales.includes(found) ? `/${found}` : ``;
  },
});

let config = {
  fallbackLng: 'en',
  whitelist: locales,
  preload: ['en'],
  ns: ['home', 'about', 'clinical-cases'],
  defaultNS: 'home',
  debug: process.env.NODE_ENV === 'build',
  detection: {
    order: ['path', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
  },
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
};

if (isNode) {
  const SyncBackend = eval("require('i18next-fs-backend/cjs')");
  i18n.use(SyncBackend);
  config = Object.assign(config, {
    preload: locales,
    initImmediate: false,
    backend: {
      loadPath: 'public/locales/{{lng}}/{{ns}}.json',
      addPath: 'public/locales/{{lng}}/{{ns}}.missing.json',
    },
  });
} else {
  i18n.use(Backend);
}

i18n.use(languageDetector).use(initReactI18next).init(config);

export default i18n;
