import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Img, { FluidObject } from 'gatsby-image';
import {
  Container,
  ResizableElement,
  Resizer,
  StaticElement,
  Wrapper,
} from './BeforeAndAfter.styles';
import { useResize } from '../../hooks';

interface Props {
  complaints: string;
  images: FluidObject[];
}

const BeforeAndAfter = ({ complaints, images }: Props) => {
  const container = useRef<HTMLDivElement>(null);
  const resizableElement = useRef<HTMLDivElement>(null);
  const resizer = useRef<HTMLDivElement>(null);
  const [touch, setTouch] = useState(false);
  const { t } = useTranslation('clinical-cases');
  const [W, H] = useResize();

  useEffect(() => setTouch(matchMedia('(pointer: coarse)').matches), [W, H]);

  useEffect(() => {
    if (!container.current || !resizableElement.current || !resizer.current) return;

    const eventStart = touch ? 'touchstart' : 'mousedown';
    const eventMove = touch ? 'touchmove' : 'mousemove';
    const eventEnd = touch ? 'touchend' : 'mouseup';

    const resize = (e: MouseEvent | TouchEvent) => {
      if (resizableElement.current && container.current && resizer.current) {
        const pageX = e instanceof MouseEvent ? e.pageX : e.changedTouches[0].pageX;
        const mv = container.current.offsetWidth; // Max value
        const rv = Math.min(pageX - resizableElement.current.getBoundingClientRect().left, mv); // Resize value
        resizer.current.style.left = `${Math.max(-3, rv)}px`;
        resizableElement.current.style.width = `${rv}px`;
      }
    };

    const stopResize = () => window.removeEventListener(eventMove, resize);

    resizer.current.addEventListener(eventStart, (e) => {
      e.preventDefault();
      window.addEventListener(eventMove, resize);
      window.addEventListener(eventEnd, stopResize);
    });
  }, [touch]);

  return (
    <Wrapper>
      <Container ref={container}>
        <ResizableElement ref={resizableElement}>
          <Img
            alt={complaints}
            fluid={images[0]}
            style={{
              position: `absolute`,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              minWidth: container.current ? `${container.current.offsetWidth}px` : ``,
              pointerEvents: `none`,
            }}
          />
        </ResizableElement>
        <Resizer ref={resizer}>
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="25" cy="25" r="25" fill="white" />
            <path
              d="M17.6462 20.5886L13.7246 25.0003L17.6462 29.4121"
              stroke="#D0D0D0"
              strokeWidth="2"
            />
            <path
              d="M33.3323 29.4119L37.2539 25.0001L33.3323 20.5884"
              stroke="#D0D0D0"
              strokeWidth="2"
            />
          </svg>
        </Resizer>

        <StaticElement>
          <Img
            alt={complaints}
            fluid={images[1]}
            style={{
              position: `absolute`,
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              pointerEvents: `none`,
            }}
          />
        </StaticElement>
      </Container>
      <p>
        <b>{t('complains.name')}:</b>
        <br />
        {complaints}
      </p>
    </Wrapper>
  );
};

export default BeforeAndAfter;
