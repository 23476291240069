import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import LanguagePanel from './LanguagePanel';
import Menu from './Menu';
import { ContactUsLink, NavBtn, NavPanel } from './Navigation.styles';
import {
  ModalContext,
  PageSectionContext,
  ScrollbarWidthContext,
  ScrollContainerContext,
  ThemeContext,
} from '../layout';
import WhatsappButton from '../WhatsappBtn/WhatsappButton';
import { useMobile } from '../../hooks';

interface Props {
  opened: boolean;
  onOpenMenu: (close?: boolean) => void;
}

const Navigation = ({ opened, onOpenMenu }: Props) => {
  const isBlue = useContext(ThemeContext)[0];
  const [pageSection, setPageSection] = useContext(PageSectionContext);
  const [modalIsOpened, setModalIsOpened] = useContext(ModalContext);
  const scrollbarWidth = useContext(ScrollbarWidthContext);
  const scrollContainer = useContext(ScrollContainerContext);
  const isMobile = useMobile();
  const { t } = useTranslation('contacts');

  const handleBtnClick = () => {
    modalIsOpened ? setModalIsOpened(false) : onOpenMenu();
  };

  /**
   * handleClick for Menu Item and Contact Us
   */
  const handleClick = (contacts?: boolean) => {
    if (scrollContainer.current) scrollContainer.current.scrollTo({ top: 0 });
    onOpenMenu(true); // Close menu

    if (contacts) setPageSection(['/contacts/', '']);

  };

  return (
    <>
      {!isMobile && (
        <ContactUsLink
          isBlue={isBlue && !opened && !isMobile}
          isHidden={pageSection[0] === '/contacts/'}
          scrollbarWidth={scrollbarWidth}
          to={'/contacts/'}
          onClick={() => handleClick(true)}
        >
          {t('Contact Us')}
        </ContactUsLink>
      )}
      <NavBtn
        isBlue={isMobile && isBlue && !opened}
        opened={opened || modalIsOpened}
        scrollbarWidth={scrollbarWidth}
        onClick={handleBtnClick}
      />
      <Menu isOpened={opened} onClickMenuItem={() => handleClick(false)} />
      <NavPanel active={opened} scrollbarWidth={scrollbarWidth}>
        <LanguagePanel visible={opened} onOpenMenu={() => handleClick(false)}/>
      </NavPanel>

      <WhatsappButton />
    </>
  );
};

export default Navigation;
