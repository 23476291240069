import React from 'react';
import styled from 'styled-components';
import { colors } from '../Variables';

export const StyledLanguagePanel = styled(({ scrollbarWidth, visible, ...rest }) => (
  <div {...rest} />
))`
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: ${(props) => props.scrollbarWidth || 0}px;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: visibility 500ms linear, opacity 500ms ease;
  transition-delay: ${(props) => (props.visible ? 0 : 500)}ms, 0ms;
  pointer-events: ${(props) => (props.visible ? 'auto' : 'none')};

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    visibility: visible;
  }
`;

export const Language = styled(({ active, children, ...rest }) => (
  <label {...rest}>{children}</label>
))`
  flex: 0 0 auto;
  opacity: ${(props) => (props.active ? 1 : 0.2)};
  transition: opacity 300ms ease, color 300ms ease;
  cursor: pointer;
  color: ${colors.black};

  &:hover {
    opacity: 1;
    color: ${(props) => (!props.active ? colors.blue : '')};

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      opacity: 1;
      color: ${colors.black};
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      opacity: 1;
      color: ${colors.black};
    }
  }

  & + label {
    margin-top: 35px;
  }

  & > span {
    font-family: ${(props) => (props.active ? 'RobotoSlab-Medium' : 'RobotoSlab-Regular')},
      sans-serif;
    font-size: 15px;
    line-height: 20px;
    color: inherit;
  }

  & > input {
    display: none;
  }
`;
