import React from 'react';
import styled from 'styled-components';
import { StyledLinkToPage } from '../LinkToPage/LinkToPage.styles';
import { grid, gridTablet, gridMobile, colors } from '../Variables';

export const ContactUsLink = styled(({ isBlue, isHidden, scrollbarWidth, ...rest }) => (
  <StyledLinkToPage {...rest} />
))`
  position: fixed;
  top: ${grid.gridMargin};
  right: calc(${grid.navWidth} + ${grid.gridMargin} + ${(props) => props.scrollbarWidth || 0}px);
  color: ${(props) => (props.isBlue ? '#fff' : colors.black)};
  border: 2px solid ${(props) => (props.isBlue ? '#fff' : colors.blue)};
  opacity: ${(props) => (props.isHidden ? 0 : 1)};
  pointer-events: ${(props) => (props.isHidden ? 'none' : 'auto')};
  z-index: 10;

  &:hover {
    color: ${(props) => (props.isBlue ? colors.blue : '#fff')};
    opacity: 0.6;
    opacity: ${(props) => (props.isHidden ? 0 : 0.6)};
    background-color: ${(props) => (props.isBlue ? '#fff' : colors.blue)};

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      color: ${(props) => (props.isBlue ? '#fff' : colors.black)};
      border: 2px solid ${(props) => (props.isBlue ? '#fff' : colors.blue)};
      background-color: ${(props) => (props.isBlue ? colors.blue : '#fff')};
      opacity: ${(props) => (props.isHidden ? 0 : 1)};
    }
  }

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    right: calc(${grid.navWidth} + ${gridTablet.gridMargin});
  }
`;

export const NavBtn = styled(({ isBlue, opened, children, scrollbarWidth, ...rest }) => (
  <button aria-label="menu" {...rest}>{children}</button>
))`
  position: fixed;
  top: 37px;
  right: ${(props) => props.scrollbarWidth + 24 || 24}px;
  left: auto;
  width: 22px;
  height: 17px;
  transform: translateY(0);
  transition: transform 300ms ease;
  z-index: 11;
  cursor: pointer;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    top: ${gridMobile.gridMargin};
    right: ${gridMobile.gridMargin};
    z-index: 11;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    border: 1px solid ${(props) => (props.isBlue ? '#fff' : colors.black)};
    border-radius: 2px;
    background-color: ${(props) => (props.isBlue ? '#fff' : colors.black)};
    transition: transform 400ms ease;
  }

  &::before {
    top: 4px;
    transform: translateY(${(props) => (props.opened ? 4 : 0)}px)
      rotate(${(props) => (props.opened ? 45 : 0)}deg);
  }

  &::after {
    bottom: 4px;
    transform: translateY(${(props) => (props.opened ? -3 : 0)}px)
      rotate(${(props) => (props.opened ? -45 : 0)}deg);
  }
`;

export const NavPanel = styled(({ active, scrollbarWidth, ...rest }) => <div {...rest} />)`
  position: fixed;
  top: 0;
  right: 0px;
  bottom: 0;
  width: calc(
    ${(props) => `${props.scrollbarWidth ? props.scrollbarWidth : 0}px + ${grid.navWidth}`}
  );
  background-color: #fafafa;
  border-left: 1px solid #e1e1e1;
  z-index: 9;
  pointer-events: none;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    opacity: ${(props) => (props.active ? 1 : 0)};
    transform: translateX(${(props) => (props.active ? 0 : 100)}vw);
    transition: transform 500ms ease, opacity ${(props) => (props.active ? 500 : 700)}ms ease;
    z-index: 10;
  }
`;
