import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { SecondaryNav, SecondaryNavItem } from './SecondaryNavigation.styles';
import {
  PageSectionContext,
  Pages,
  Sections,
  ScrollbarWidthContext,
  ScrollContainerContext,
} from '../layout';

interface Props {
  location: string;
  visible: boolean;
}

const useSections = (pathname: string): Array<Pages> => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          pages {
            name
            slug
            sections {
              name
              onlyLeftSide
              slug
            }
          }
        }
      }
    }
  `);

  return site.siteMetadata.pages
    .filter((p: Pages) => p.slug === pathname)
    .map((p: Pages) => p.sections)[0];
};

const SecondaryNavigation = ({ location, visible }: Props) => {
  const [pageSection, setPageSection] = useContext(PageSectionContext);
  const sections = useSections(location);
  const scrollbarWidth = useContext(ScrollbarWidthContext);
  const scrollContainer = useContext(ScrollContainerContext);
  const { t } = useTranslation('menu');
  // Translated Page
  const TP = (Array.from(t('list', { returnObjects: true })) as Pages[]).filter(
    (p: Pages) => p.slug === pageSection[0]
  )[0] as Pages;

  const onClick = (to: string) => {
    setPageSection([location+"/", to]);
    const el = document.getElementById(to);

    if (el && scrollContainer.current) {
      scrollContainer.current.scrollTo({ top: el.offsetTop, behavior: 'smooth' });
    }
  };

  return (
    <SecondaryNav visible={visible} scrollbarWidth={scrollbarWidth}>
      {sections &&
        sections
          .filter((s: Sections) => !s.onlyLeftSide)
          .map((s: Sections, index: number) => (
            <SecondaryNavItem
              active={
                pageSection[1] !== '' ? pageSection[1] === s.slug : s.slug === sections[0].slug
              }
              key={s.slug}
              onClick={() => onClick(s.slug)}
            >
              <p>
                {TP &&
                TP.sections &&
                TP.sections.filter((section: Sections) => section.slug === s.slug)[0]
                  ? TP.sections.filter((section: Sections) => section.slug === s.slug)[0].name
                  : s.name}
              </p>
            </SecondaryNavItem>
          ))}
    </SecondaryNav>
  );
};

export default SecondaryNavigation;
