import React from 'react';
import styled from 'styled-components';
import { colors, gridMobile, grid } from '../Variables';

const remW = '0.4rem';
const remH = '0.15rem';

export const Tab = styled(({ active, ...rest }) => <li {...rest} />)`
  flex: 0 0 auto;
  transition: opacity 300ms ease;

  &:hover {
    @media (min-width: 1024px) {
      opacity: 0.6;
    }
  }

  & > button {
    text-align: left;
  }

  & > button span {
    position: relative;
    font-family: RobotoSlab-Regular, sans-serif;
    font-size: calc(${grid.col7} / 8);
    line-height: 1.5;
    color: ${colors.blue};
    background-image: linear-gradient(to right, ${colors.blue} ${remH}, rgba(255, 255, 255, 0) 0%);
    background-position: 0 93%;
    background-size: ${remW} ${remH};
    background-repeat: repeat-x;
    cursor: pointer;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      padding-bottom: 5px;
      font-size: 12px;
      line-height: 16px;
      color: ${(props) => (props.active ? colors.blue : colors.black)};
      border-bottom: 2px solid ${(props) => (props.active ? colors.blue : 'transparent')};
      transition: color 300ms ease, border 300ms ease;
      background-image: none;
      z-index: 1;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      font-size: 45px;
      line-height: 76px;
    }

    @media (min-width: 1400px) {
      font-size: calc(${grid.col7} / 8);
    }

    @media (min-width: 1920px) {
      font-size: calc(${grid.col7} / 8);
    }
  }

  ul > & {
    margin-right: calc(${grid.col1} / 2);

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-right: 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-right: 0;
      margin-left: 0;
    }
  }
`;

export const Tabs = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    position: relative;
    left: calc(-1 * ${gridMobile.gridMargin});
    flex-wrap: nowrap;
    min-width: calc(${gridMobile.col6} + ${gridMobile.gridMargin} * 2);
    padding: 0 ${gridMobile.gridMargin};
    height: 40px;
    margin-top: 30px;
    overflow-x: auto;

    li + li {
      margin-left: 25px;
    }

    li:last-child {
      padding-right: ${gridMobile.gridMargin};
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
  }
`;
