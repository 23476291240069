import { useState, useEffect } from 'react';
import useResize from './useResize';

const useTablet = () => {
  const [isTablet, setIsTablet] = useState(false);
  const [W, H] = useResize();

  useEffect(() => {
    const { matchMedia } = window;
    const query = '(min-width: 768px) and (max-width: 1024px) and (orientation: portrait)';
    const tablet = matchMedia('(pointer: coarse)').matches;

    setIsTablet(matchMedia(query).matches.valueOf() && tablet);
  }, [W, H, isTablet]);

  return isTablet;
};

export default useTablet;
