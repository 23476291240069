import React, { useState } from 'react';
import { useMobile, useTablet } from '../../hooks';

const colors = { hovered: '#00a0cc', default: '#d6d6d6' };

const Vimeo = () => {
  const isMobile = useMobile();
  const isTouch = useTablet();
  const [color, setColor] = useState(colors.default);

  const handleMouseEnter = () => {
    if (isMobile || isTouch) return;

    setColor(colors.hovered);
  };

  const handleMouseLeave = () => {
    if (isMobile || isTouch) return;

    setColor(colors.default);
  };

  return (
    <a
      href="https://vimeo.com/user114009096"
      rel="noopener noreferrer"
      target="_blank"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg
        aria-label="vimeo"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill={color}
        />
        <path
          d="M15 7.7497C14.9375 8.7497 14.25 10.0622 12.9375 11.7497C11.5625 13.4997 10.4375 14.3747 9.5 14.3747C8.9375 14.3747 8.4375 13.8122 8 12.7497C7.75 11.7497 7.4375 10.7497 7.1875 9.8122C6.875 8.7497 6.5625 8.1872 6.25 8.1872C6.1875 8.1872 5.9375 8.3747 5.4375 8.6872L4.9375 8.0622C5.4375 7.6247 5.9375 7.1872 6.375 6.7497C7.0625 6.1872 7.5 5.8747 7.875 5.8747C8.625 5.8122 9.125 6.3122 9.3125 7.4372C9.5 8.6872 9.625 9.4372 9.6875 9.7497C9.9375 10.7497 10.1875 11.2497 10.4375 11.2497C10.625 11.2497 10.9375 10.9372 11.375 10.2497C11.8125 9.5622 12 9.0622 12.0625 8.7497C12.125 8.1872 11.875 7.8747 11.375 7.8747C11.125 7.8747 10.875 7.9372 10.625 8.0622C11.125 6.4372 12.0625 5.6872 13.4375 5.7497C14.5625 5.7497 15.0625 6.4372 15 7.7497Z"
          fill="white"
        />
      </svg>
    </a>
  );
};

export default Vimeo;
