import React, { useState } from 'react';
import { useMobile, useTablet } from '../../hooks';

const colors = { hovered: '#00a0cc', default: '#d6d6d6' };

const Instagram = () => {
  const isMobile = useMobile();
  const isTouch = useTablet();
  const [color, setColor] = useState(colors.default);

  const handleMouseEnter = () => {
    if (isMobile || isTouch) return;

    setColor(colors.hovered);
  };

  const handleMouseLeave = () => {
    if (isMobile || isTouch) return;

    setColor(colors.default);
  };

  return (
    <a
      href="https://www.instagram.com/albufeira_health_institute/"
      rel="noopener noreferrer"
      target="_blank"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg
        aria-label="Instagram"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.0393 11.7602C11.0113 11.7602 11.7993 10.9723 11.7993 10.0002C11.7993 9.02821 11.0113 8.24023 10.0393 8.24023C9.06728 8.24023 8.2793 9.02821 8.2793 10.0002C8.2793 10.9723 9.06728 11.7602 10.0393 11.7602Z"
          fill={color}
        />
        <path
          d="M12.2388 5.67969H7.83875C7.19875 5.67969 6.63875 5.87969 6.27875 6.23969C5.91875 6.59969 5.71875 7.15969 5.71875 7.79969V12.1997C5.71875 12.8397 5.91875 13.3997 6.31875 13.7997C6.71875 14.1597 7.23875 14.3597 7.87875 14.3597H12.2388C12.8788 14.3597 13.4388 14.1597 13.7988 13.7997C14.1988 13.4397 14.3988 12.8797 14.3988 12.2397V7.83969C14.3988 7.19969 14.1988 6.67969 13.8388 6.27969C13.4388 5.87969 12.9188 5.67969 12.2388 5.67969ZM10.0388 12.7197C8.51875 12.7197 7.31875 11.4797 7.31875 9.99969C7.31875 8.47969 8.55875 7.27969 10.0388 7.27969C11.5188 7.27969 12.7988 8.47969 12.7988 9.99969C12.7988 11.5197 11.5588 12.7197 10.0388 12.7197ZM12.8788 7.79969C12.5188 7.79969 12.2388 7.51969 12.2388 7.15969C12.2388 6.79969 12.5188 6.51969 12.8788 6.51969C13.2388 6.51969 13.5188 6.79969 13.5188 7.15969C13.5188 7.51969 13.2388 7.79969 12.8788 7.79969Z"
          fill={color}
        />
        <path
          d="M10.1193 0.0800781C4.6793 0.0800781 0.279297 4.48008 0.279297 9.92008C0.279297 15.3601 4.6793 19.7601 10.1193 19.7601C15.5593 19.7601 19.9593 15.3601 19.9593 9.92008C19.9993 4.48008 15.5593 0.0800781 10.1193 0.0800781ZM15.3593 12.2401C15.3593 13.1601 15.0393 13.9601 14.4793 14.5201C13.9193 15.0801 13.1193 15.3601 12.2393 15.3601H7.8793C6.9993 15.3601 6.1993 15.0801 5.6393 14.5201C5.0393 13.9601 4.7593 13.1601 4.7593 12.2401V7.84008C4.7593 6.00008 5.9993 4.72008 7.8793 4.72008H12.2793C13.1993 4.72008 13.9593 5.04008 14.5193 5.60008C15.0793 6.16008 15.3593 6.92008 15.3593 7.84008V12.2401V12.2401Z"
          fill={color}
        />
      </svg>
    </a>
  );
};

export default Instagram;
