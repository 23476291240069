import { useStaticQuery, graphql } from 'gatsby';

export const useHomeServicesImages = () => {
  const {image1, image4, image5, image8, image13, image14, image15 } = useStaticQuery(graphql`
    query HomeServicesImages {
      image1: file(relativePath: { eq: "home/services/oral-surgery.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "home/services/pediatric-dentistry.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image5: file(relativePath: { eq: "home/services/orthodontics.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image8: file(relativePath: { eq: "home/services/oral-hygiene.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image13: file(relativePath: { eq: "home/services/emergencies.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image14: file(relativePath: { eq: "home/services/interventional-radiology.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image15: file(relativePath: { eq: "home/services/general-surgery.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return [
    { name: 'Oral Surgery', src: image1.childImageSharp.fluid },
    { name: 'Pediatric Dentistry', src: image4.childImageSharp.fluid },
    { name: 'Orthodontics', src: image5.childImageSharp.fluid },
    { name: 'Oral Hygiene', src: image8.childImageSharp.fluid },
   //{ name: 'Laboratory', src: image12.childImageSharp.fluid },
   { name: 'General Surgery', src: image15.childImageSharp.fluid },
    { name: 'Emergencies', src: image13.childImageSharp.fluid },
    { name: 'Interventional Radiology', src: image14.childImageSharp.fluid },
  ];
};
