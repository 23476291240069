import React from 'react';
import { StyledLinkToPage } from './LinkToPage.styles';

interface Props {
  children: React.ReactNode;
  to: string;
}

const LinkToPage = ({ children, to }: Props) => {
  return <StyledLinkToPage to={to}>{children}</StyledLinkToPage>;
};

export default LinkToPage;
