import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Section } from '../Layout.styles';
import Link from '../Link';
import { colors, gridTablet, gridMobile, grid } from '../Variables';

export const SectionTeam = styled(Section)`
  padding-top: 120px;
  padding-bottom: 50px;
  scroll-snap-stop: unset;

  & > h6 {
    width: 100%;
  }
`;

export const Departament = styled.p`
  margin-top: 6px;
  font-family: RobotoSlab-Regular, sans-serif;
  font-size: 10px;
  line-height: 13px;
  color: ${colors.black};
`;

export const EmployeeName = styled.p`
  margin-top: 13px;
  font-family: RobotoSlab-Regular, sans-serif;
`;

export const EmployeePosition = styled.p`
  margin-top: 1px;
  font-family: RobotoSlab-Light, sans-serif;
`;

export const Employee = styled(({ ...rest }) => <Link {...rest} />)`
  width: calc((100% - ${grid.gridGutter} * 4) / 5);
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: calc(${gridMobile.col3});
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col4});
  }

  ${EmployeeName},
  ${EmployeePosition} {
    font-size: 15px;
    line-height: 20px;
    color: ${colors.black};

    @media (min-width: 768px) and (max-width: 1279px) {
      font-size: 12px;
      line-height: 16px;
    }

    @media (min-width: 1280px) and (max-width: 1399px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (min-width: 1920px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
`;

export const EmployeePhoto = styled(Img)`
  height: calc(((${grid.col16} + ${grid.gridGutter}) / 5 - ${grid.gridGutter}) * 1.238);

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: calc(${gridMobile.col3});
    height: calc(${gridMobile.col3} * 1.240259);
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    height: calc(${gridTablet.col4} * 1.21875);
  }
`;

export const TeamGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    & > ${Employee}:nth-child(2n + 2) {
      margin-left: ${grid.gridGutter};
    }

    & > ${Employee}:nth-child(n + 3)  {
      margin-top: 30px;
    }
  }

  @media (min-width: 1024px) {
    ${Employee} + ${Employee} {
      margin-left: ${grid.gridGutter};
    }

    & > ${Employee}:nth-child(n + 6) {
      margin-top: 30px;
    }

    & > ${Employee}:nth-child(5n) + ${Employee} {
      margin-left: 0;
    }
  }


  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    margin-top: 25px;

    ${Employee} + ${Employee} {
      margin-left: ${gridTablet.gridMargin};
    }

    & > ${Employee}:nth-child(n + 4) {
      margin-top: 20px;
    }

    & > ${Employee}:nth-child(3n + 4) {
      margin-left: 0;
    }
  }

  @media (min-width: 1920px) {
    margin-top: 60px;
  }
`;
