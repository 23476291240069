import React, { useRef, useEffect, useState, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useHomeServicesImages } from './Home.images';
import {
  Heading,
  Links,
  MainSection,
  Section,
  Services,
  ServicesItem,
  ServicesSubItem,
  ServicesItemBbImage,
} from './Home.styles';
import { PageSectionContext, ScrollbarWidthContext } from '../layout';
import { SectionHeading, MainText } from '../Layout.styles';
import LinkToPage from '../LinkToPage/LinkToPage';
import { grid } from '../Variables';
import SEO from '../seo';
import { useMobile, useResize, useScrollPosition, useTablet } from '../../hooks';

export const useHomeBg = () => {
  const { image } = useStaticQuery(graphql`
    query HomeBg {
      image: file(relativePath: { eq: "home/home.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return image.childImageSharp.fluid;
};

const Home: React.FC<RouteComponentProps> = ({ location }) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isIE11 =
    typeof window !== 'undefined' &&
    !!window.MSInputMethodContext &&
    !!(document as any).documentMode;
  const position = useScrollPosition();
  const scrollbarWidth = useContext(ScrollbarWidthContext);
  const section = useRef<HTMLDivElement>(null);
  const services = useRef<HTMLDivElement>(null);
  const servicesImages = useHomeServicesImages();
  const setPageSection = useContext(PageSectionContext)[1];
  const [scrollHeight, setScrollHeight] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { t } = useTranslation(['home', 'about', 'clinical-cases', 'services']);
  const [W, H] = useResize();

  
  /**
   * Set horizontal scroll
   */
  useEffect(() => {
    if (isMobile || isTablet || isIE11 || W < 1025) {
      setScrollPosition(0);
      return;
    }

    setScrollPosition(Math.max(0, position - H));
  }, [isMobile, isTablet, isIE11, position, W, H]);

  /**
   * Set scroll height
   */
  useEffect(() => {
    if (isMobile || isTablet) {
      setScrollHeight(0);
      return;
    }

    if (section.current && services.current) {
      /** Section Height */
      const sH = section.current.offsetHeight;

      /**  If the height of the section is greater than the height of the window - get height difference */
      const d = sH > H ? sH - H : 0;

      /** Services side indent */
      const indent = services.current.offsetLeft * 2;

      /**  Scrollbar width */
      const sBW = scrollbarWidth ? scrollbarWidth : 0;

      setScrollHeight(sH + services.current.scrollWidth - W + indent - d + sBW);
    }
  }, [isMobile, isTablet, scrollbarWidth, W, H]);

const currentPath = location?.pathname ? location?.pathname : '';

  return (
    <>
      <SEO title={t('home:title')} description={t('home:aboutHomeHeader')} path={currentPath}/>
      <MainSection id="intro">
        <Heading>{t('home:title')}</Heading>
        <Links>
          <LinkToPage to="/about">{t('about:About us')}</LinkToPage>
          <button onClick={() => setPageSection(['/clinical-cases/', 'testimonials'])}>
            {t('clinical-cases:testimonials.title')}
          </button>
        </Links>
        <Img
          alt="Home"
          fluid={useHomeBg()}
          imgStyle={{ objectFit: `contain` }}
          style={{
            position: `absolute`,
            top: isMobile || isTablet ? '10%' : 0,
            right: 0,
            bottom: isMobile || isTablet ? 'auto' : '160px',
            left: isMobile || isTablet ? `0` : `calc(${grid.col6})`,
            pointerEvents: `none`,
            objectFit: `contain`,
          }}
        />
      </MainSection>
      <Section
        isIE11={isIE11}
        style={{
          height: !(isMobile || W < 1025 || isIE11) && scrollHeight ? `${scrollHeight}px` : '',
        }}
      >
        <div id="services" ref={section}>
          <SectionHeading>
            <strong>{t('home:Introduction')}</strong> — <span>{t('services:Services')}</span>
          </SectionHeading>
          <MainText>
            <Trans i18nKey="text" components={{ bold: <strong /> }}>
              {t('text')}
            </Trans>
          </MainText>

          <Services isIE11={isIE11} isTablet={isTablet}>
            <div ref={services} style={{ transform: `translateX(${-scrollPosition}px)` }}>
              <ServicesItem
                style={{ background: `#d5d8dd` }}
                onClick={() => setPageSection(['/services/', 'oral-surgery'])}
              >
                <p>{t('services:servicesList.3.name')}</p>
                <ServicesItemBbImage
                  alt="Oral Surgery"
                  fluid={servicesImages[0].src}
                  style={{
                    position: `absolute`,
                    top: isIE11 ? '50%' : '0',
                    left: isIE11 ? '50%' : '0',
                    width: `100%`,
                    height: `100%`,
                    pointerEvents: `none`,
                    objectFit: `contain`,
                  }}
                />
              </ServicesItem>

              <ServicesItem double={true}>
                <ServicesSubItem
                  style={{ background: `#4bb6d0` }}
                  onClick={() => setPageSection(['/services/', 'implantology'])}
                >
                  <p>{t('services:servicesList.1.name')}</p>
                </ServicesSubItem>
                <ServicesSubItem
                  style={{ background: `#c0d1db` }}
                  onClick={() => setPageSection(['/services/', 'periodontology'])}
                >
                  <p>{t('services:servicesList.2.name')}</p>
                </ServicesSubItem>
              </ServicesItem>

              <ServicesItem
                style={{ background: `#20c6a7` }}
                onClick={() => setPageSection(['/services/', 'pediatric-dentistry'])}
              >
                <p>{t('services:servicesList.4.name')}</p>
                <ServicesItemBbImage
                  alt="Pediatric Dentistry"
                  fluid={servicesImages[1].src}
                  style={{
                    position: `absolute`,
                    top: isIE11 ? '50%' : '0',
                    left: isIE11 ? '50%' : '0',
                    width: `100%`,
                    height: `100%`,
                    pointerEvents: `none`,
                    objectFit: `contain`,
                  }}
                />
              </ServicesItem>

              <ServicesItem
                style={{ background: `#d7e0e5` }}
                onClick={() => setPageSection(['/services/', 'orthodontics'])}
              >
                <p>{t('services:servicesList.5.name')}</p>
                <ServicesItemBbImage
                  alt="Orthodontics"
                  fluid={servicesImages[2].src}
                  style={{
                    position: `absolute`,
                    top: isIE11 ? '50%' : '0',
                    left: isIE11 ? '50%' : '0',
                    width: `100%`,
                    height: `100%`,
                    pointerEvents: `none`,
                    objectFit: `contain`,
                  }}
                />
              </ServicesItem>

              <ServicesItem double={true}>
                <ServicesSubItem
                  style={{ background: `#5790bA` }}
                  onClick={() => setPageSection(['/services/', 'endodontics'])}
                >
                  <p>{t('services:servicesList.6.name')}</p>
                </ServicesSubItem>
                <ServicesSubItem
                  style={{ background: `#bfd1db` }}
                  onClick={() => setPageSection(['/services/', 'conscious-sedation'])}
                >
                  <p>{t('services:servicesList.10.name')}</p>
                </ServicesSubItem>
              </ServicesItem>

              <ServicesItem
                style={{ background: `#8FC2D5` }}
                onClick={() => setPageSection(['/services/', 'oral-hygiene'])}
              >
                <p>{t('services:servicesList.7.name')}</p>
                <ServicesItemBbImage
                  alt="Oral Hygien"
                  fluid={servicesImages[3].src}
                  style={{
                    position: `absolute`,
                    top: isIE11 ? '50%' : '0',
                    left: isIE11 ? '50%' : '0',
                    width: `100%`,
                    height: `100%`,
                    pointerEvents: `none`,
                    objectFit: `contain`,
                  }}
                />
              </ServicesItem>


              <ServicesItem double={true}>
                <ServicesSubItem
                style={{ background: `#b1e0ea` }}
                onClick={() => setPageSection(['/services/', 'physiotherapy'])}>
                <p>{t('services:servicesList.14.name')}</p>
                </ServicesSubItem>

                <ServicesSubItem
                style={{ background: `#00558b` }}
                onClick={() => setPageSection(['/services/', 'facial-harmonization'])}>
                <p>{t('services:servicesList.11.name')}</p>
                </ServicesSubItem>
              </ServicesItem>

              <ServicesItem double={true}>
                <ServicesSubItem
                  style={{ background: `#8ad6a8` }}
                  onClick={() => setPageSection(['/services/', 'imagiology'])}
                >
                  <p>{t('services:servicesList.12.name')}</p>
                </ServicesSubItem>
                <ServicesSubItem
                  style={{ background: `#8edada` }}
                  onClick={() =>
                    setPageSection(['/services/', 'oral-rehabilitation-and-aesthetic-dentistry'])
                  }
                >
                  <p>{t('services:servicesList.8.name')}</p>
                </ServicesSubItem>
              </ServicesItem>

              <ServicesItem
                style={{ background: `#d7dde2` }}
                onClick={() => setPageSection(['/services/', 'general-surgery'])}
              >
                <p>{t('services:servicesList.16.name')}</p>
                <ServicesItemBbImage
                  alt="General Surgery"
                  fluid={servicesImages[4].src}
                  style={{
                    position: `absolute`,
                    top: isIE11 ? '50%' : '0',
                    left: isIE11 ? '50%' : '0',
                    width: `100%`,
                    height: `100%`,
                    pointerEvents: `none`,
                    objectFit: `contain`,
                  }}
                />
              </ServicesItem>

              <ServicesItem
                style={{ background: `#FFBDA8` }}
                onClick={() => setPageSection(['/services/', 'emergencies'])}
              >
                <p>{t('services:servicesList.0.name')}</p>
                <ServicesItemBbImage
                  alt="Emergencies"
                  fluid={servicesImages[5].src}
                  style={{
                    position: `absolute`,
                    top: isIE11 ? '50%' : '0',
                    left: isIE11 ? '50%' : '0',
                    width: `100%`,
                    height: `100%`,
                    pointerEvents: `none`,
                    objectFit: `contain`,
                  }}
                />
              </ServicesItem>
              <ServicesItem
                style={{ background: `#FFBDA8` }}
                onClick={() => setPageSection(['/services/', 'interventional-radiology'])}
              >
                <p>{t('services:servicesList.15.name')}</p>
                <ServicesItemBbImage
                  alt="Interventional Radiology"
                  fluid={servicesImages[6].src}
                  style={{
                    position: `absolute`,
                    top: isIE11 ? '50%' : '0',
                    left: isIE11 ? '50%' : '0',
                    width: `100%`,
                    height: `100%`,
                    pointerEvents: `none`,
                    objectFit: `contain`,
                  }}
                />
              </ServicesItem>
            </div>
          </Services>
        </div>
      </Section>
    </>
  );
};

export default Home;
