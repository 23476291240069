import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem } from './ServicesList.styles';
import { LeftSectionsItemsContext, ScrollContainerContext, Sections } from '../layout';

interface Props {
  sections: Sections[];
}

const ServicesList = ({ sections }: Props) => {
  const scrollContainer = useContext(ScrollContainerContext);
  const setLeftSectionsItems = useContext(LeftSectionsItemsContext)[1];
  const { t } = useTranslation('services');

  const handleChangeRoom = (slug: string) => {
    setLeftSectionsItems(['/services', slug]);

    const el = document.getElementById(slug);

    if (el && scrollContainer.current) {
      scrollContainer.current.scrollTo({ top: el.offsetTop, behavior: 'smooth' }); //enables the serviceslist links
    }
  };

  return (
    <List>
      {sections &&
        sections.map((s: Sections, index: number) => (
          <ListItem key={s.slug}>
            <button onClick={() => handleChangeRoom(s.slug)}>
              <span>{t(`servicesList.${index}.name`)}</span>
            </button>
          </ListItem>
        ))}
    </List>
  );
};

export default ServicesList;
