import React, { useEffect, useRef, useState } from 'react';
import {
  Gallery,
  GalleryImage,
  GalleryThumbs,
  GalleryThumb,
  Indicators,
  Indicator,
} from './MediaArticleGallery.styles';
import { useMobile } from '../../hooks';

interface Image {
  image: { alt: string; url: string };
}

interface Props {
  images: Image[] | undefined;
}

const MediaArticleGallery = ({ images }: Props) => {
  const [activeImage, setActiveImage] = useState(0);
  const gallery = useRef<HTMLDivElement>(null);
  const isMobile = useMobile();

  const handleChangeImage = (index: number) => {
    setActiveImage(index);
  };

  useEffect(() => {
    if (!isMobile || !gallery.current) return;

    const images = Array.from(gallery.current.children);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveImage(images.findIndex((i: Element) => entry.target === i));
          }
        });
      },
      { threshold: 0.5 }
    );

    images.forEach((el: Element) => observer.observe(el));

    return () => observer.disconnect();
  });

  if (typeof images === 'undefined') return null;

  return (
    <>
      <Gallery forwardRef={gallery}>
        {images instanceof Array &&
          images.map((img: Image, index: number) => (
            <GalleryImage
              active={activeImage === index}
              draggable="false"
              changeHeight={images.length > 1}
              alt={img.image && img.image.alt ? img.image.alt : ''}
              key={index}
              src={img.image && img.image.url ? img.image.url : ''}
            />
          ))}

        {images.length > 1 && !isMobile && (
          <GalleryThumbs>
            {images.map((img: Image, index: number) => (
              <GalleryThumb
                active={activeImage === index}
                key={index}
                onClick={() => handleChangeImage(index)}
              >
                <img
                  alt={img.image && img.image.alt ? img.image.alt : ''}
                  src={img.image && img.image.url ? img.image.url : ''}
                />
              </GalleryThumb>
            ))}
          </GalleryThumbs>
        )}
      </Gallery>
      {images.length > 0 && isMobile && (
        <Indicators>
          {images.map((_img: Image, index: number) => (
            <Indicator active={activeImage === index} key={index} />
          ))}
        </Indicators>
      )}
    </>
  );
};

export default MediaArticleGallery;
