import React from 'react';
import styled from 'styled-components';
import { MainText, SecondaryText, Section } from '../Layout.styles';
import { colors, gridTablet, gridMobile, grid } from '../Variables';

export const AnimationHeight = styled(({ active, ...rest }) => <div {...rest} />)`
  max-height: ${(props) => (props.active ? '500px' : '0')};
  transition: max-height ${(props) => (props.active ? 500 : 600)}ms ease;
  overflow: hidden;

  p {
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity ${(props) => (props.active ? 400 : 600)}ms ease;
    transition-delay: ${(props) => (props.active ? 100 : 0)}ms;
  }
`;

export const ConsultationRoomList = styled.ul`
  width: calc(100% - ${grid.navWidth} - ${grid.gridMargin});
  margin-top: 30px;
  border-top: 1px solid #dedede;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: 100%;
  }
`;

export const ConsultationRoomListItem = styled.li`
  padding: 30px 0;
  border-bottom: 1px solid #dedede;

  h6 {
    font-family: RobotoSlab-Medium, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.black};

    @media (min-width: 1280px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (min-width: 1400px) {
      font-size: 16px;
      line-height: 21px;
    }

    @media (min-width: 1920px) {
      font-size: 22px;
      line-height: 29px;
    }
  }

  p {
    max-width: 500px;
    margin-top: 10px;
    font-family: RobotoSlab-Light, sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: ${colors.black};

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: calc(${gridTablet.col11});
    }

    @media (min-width: 1280px) {
      font-size: 14px;
      line-height: 25px;
    }

    @media (min-width: 1400px) {
      width: calc(${grid.col7});
    }

    @media (min-width: 1920px) {
      font-size: 22px;
      line-height: 29px;
    }
  }
`;

export const MainRoomsSection = styled(Section)`
  position: relative;
  padding-top: 151px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    position: sticky;
    top: 0;
    min-height: auto;
    padding-top: 75px;
    z-index: 3;
    background: #fff;
    overflow-x: hidden;

    &::after {
      content: '';
      position: absolute;
      bottom: 17px;
      right: 0;
      left: 0;
      min-width: calc(${gridMobile.col6} + ${gridMobile.gridMargin} * 2);
      height: 1px;
      background-color: #e6e6e6;
      z-index: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    min-height: auto;
  }

  h6 {
    width: calc(${grid.col6});
    margin-top: 50px;
    font-family: RobotoSlab-Medium, sans-serif;
    font-size: 16px;
    line-height: 21px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      display: none;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: calc(${gridTablet.col7});
      margin-top: 25px;
    }
  }
`;

export const RoomContent = styled.div`
  flex: 0 0 auto;
  width: calc(${grid.col9});

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    order: 1;
    width: 100%;
    white-space: pre-line;

    h5 {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    order: 1;
    width: calc(${grid.col8});
    margin-top: 45px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    order: 1;
    width: calc(${gridTablet.col12});
    margin-top: 45px;
  }

  ${MainText} {
    width: calc(${grid.col8});
    margin-top: 25px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: 100%;
      margin-top: 30px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: calc(${gridTablet.col10});
    }

    @media (min-width: 1280px) {
      margin-top: 50px;
    }

    @media (min-width: 1400px) {
      margin-top: 40px;
    }

    @media (min-width: 1920px) {
      margin-top: 60px;
    }
  }

  ${SecondaryText} {
    max-width: 500px;
    margin-top: 30px;
  }
`;

export const RoomsSection = styled(Section)`
  position: relative;
  display: flex;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    display: inline-flex;
    flex-direction: column;
    max-width: 100vw;
    padding-top: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    min-height: auto;
    padding-top: 100px;
    padding-bottom: 40px;
  }
`;

export const RoomsWrapper = styled.div`
  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    overflow: hidden;
    white-space: nowrap;

    & > div {
      transition: transform 450ms ease;
    }
  }
`;
