import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { gridMobile, grid, gridTablet } from '../Variables';

export const StyledGallery = styled(({ forwardRef, scrollbarWidth, ...rest }) => (
  <div ref={forwardRef} {...rest} />
))`
  position: fixed;
  top: 0;
  right: calc(
    ${(props) => `${props.scrollbarWidth ? props.scrollbarWidth : 0}px + ${grid.navWidth}`}
  );
  bottom: 0;
  width: calc(
    ${grid.col7} + ${grid.gridMargin} + ${grid.gridGutter} -
      ${(props) => props.scrollbarWidth || 0}px
  );
  pointer-events: none;
  opacity: 0;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    position: relative;
    right: 0;
    width: calc(${gridMobile.col6});
    height: calc(${gridMobile.col6});
    opacity: 1;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    position: relative;
    left: calc(-1 * (${grid.navWidth} + ${gridTablet.gridMargin}));
    right: 0;
    opacity: 1;
    width: calc(100% + ${grid.navWidth} + ${gridTablet.gridMargin} * 2);
    height: 458px;
  }
`;

export const GalleryImage = styled(({ active, ...rest }) => <Img {...rest} />)`
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: opacity ${(props) => (props.active ? 400 : 600)}ms ease;
`;
