import React from 'react';
import styled from 'styled-components';
import { ContactUsLink, NavBtn } from '../Navigation/Navigation.styles';
import { StyledLogo } from '../Logo/Logo.styles';
import { colors, grid, gridMobile } from '../Variables';

export const PreferredLang = styled(({ visible, ...rest }) => <div {...rest} />)`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 72px;
  padding: 0 23px 0 calc(${grid.navWidth} + ${grid.gridMargin});
  transform: translateY(${(props) => (props.visible ? 0 : -72)}px);
  transition: transform 300ms ease;
  background-color: ${colors.blue};
  z-index: 12;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    padding: 0 ${gridMobile.gridMargin};
  }

  p {
    flex: 0 0 auto;
    font-family: RobotoSlab-Regular, sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #fff;

    button {
      color: #fff;
      font-family: RobotoSlab-Medium, sans-serif;
      border-bottom: 1px solid #fff;
      cursor: pointer;
    }

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: ${gridMobile.col5};
    }
  }

  & ~ ${StyledLogo}, & ~ ${ContactUsLink}, & ~ ${NavBtn} {
    ${(props) => (props.visible ? 'transform: translateY(72px);' : null)}
  }
`;

export const PreferredLangBtn = styled.button`
  position: relative;
  flex: 0 0 auto;
  width: 22px;
  height: 17px;
  z-index: 11;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    border: 1px solid #fff;
    border-radius: 2px;
    background-color: #fff;
  }

  &::before {
    top: 4px;
    transform: translateY(4px) rotate(45deg);
  }

  &::after {
    bottom: 4px;
    transform: translateY(-3px) rotate(-45deg);
  }
`;
