import React from 'react';
import SEO from '../components/seo';
import { RouteComponentProps } from '@reach/router';
import {
  Heading,
  MainSection,
} from '../privacy-policy.styles';
import { Trans, useTranslation } from 'react-i18next';
// import { grid } from '../components/Variables';
// import { useMobile, useTablet } from '../hooks';

const PrivacyPolicyPage: React.FC<RouteComponentProps> = ({ location }) => {

  const { t } = useTranslation(['privacy']);
  // const isMobile = useMobile();
  // const isTablet = useTablet();

  const currentPath = location?.pathname ? location?.pathname : '';

  return (
    <>
    <SEO title={t('title')} path={currentPath}/>
    <MainSection>
        <Heading>{t('title')}</Heading>
        <br /><br /><br />
    <Trans components={{ bold: <strong />, a:<a target="_blank"/>}}>
          {t('privacy-text')}
          </Trans>
          <br /><br /><br />
          {t('update')}
          <br /><br /><br />
          </MainSection>
</>
  );
};
export default PrivacyPolicyPage;