import { useStaticQuery, graphql } from 'gatsby';

export interface Item {
  title: string;
  text: string;
}

export const useConsultationRoomImages = () => {
  const { image1, image2, image3, image4 } = useStaticQuery(graphql`
    query ConsultationRoomImages {
      image1: file(relativePath: { eq: "about/rooms/consultation-room/consultation-room-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "about/rooms/consultation-room/consultation-room-02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "about/rooms/consultation-room/consultation-room-03.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "about/rooms/consultation-room/consultation-room-04.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return [
    { alt: 'Intra-oral cameras', src: image1.childImageSharp.fluid },
    { alt: 'Digital periapical x-ray technology', src: image2.childImageSharp.fluid },
    { alt: 'Magnifying Surgical Loupes', src: image3.childImageSharp.fluid },
    { alt: 'Virtual Private Theater Glasses', src: image4.childImageSharp.fluid },
  ];
};
