import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab, Tabs } from './RoomsTabs.styles';
import { useMobile } from '../../hooks';

interface Props {
  activeRoom?: number;
  onChangeRoom: (roomIndex: number, slug: string) => void;
}

interface Tab {
  name: string;
  slug: string;
}

const RoomsTabs = ({ activeRoom, onChangeRoom }: Props) => {
  const isMobile = useMobile();
  const { t } = useTranslation('about');
  const { listRooms } = t('rooms', { returnObjects: true });

  return (
    <Tabs>
      {listRooms &&
        listRooms.map((t: Tab, index: number) => (
          <Tab
            active={isMobile && activeRoom === index}
            key={index}
            onClick={() => onChangeRoom(index, t.slug)}
          >
            {isMobile ? (
              <button>
                <span>{t.name}</span>
              </button>
            ) : (
              <button>
                <span>{t.name}</span>
              </button>
            )}
          </Tab>
        ))}
    </Tabs>
  );
};

export default RoomsTabs;
