import React from 'react';
import SEO from '../components/seo';
import { RouteComponentProps } from '@reach/router';
import {
  Heading,
  MainSection,
} from '../404.styles';
import { useTranslation } from 'react-i18next';
import Img from 'gatsby-image';
import { grid } from '../components/Variables';
import { useMobile, useTablet } from '../hooks';
import { useStaticQuery, graphql } from 'gatsby';



export const use404Bg = () => {
  const { image } = useStaticQuery(graphql`
    query NotFoundBg {
      image: file(relativePath: { eq: "404/404.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return image.childImageSharp.fluid;
};

const NotFoundPage: React.FC<RouteComponentProps> = ({ location }) => {

  const { t } = useTranslation(['404']);
  const isMobile = useMobile();
  const isTablet = useTablet();

  const currentPath = location?.pathname ? location?.pathname : '';

  return (
    <>
    <SEO title="404 Error!" path={currentPath}/>
    <MainSection>
        <Heading>{t('404:404')}</Heading>
        <Img
          alt="Home"
          fluid={use404Bg()}
          imgStyle={{ objectFit: `contain` }}
          style={{
            position: `absolute`,
            top: isMobile || isTablet ? '20%' : 0,
            right: 0,
            bottom: isMobile || isTablet ? 'auto' : '160px',
            left: isMobile || isTablet ? `0` : `calc(${grid.col6})`,
            pointerEvents: `none`,
            objectFit: `contain`,
          }}/>
    </MainSection>
    </>
  );
};
export default NotFoundPage;