import React, { useState } from 'react';
import { useMobile, useTablet } from '../../hooks';

const colors = { hovered: '#00a0cc', default: '#d6d6d6' };

const Linkedin = () => {
  const isMobile = useMobile();
  const isTouch = useTablet();
  const [color, setColor] = useState(colors.default);

  const handleMouseEnter = () => {
    if (isMobile || isTouch) return;

    setColor(colors.hovered);
  };

  const handleMouseLeave = () => {
    if (isMobile || isTouch) return;

    setColor(colors.default);
  };

  return (
    <a
      href="https://www.linkedin.com/company/albufeira-health-institute/"
      rel="noopener noreferrer"
      target="_blank"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg
        aria-label="linked-in"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.29496 14.6079V8.50826H5.40972V14.6079H7.29496ZM7.29496 6.34622C7.28373 5.80037 6.91994 5.38477 6.32895 5.38477C5.73781 5.38477 5.35156 5.80037 5.35156 6.34622C5.35156 6.88022 5.72658 7.30784 6.30634 7.30784H6.31757C6.91994 7.30784 7.29496 6.88022 7.29496 6.34622ZM10.3706 14.6079V11.1326C10.3706 10.9466 10.3841 10.7606 10.4387 10.628C10.5883 10.2562 10.9286 9.87137 11.5 9.87137C12.2486 9.87137 12.718 10.2962 12.718 11.1326V14.6079H14.616V11.0396C14.616 9.12815 13.5956 8.23871 12.2346 8.23871C11.1186 8.23871 10.6288 8.8626 10.3568 9.28725L10.3704 8.47145H8.47575C8.50301 9.05539 8.47575 14.6079 8.47575 14.6079H10.3706Z"
          fill="white"
        />
      </svg>
    </a>
  );
};

export default Linkedin;
