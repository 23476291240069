import styled from 'styled-components';
import { grid, gridMobile, gridTablet, colors } from './Variables';

export const Main = styled.main`
  padding-right: ${grid.navWidth};
  background-color: #fff;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    padding-right: 0;
  }
`;

export const MainText = styled.p`
  font-family: RobotoSlab-Regular, sans-serif;
  font-size: 24px;
  line-height: 37px;
  color: ${colors.black};
  white-space: pre-line;

  strong {
    color: ${colors.blue};
  }

  @media (min-width: 1280px) {
    font-size: 35px;
    line-height: 51px;
  }

  @media (min-width: 1920px) {
    font-size: 50px;
    line-height: 73px;
  }
`;

export const ScrollContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  scroll-padding-bottom: 5vh;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    scroll-padding-bottom: 0;
  }
`;

export const SecondaryText = styled.p`
  font-family: RobotoSlab-Light, sans-serif;
  font-size: 14px;
  line-height: 25px;
  white-space: pre-wrap;
  color: ${colors.black};

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    font-size: 16px;
    line-height: 26px;
  }

  @media (min-width: 1920px) {
    font-size: 22px;
    line-height: 38px;
  }
`;

export const SectionHeading = styled.h5`
  font-size: 10px;
  line-height: 13px;

  @media (min-width: 1920px) {
    font-size: 14px;
    line-height: 18px;
  }

  strong {
    font-family: RobotoSlab-Medium, sans-serif;
  }

  span {
    font-family: RobotoSlab-Regular, sans-serif;
  }
`;

export const TopSectionHeading = styled.h1`
  font-size: 10px;
  line-height: 13px;

  @media (min-width: 1920px) {
    font-size: 14px;
    line-height: 18px;
  }

  strong {
    font-family: RobotoSlab-Medium, sans-serif;
  }

  span {
    font-family: RobotoSlab-Regular, sans-serif;
  }
`;

export const Section = styled.section`
  min-height: 100vh;
  padding-top: 180px;
  padding-right: ${grid.gridMargin};
  padding-left: calc(${grid.navWidth} + ${grid.gridMargin});
  scroll-snap-align: start;
  scroll-snap-stop: always;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    min-height: auto;
    padding-top: 115px;
    padding-right: ${gridMobile.gridMargin};
    padding-left: ${gridMobile.gridMargin};
    scroll-snap-align: unset;
    scroll-snap-stop: unset;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    padding-top: 120px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    padding-top: 140px;
    padding-right: ${gridTablet.gridMargin};
    padding-left: calc(${grid.navWidth} + ${gridTablet.gridMargin});
  }
`;
