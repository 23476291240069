import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SectionHeritage } from './Intro.styles';
import { WrapperText } from './About.styles';
import {SectionHeading, MainText } from '../Layout.styles';

const AboutIntro = () => {
  const { t } = useTranslation('about');

  return (
    <SectionHeritage id="about-intro">
      <SectionHeading>
       <strong>{t('About us')}</strong> — <span>{t('heritage.title')}</span>
      </SectionHeading>
      <SectionHeading>
        <span>2001 — {(new Date().getFullYear())}</span>
      </SectionHeading>
      <MainText>
        <Trans components={{ bold: <strong /> }}>{t('heritage.text')}</Trans>
      </MainText>

      <WrapperText>
        <p>{t('heritage.additionalText.0.aText')}</p>

        <p>{t('heritage.additionalText.1.aText')}</p>
      </WrapperText>
    </SectionHeritage>
  );
};

export default AboutIntro;
