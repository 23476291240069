import React from 'react';
import Link from '../Link';
import styled from 'styled-components';
import { colors } from '../Variables';

export const StyledLinkToPage = styled(({ ...rest }) => <Link {...rest} />)`
  padding: 12px 29px;
  border: 2px solid ${colors.blue};
  border-radius: 20.5px;
  transition: transform 300ms ease, opacity 300ms ease, background 300ms ease, border 300ms ease,
    color 300ms ease;
  z-index: 1;

  &:hover {
    color: ${(props) => (props.isBlue ? colors.black : '#fff')};
    opacity: 0.6;
    background-color: ${colors.blue};

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      opacity: 1;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      opacity: 1;
    }
  }

  // FONT
  font-family: RobotoSlab-Medium, sans-serif;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  color: ${colors.black};
`;
