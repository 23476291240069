import React, { useEffect, useRef, useState, useContext } from 'react';
import { FluidObject } from 'gatsby-image';
import { GalleryImage, StyledGallery } from './Gallery.styles';
import { ScrollbarWidthContext } from '../layout';
import { useMobile, useResize, useScrollPosition, useTablet } from '../../hooks';

export interface GalleryImage {
  alt?: string;
  src: FluidObject;
}

interface Props {
  activeItem?: number;
  images: GalleryImage[];
}

const Gallery = ({ activeItem, images }: Props) => {
  const [activeIndex, setActiveIndex] = useState(activeItem ? activeItem : 0);
  const gallery = useRef<HTMLDivElement>(null);
  const isMobile = useMobile();
  const isTablet = useTablet();
  const [isVisible, setIsVisible] = useState(false);
  const prevActiveItem = useRef(0);
  const scrollbarWidth = useContext(ScrollbarWidthContext);
  const scrollPosition = useScrollPosition();
  const [W, H] = useResize();

  /** Change active room by hover */
  useEffect(() => {
    if (typeof activeItem === 'undefined') return;
    setActiveIndex(activeItem);

    if (activeItem !== -1 && activeItem !== prevActiveItem.current) {
      prevActiveItem.current = activeItem;
    }
  }, [activeItem]);

  /** Auto change activeIndex */
  useEffect(() => {
    if (images.length <= 1 || !isVisible || typeof activeItem !== 'undefined') return;

    const timeoutID = setTimeout(() => {
      const ai =
        activeIndex === images.length - 1 ? 0 : Math.min(activeIndex + 1, images.length - 1);
      setActiveIndex(ai);
    }, 5000);

    return () => clearTimeout(timeoutID);
  }, [activeIndex, activeItem, images.length, isVisible]);

  /** Set gallery visibility */
  useEffect(() => {
    if (!gallery.current) return;
    const el = gallery.current.parentElement;
    const container = el && el.parentElement;
    const threshold = container && isMobile ? Math.min(W / container.scrollWidth, 1) : 0.5;

    const observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => setIsVisible(entry.isIntersecting)),
      { threshold }
    );
    if (el) observer.observe(el);

    return () => observer.disconnect();
  }, [isMobile, W]);

  /** Set opacity */
  useEffect(() => {
    if (isMobile || isTablet) {
      if (gallery.current && window.getComputedStyle(gallery.current, null)['opacity'] !== '1') {
        gallery.current.style.opacity = `1`;
      }
      return;
    }

    if (gallery.current) {
      const section = gallery.current.parentElement;
      const top = section ? section.offsetTop : 0;
      let opacity = section ? (scrollPosition - (top - H)) / H : 0;
      gallery.current.style.opacity = `${1 - Math.abs(opacity - 1)}`;
    }
  }, [H, isMobile, isTablet, scrollPosition]);

  return (
    <StyledGallery forwardRef={gallery} scrollbarWidth={scrollbarWidth}>
      {images.map((img: GalleryImage, index: number) => (
        <GalleryImage
          active={activeIndex !== -1 ? activeIndex === index : prevActiveItem.current === index}
          alt={img.alt}
          fluid={img.src}
          key={index}
          style={{
            position: `absolute`,
            top: 0,
            right: 0,
            bottom: 0,
            width: `100%`,
            pointerEvents: `none`,
          }}
        />
      ))}
    </StyledGallery>
  );
};

export default Gallery;
