import styled from 'styled-components';
import { Section } from '../Layout.styles';
import { colors } from '../Variables';

export const BG = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 300vh;
  background-color: ${colors.blue};
  opacity: 0;
  z-index: 0;
  pointer-events: none;


  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    height: 100%;
  }
`;

export const MainSection = styled(Section)`
  position: relative;
  padding-top: 150px;
  scroll-snap-stop: always;
  scroll-snap-align: start;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    min-height: unset;
    padding-bottom: 26px;
    background-color: ${colors.blue};
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    min-height: unset;
  }

  h5,
  h6 {
    position: relative;
    color: #fff;
    z-index: 1;
  }

  h6 {
    max-width: 500px;
    margin-top: 30px;
    font-family: RobotoSlab-Medium, sans-serif;
    font-size: 16px;
    line-height: 21px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      max-width: unset;
    }

    @media (min-width: 1024px) {
      margin-top: 15px;
    }

    @media (min-width: 1920px) {
      margin-top: 60px;
    }
  }
`;
