import React from 'react';
import styled from 'styled-components';
import { colors, gridTablet, grid } from '../Variables';
//import {useMobile, useResize} from '../../hooks'

export const BtnSubmit = styled(({ disabled, ...rest }) => <button {...rest} />)`
  width: 100%;
  height: 46px;
  font-family: RobotoSlab-Regular, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  background: ${(props) => (props.disabled ? colors.grey : colors.blue)};
  transition: 300ms ease, background 300ms ease;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  margin-bottom: 30px;

  &:hover {
    opacity: ${(props) => (props.disabled ? 1 : 0.6)};

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      opacity: 1;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      opacity: 1;
    }
  }

  @media (min-width: 1400px) {
    height: 65px;
    font-size: 16px;
    line-height: 21px;
  }
`;

export const MessageIsSended = styled.div`
  width: 100%;
  flex: 0 0 auto;
  width: calc(${grid.col6} + ${grid.gridGutter});
  margin-right: 40px;
  padding-top: 160px;
  padding-right: ${grid.gridGutter};

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: 100%;
    margin-right: 0;
    padding-top: 110px;
    padding-right: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col6} + ${gridTablet.gridGutter});
    margin-right: 0;
    padding-top: 110px;
    padding-right: calc(${gridTablet.gridGutter} + ${gridTablet.gridMargin});
  }

  @media (min-width: 1400px) {
    width: calc(${grid.col7} + ${grid.gridGutter});
  }

  button {
    margin-top: 30px;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    color: ${colors.black};
    border-bottom: 1px solid ${colors.blue};
    transition: opacity 300ms ease;
    cursor: pointer;

    @media (min-width: 1024px) {
      &:hover {
        opacity: 0.6;
      }
    }

    @media (min-width: 1440px) {
      font-size: 15px;
      line-height: 20px;
    }

    @media (min-width: 1920px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
`;

export const Form = styled.form`
  flex: 0 0 auto;
  width: calc(${grid.col6} + ${grid.gridGutter});
  height: 100vh;
  margin-right: 40px;
  padding-top: 160px;
  padding-right: ${grid.gridGutter};
  font-size: 11px;
  font-family: RobotoSlab-Light, sans-serif;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: 100%;
    height: auto;
    margin-right: 0;
    padding-top: 110px;
    padding-right: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col6} + ${gridTablet.gridGutter});
    height: auto;
    margin-right: 0;
    padding-top: 110px;
    padding-right: calc(${gridTablet.gridGutter} + ${gridTablet.gridMargin});
  }

  @media (min-width: 1400px) {
    width: calc(${grid.col7} + ${grid.gridGutter});
  }

 a {
    font-family: RobotoSlab-Light, sans-serif;
    line-height: 16px;
    color: ${colors.black};

    @media (min-width: 1400px) {
      font-size: 11px;
      line-height: 18px;
    }

    @media (min-width: 1920px) {
      font-size: 11px;
      line-height: 26px;
    }
  }

  a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: color 300ms ease;

    &:hover {
      color: ${colors.blue};

      span {
        border-bottom: 1px solid transparent;
      }

      @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
        color: ${colors.black};

        span {
          border-bottom: 1px solid ${colors.blue};
        }
      }

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        color: ${colors.black};

        span {
          border-bottom: 1px solid ${colors.blue};
        }
      }
    }

    span {
      display: inline;
      transition: border 300ms ease;
      border-bottom: 1px solid ${colors.blue};
    }
  }
`;

export const FormArrow = styled(({ active, ...rest }) => <div {...rest} />)`
  position: absolute;
  right: 0;
  bottom: 5px;
  pointer-events: none;
  transform: rotate(${(props) => (props.active ? 180 : 0)}deg);
  transition: transform 200ms ease;
`;

export const Placeholder = styled(({ onTop, ...rest }) => <p {...rest} />)`
  position: absolute;
  left: 0;
  bottom: 0;
  font-family: RobotoSlab-Light, sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: ${colors.black};
  transform: scale(${(props) => (props.onTop ? 0.65 : 1)})
    translateY(${(props) => (props.onTop ? -35 : 0)}px);
  transform-origin: left;
  transition: transform 200ms ease;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    font-size: 20px;
    line-height: 31px;
  }

  @media (min-width: 1400px) {
    font-size: 20px;
    line-height: 31px;
  }
`;

export const FormField = styled(({ children, error, focused, type, ...rest }) => (
  <label {...rest}>{children}</label>
))`
  position: relative;
  display: inline-block;
  width: ${(props) => (props.type === 'date' ? `calc(${grid.col3})` : '100%')};
  margin-bottom: 40px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: ${(props) => (props.type === 'date' ? 'calc(50% - 8px)' : '100%')};
  }

  @media (min-width: 1400px) {
    width: ${(props) => (props.type === 'date' ? `calc(${grid.col4})` : '100%')};
    margin-bottom: 50px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 80px;
  }

  &::before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0px;
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${(props) => (props.error ? '#d34347' : colors.blue)};
    transform: scale(${(props) => (props.focused || props.error ? 1 : 0)});
    transform-origin: center;
    transition: transform 400ms ease, border 300ms ease;
  }

  input::placeholder {
    color: #a9a9a9;
    opacity: ${(props) => (props.type === 'date' ? (props.focused ? 1 : 0) : 1)};
    transition: opacity 100ms ease;
    transition-delay: ${(props) => (props.type === 'date' ? (props.focused ? 100 : 0) : 0)}ms;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 100px white inset;
  }

  input {
    width: 100%;
    font-family: RobotoSlab-Light, sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: ${colors.black};
    margin: 0;
    padding: 0;
    appearance: none;
    background: transparent;
    border: none;
    border-radius: 0;
    outline: none;
    border-bottom: 1px solid ${colors.grey};

    &:focus {
      outline: 0;
    }

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 20px;
      line-height: 31px;
    }

    @media (min-width: 1400px) {
      font-size: 20px;
      line-height: 31px;
    }
  }
`;

export const FormSelect = styled(({ type, ...rest }) => <div {...rest} />)`
  position: relative;
  display: inline-block;
  width: ${(props) => (props.type === 'time' ? `calc(${grid.col3})` : '100%')};
  margin-top: ${(props) => (props.type === 'time' ? 0 : 30)}px;
  margin-bottom: 40px;
  margin-left: ${(props) => (props.type === 'time' ? grid.gridGutter : 0)};
  vertical-align: top;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    margin-left: 0;
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: ${(props) => (props.type === 'time' ? 'calc(50% - 8px)' : '100%')};
    margin-left: ${(props) => (props.type === 'time' ? gridTablet.gridGutter : 0)};
  }

  @media (min-width: 1400px) {
    margin-top: ${(props) => (props.type === 'time' ? 0 : 50)}px;
    margin-bottom: 50px;
  }

  @media (min-width: 1920px) {
    margin-top: ${(props) => (props.type === 'time' ? 0 : 80)}px;
    margin-bottom: 80px;
  }

  &::before {
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    bottom: -1px;
    display: block;
  }

  select {
    font-family: RobotoSlab-Light, sans-serif;
    font-size: 14px;
    line-height: 22px;
    appearance: none;
    border: 0;
    outline: 0;
    padding: 0;
    background: none;
    width: 100%;
    border-bottom: 1px dashed ${colors.grey};
    border-radius: 0;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 20px;
      line-height: 31px;
    }

    @media (min-width: 1400px) {
      width: ${(props) => (props.type === 'date' ? `calc(${grid.col4})` : '100%')};
      font-size: 20px;
      line-height: 31px;
    }
  }
`;

export const ErrorMessage = styled(({ visible, ...rest }) => <p {...rest} />)`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  font-family: RobotoSlab-Light, sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #d34347;
  pointer-events: none;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 300ms ease;
  white-space: nowrap;
`;
