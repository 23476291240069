export const navWidth = '70px';
export const gridMargin = '20px';
export const gridGutter = '20px';
export const col = `(100vw - ${navWidth} * 2 - 15 * ${gridGutter} - ${gridMargin} * 2) / 16`;

/* Tablet */
export const gridMarginT = '16px';
export const gridGutterT = '16px';
export const colT = `(100vw - (${navWidth} * 2) - (11 * ${gridGutterT}) - ${gridMarginT} * 2) / 12`;

/* Mobile */
export const gridMarginM = '24px';
export const colM = `(100vw - (5 * ${gridGutter}) - ${gridMarginM} * 2) / 6`;

export const grid = {
  navWidth: navWidth,
  gridMargin: gridMargin,
  gridGutter: gridGutter,
  col: col,
  col1: `(${col} + ${gridGutter})`,
  col2: `(${col} * 2 + ${gridGutter})`,
  col3: `(${col} * 3 + ${gridGutter} * 2)`,
  col4: `(${col} * 4 + ${gridGutter} * 3)`,
  col5: `(${col} * 5 + ${gridGutter} * 4)`,
  col6: `(${col} * 6 + ${gridGutter} * 5)`,
  col7: `(${col} * 7 + ${gridGutter} * 6)`,
  col8: `(${col} * 8 + ${gridGutter} * 7)`,
  col9: `(${col} * 9 + ${gridGutter} * 8)`,
  col10: `(${col} * 10 + ${gridGutter} * 9)`,
  col11: `(${col} * 11 + ${gridGutter} * 10)`,
  col12: `(${col} * 12 + ${gridGutter} * 11)`,
  col13: `(${col} * 13 + ${gridGutter} * 12)`,
  col14: `(${col} * 14 + ${gridGutter} * 13)`,
  col15: `(${col} * 15 + ${gridGutter} * 14)`,
  col16: `(${col} * 16 + ${gridGutter} * 15)`,
};

export const gridTablet = {
  navWidth: navWidth,
  gridMargin: gridMarginT,
  gridGutter: gridGutterT,
  col: col,
  col1: `(${colT} + ${gridGutterT})`,
  col2: `(${colT} * 2 + ${gridGutterT})`,
  col3: `(${colT} * 3 + ${gridGutterT} * 2)`,
  col4: `(${colT} * 4 + ${gridGutterT} * 3)`,
  col5: `(${colT} * 5 + ${gridGutterT} * 4)`,
  col6: `(${colT} * 6 + ${gridGutterT} * 5)`,
  col7: `(${colT} * 7 + ${gridGutterT} * 6)`,
  col8: `(${colT} * 8 + ${gridGutterT} * 7)`,
  col9: `(${colT} * 9 + ${gridGutterT} * 8)`,
  col10: `(${colT} * 10 + ${gridGutterT} * 9)`,
  col11: `(${colT} * 11 + ${gridGutterT} * 10)`,
  col12: `(${colT} * 12 + ${gridGutterT} * 11)`,
};

export const gridMobile = {
  navWidth: navWidth,
  gridMargin: gridMarginM,
  gridGutter: gridGutter,
  col: colM,
  col1: `(${colM} + ${gridGutter})`,
  col2: `(${colM} * 2 + ${gridGutter})`,
  col3: `(${colM} * 3 + ${gridGutter} * 2)`,
  col4: `(${colM} * 4 + ${gridGutter} * 3)`,
  col5: `(${colM} * 5 + ${gridGutter} * 4)`,
  col6: `(${colM} * 6 + ${gridGutter} * 5)`,
};

/* Colors */
export const colors = {
  grey: '#d6d6d6',
  black: '#000',
  blue: '#00a0cc',
};