import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ServiceContent, ServiceSection } from './Service.styles';
import { SectionHeading, MainText, SecondaryText } from '../Layout.styles';
import Gallery, { GalleryImage } from '../Gallery/Gallery';
import { isMobile, isSafari } from 'react-device-detect';

interface Props {
  images: GalleryImage[];
  name: string;
  slug: string;
  text: string;
}

const Service = ({ images, name, slug, text }: Props) => {
  const { t } = useTranslation('services');
  return (
    <ServiceSection id={slug} style={isSafari && !isMobile ? {maxHeight: '100vh', overflowY: "auto"} : {}}>
      <ServiceContent>
        <SectionHeading>
          <strong>{t('Services')}</strong> — <span>{t('whatWeDo')}</span>
        </SectionHeading>
        <MainText>{name}</MainText>
        <SecondaryText>
        <Trans components={{ bold: <strong />, a:<a />}}>
          {text}
          </Trans>
          {!isMobile ? '\n\n\n\n\n\n\n' : ''} {/*hack to better the scroll on long service sections*/}
          </SecondaryText>
      </ServiceContent>
      <Gallery images={images} />
    </ServiceSection>
  );
};

export default Service;
