import React, { useRef, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { WrapperText } from './About.styles';
import { BG, SectionAward } from './Award.styles';
import { ThemeContext } from '../layout';
import { useMobile, useResize, useScrollPosition } from '../../hooks';
import { colors } from '../Variables';

const Award = () => {
  const bg = useRef<HTMLDivElement>(null);
  const H = useResize()[1];
  const heading = useRef<HTMLHeadingElement>(null);
  const isMobile = useMobile();
  const section = useRef<HTMLElement>(null);
  const setIsBlue = useContext(ThemeContext)[1];
  const scrollPosition = useScrollPosition();
  const { t } = useTranslation('about');

  /** Set parallax for heading, opacity for background and blue theme */
  useEffect(() => {
    if (!heading.current || !section.current) return;

    const top = section.current ? section.current.getBoundingClientRect().top : 0;
    let v = section.current ? (scrollPosition - (scrollPosition + top - H)) / H : 0;
    const parallax = isMobile ? Math.min(0, v * 40 - 40) : Math.min(0, v * 100 - 100);

    /** Set parallax */
    heading.current.style.transform = `translateY(${parallax}px)`;

    /** Set blue theme */
    const mTrigger = top <= 108 && top >= -section.current.offsetHeight / 2; // MOBILE TRIGGER
    const dTrigger = top <= H / 2 && top >= -H / 2; // DESKTOP TRIGGER
    const isBlueTheme = isMobile ? mTrigger : dTrigger;

    setIsBlue(isBlueTheme);

    if (bg.current) {
      if (!isMobile) {
        /** Set opacity */
        bg.current.style.opacity = `${1 - Math.abs(v - 1)}`;
        bg.current.style.backgroundColor = colors.blue;
      } else {
        /** Set background color */
        bg.current.style.backgroundColor = isBlueTheme ? colors.blue : `transparent`;
        bg.current.style.opacity = `1`;
      }
    }
  }, [H, isMobile, scrollPosition, setIsBlue]);

  return (
    <SectionAward id="awards" ref={section}>
      <h2 ref={heading}>{t('Best Dental Clinic 2018')}</h2>
      <WrapperText>
        <p>{t('awardsText1')}</p>
        <p>{t('awardsText2')}</p>
      </WrapperText>
      <BG ref={bg} />
    </SectionAward>
  );
};

export default Award;
