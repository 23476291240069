import { useStaticQuery, graphql } from 'gatsby';

export const useServicesImages = () => {
  const {
    emergencies,
    implantology,
    periodontology,
    oralSurgery,
    pediatricDentistry,
    orthodontics,
    endodontics,
    oralHygiene,
    occlusionOrofacialPainAndTMJD,
    imagiology,
    oralRehabilitationAndAestheticDentistry,
    consciousSedation,
    facialHarmonization,
    laboratoryRoom,
    physiotherapy,
    interventionalRadiology,
    generalSurgery,
  } = useStaticQuery(graphql`
    query ServicesImages {
      emergencies: file(relativePath: { eq: "services/emergencies-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      implantology: file(relativePath: { eq: "services/algarve-dental-implants.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      periodontology: file(relativePath: { eq: "services/periodontology-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oralSurgery: file(relativePath: { eq: "services/oral-surgeon.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pediatricDentistry: file(relativePath: { eq: "services/pediatric-dentistry-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      orthodontics: file(relativePath: { eq: "services/orthodontics-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      endodontics: file(relativePath: { eq: "services/endodontics-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oralHygiene: file(relativePath: { eq: "services/oral-hygiene-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      occlusionOrofacialPainAndTMJD: file(
        relativePath: { eq: "services/occlusion-orofacial-pain-and-tmjd-01.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagiology: file(relativePath: { eq: "services/imagiology-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oralRehabilitationAndAestheticDentistry: file(
        relativePath: { eq: "services/oral-rehabilitation-and-aesthetic-dentistry-01.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      consciousSedation: file(relativePath: { eq: "services/conscious-sedation-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      facialHarmonization: file(relativePath: { eq: "services/facial-harmonization-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laboratoryRoom: file(relativePath: { eq: "services/laboratory-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      physiotherapy: file(relativePath: { eq: "services/physiotherapy-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      interventionalRadiology: file(relativePath: { eq: "services/interventional-radiology-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      generalSurgery: file(relativePath: { eq: "services/general-surgery-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return [
    [{ alt: 'Emergencies', src: emergencies.childImageSharp.fluid }],
    [{ alt: 'Implantology', src: implantology.childImageSharp.fluid }],
    [{ alt: 'Periodontology', src: periodontology.childImageSharp.fluid }],
    [{ alt: 'Oral Surgery', src: oralSurgery.childImageSharp.fluid }],
    [{ alt: 'Pediatric Dentistry', src: pediatricDentistry.childImageSharp.fluid }],
    [{ alt: 'Orthodontics', src: orthodontics.childImageSharp.fluid }],
    [{ alt: 'Endodontics', src: endodontics.childImageSharp.fluid }],
    [{ alt: 'Oral Hygiene', src: oralHygiene.childImageSharp.fluid }],
    [
      {
        alt: 'Oral Rehabilitation And Aesthetic Dentistry',
        src: oralRehabilitationAndAestheticDentistry.childImageSharp.fluid,
      },
    ],
    [
      {
        alt: 'Occlusion Orofacial Pain And TMJD',
        src: occlusionOrofacialPainAndTMJD.childImageSharp.fluid,
      },
    ],
    [{ alt: 'Conscious Sedation', src: consciousSedation.childImageSharp.fluid }],
    [{ alt: 'Facial Harmonization', src: facialHarmonization.childImageSharp.fluid }],
    [{ alt: 'Imagiology', src: imagiology.childImageSharp.fluid }],
    [{ alt: 'Laboratory Room', src: laboratoryRoom.childImageSharp.fluid }],
    [{ alt: 'Physiotherapy', src: physiotherapy.childImageSharp.fluid }],
    [{ alt: 'Interventional Radiology', src: interventionalRadiology.childImageSharp.fluid }],
    [{ alt: 'General Surgery', src: generalSurgery.childImageSharp.fluid }],
  ];
};
