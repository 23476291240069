import React from 'react';
import styled from 'styled-components';

export const Tooltip = styled(({ scrollbarWidth, ...rest }) => <p {...rest} />)`
  position: fixed;
  right: 67px;
  bottom: 8px;
  margin-right: ${(props) => props.scrollbarWidth || 0}px;
  max-width: 217px;
  padding: 16px 26px 16px 16px;
  font-family: RobotoSlab-Regular, sans-serif;
  font-size: 14px;
  line-height: 18px;
  background-color: #fff;
  box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.05);
  opacity: 0;
  visibility: hidden;
  transition: visibility 300ms linear, opacity 300ms ease;
  pointer-events: none;
  z-index: 10;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -5px;
    bottom: 0;
    width: 10px;
    height: 10px;
    margin: auto 0;
    border-radius: 2px;
    background: #fff;
    transform: rotate(-45deg);
  }
`;

export const WhatsappIcon = styled(({ scrollbarWidth, ...rest }) => <div {...rest} />)`
  position: fixed;
  right: 8px;
  bottom: 10px;
  width: 54px;
  height: 54px;
  margin-right: ${(props) => props.scrollbarWidth || 0}px;
  cursor: pointer;
  z-index: 10;

  &:hover + ${Tooltip} {
    opacity: 1;
    visibility: visible;
`;
