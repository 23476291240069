import { useStaticQuery, graphql } from 'gatsby';

export const useRoomsImages = () => {
  const {
    operationRoom1,
    operationRoom2,
    operationRoom3,
    trainingRoom1,
    imagingRoom1,
    laboratoryRoom1,
  } = useStaticQuery(graphql`
    query RoomsImages {
      operationRoom1: file(
        relativePath: { eq: "about/rooms/operation-room/operation-room-01.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      operationRoom2: file(
        relativePath: { eq: "about/rooms/operation-room/operation-room-02.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      operationRoom3: file(
        relativePath: { eq: "about/rooms/operation-room/operation-room-03.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      trainingRoom1: file(relativePath: { eq: "about/rooms/training-room/training-room-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagingRoom1: file(relativePath: { eq: "about/rooms/imaging-room/imaging-room-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      laboratoryRoom1: file(
        relativePath: { eq: "about/rooms/laboratory-room/laboratory-room-01.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return [
    [
      { alt: 'Operation Room', src: operationRoom1.childImageSharp.fluid },
      { alt: 'Operation Room', src: operationRoom2.childImageSharp.fluid },
      { alt: 'Operation Room', src: operationRoom3.childImageSharp.fluid },
    ],
    [{ alt: 'Training Room', src: trainingRoom1.childImageSharp.fluid }],
    [{ alt: 'Imaging Room', src: imagingRoom1.childImageSharp.fluid }],
    [{ alt: 'Laboratory', src: laboratoryRoom1.childImageSharp.fluid }],
  ];
};
