import React, { useContext, useState, useEffect } from 'react';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import { navigate } from 'gatsby';
import moment from 'moment';
import { RouteComponentProps } from '@reach/router';
import { Date } from './Media.styles';
import { ArticleSection, Text, Title } from './MediaArticle.styles';
import MediaArticleGallery from './MediaArticleGallery';
import { LocaleContext } from '../layout';
import Modal from '../Modal/Modal';
import SEO from '../seo';
import { graphql, useStaticQuery } from 'gatsby';

const Client = Prismic.client('https://albufeira.prismic.io/api/v2');
const langs: any = { de: 'de-de', en: 'en-gb', fr: 'fr-fr', pt: 'pt-pt' };

const MediaArticle: React.FC<RouteComponentProps> = ({ location }) => {
  //const [media, setMedia] = useState<any>('');
  const [locale] = useContext(LocaleContext);

  // useEffect(() => {
  //   const l = (location && location.pathname.toString().replace(`${locale}/media/`, '')) || '';
     const clang = locale === '' ? 'en' : locale.replace('/', '');
     const lang = langs[clang] ? langs[clang] : null;

  //   const fetchData = async () => {
  //     const response = await Client.query(
  //       Prismic.Predicates.at('my.media.uid', l.replace(/\/$/g, '')),
  //       { lang }
  //     );
  //     if (response) setMedia(response.results);
  //   };
  //   fetchData();
  // }, [location, locale]);


  const uid = location?.pathname.toString().replace(`${locale}/news/`, '').replace('/', '');

  //console.log(uid);

  const handleClose = () => navigate(`${locale}/news/`);

  //if (!media && !media[0]) return null;

 
  const teste = useStaticQuery(graphql`
  {
   allPrismicMedia(sort: {fields: data___date, order: DESC}) {
     edges {
        node {
          alternate_languages {
            id
            uid
            type
            lang
            slug
          }
          data {
            date
            images {
              image {
                alt
                copyright
                url
              }
            }
            text {
              raw
            }
            title {
              raw
            }
          }
          first_publication_date
          href
          id
          lang
          last_publication_date
          tags
          type
          uid
        }
      }
    }
  }
  `);

const articleQuery = [];

  for (let node of teste.allPrismicMedia.edges){
    if (node.node.lang == lang && node.node.uid == uid) {
      //console.log (node);
      articleQuery.push(node);
    }
  }
//console.log (articleQuery[0].node.data.text);
//console.log (typeof(articleQuery[0].node.data.text));
//const description = (articleQuery[0].node.data.text.raw).substring(0,300);

const currentPath = location?.pathname ? location?.pathname : '';


  return (
    <>
      <SEO
        title={articleQuery[0].node.data.title.raw[0].text || 'Media article'}
        description={articleQuery[0].node.data.text.raw[0].text.substring(0,300)}
        path={currentPath}
      />
      <Modal handleClose={handleClose}>
        <ArticleSection>
          {/* GALLERY */}
          {articleQuery[0].node.data.images.length > 0 && <MediaArticleGallery images={articleQuery[0].node.data.images} />}
          {/* DATE */}
          {articleQuery[0].node.data.date && <Date>{moment(`${articleQuery[0].node.data.date}`).format('LL')}</Date>}
          {/* TITLE */}
          {articleQuery[0].node.data.title.raw && <Title>{RichText.asText(articleQuery[0].node.data.title.raw)}</Title>}
          {/* TEXT */}
          {articleQuery[0].node.data.text.raw && <Text>{RichText.render(articleQuery[0].node.data.text.raw)}</Text>}
        </ArticleSection>
      </Modal>
    </>
  );
};

export default MediaArticle;
