import styled from 'styled-components';
import { Section } from '../Layout.styles';
import { colors, gridTablet, gridMobile, grid } from '../Variables';

export const MediaSection = styled(Section)`
  scroll-snap-align: unset;
  scroll-snap-stop: unset;
`;

export const MediaList = styled.div`
  position: relative;
  width: calc(calc(${grid.col16}));
  margin-top: 50px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: calc(-1 * (${grid.navWidth} + ${grid.gridMargin}));
    width: calc(100% + ${grid.gridMargin} * 2 + ${grid.navWidth});
    height: 1px;
    background-color: #e1e1e1;
    z-index: 2;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      left: calc(-1 * ${gridMobile.gridMargin});
      width: calc(100% + ${gridMobile.gridMargin} * 2);
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      left: calc(-1 * (${grid.navWidth} + ${gridTablet.gridMargin}));
      width: calc(100% + ${gridTablet.gridMargin} + ${grid.navWidth});
    }
  }

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: calc(${gridMobile.col6});
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col12});
  }
`;

export const MediaListItem = styled.div`
  position: relative;

  &:hover::before {
    background-color: #fafafa;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      background-color: none;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      background-color: none;
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(-1 * (${grid.navWidth} + ${grid.gridMargin}));
    width: calc(100% +${grid.navWidth} + ${grid.gridMargin} * 2);
    height: 100%;
    border-bottom: 1px solid #e1e1e1;
    transition: background 300ms ease;
    z-index: 0;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      left: calc(-1 * ${gridMobile.gridMargin});
      width: calc(100% + ${gridMobile.gridMargin} * 2);
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      left: calc(-1 * (${grid.navWidth} + ${gridTablet.gridMargin}));
      width: calc(100% + ${grid.navWidth} + ${gridTablet.gridMargin});
    }
  }

  p {
    transition: color 300ms ease;
    color: inherit;
  }

  & > a {
    position: relative;
    display: block;
    padding: 36px 0;
    margin-bottom: 1px;
    color: ${colors.black};
    text-decoration: none;
    z-index: 1;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      padding: 30px 0;
    }

    @media (min-width: 1280px) {
      padding: 40px 0;
    }

    @media (min-width: 1400) {
      padding: 50px 0;
    }

    @media (min-width: 1920px) {
      padding: 70px 0;
    }
  }

  &:hover a {
    color: ${colors.blue};

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      color: ${colors.black};
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      color: ${colors.black};
    }
  }
`;

export const Date = styled.p`
  display: block;
  font-family: RobotoSlab-Regular, sans-serif;
  font-size: 10px;
  line-height: 13px;

  @media (min-width: 1920px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const MediaTextWrapper = styled.div`
  width: calc(${grid.col9});
  margin-left: calc(${grid.col3} + ${grid.gridGutter});

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: calc(${gridMobile.col6});
    margin-left: 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col9});
    margin-left: calc(${gridTablet.col3} + ${gridTablet.gridGutter});
  }

  ${Date} {
    margin-bottom: 10px;

    @media (min-width: 1920px) {
      margin-bottom: 20px;
    }
  }
`;

export const MediaImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 1px;
  left: 0;
  width: calc(${grid.col2} + ${grid.gridGutter});
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col2} + ${gridTablet.gridGutter});
  }
`;
