import React from 'react';
import styled from 'styled-components';
import { colors, gridTablet, gridMobile, grid } from '../Variables';

export const Gallery = styled(({ forwardRef, ...rest }) => <div ref={forwardRef} {...rest} />)`
  position: relative;
  display: flex;
  width: 100%;
  height: calc(${grid.col10} * 0.7064814814814815);
  margin-bottom: 36px;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    left: calc(-1 * ${gridMobile.gridMargin});
    width: calc(${gridMobile.col6} + ${gridMobile.gridMargin});
    height: calc(${gridMobile.col6} * 0.6299694189602446);
    margin-bottom: 8px;
    scroll-snap-type: x mandatory;
    scroll-padding-left: ${gridMobile.gridMargin};
    overflow-x: scroll;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    height: calc(${gridTablet.col11} * 0.691743119266055);
  }

  @media (min-width: 1280px) {
    margin-bottom: 50px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 70px;
  }
`;

export const GalleryImage = styled(({ active, alt, changeHeight, ...rest }) => (
  <img alt={alt} {...rest} />
))`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  flex: 0 0 auto;
  width: 100%;
  height: calc(
    100% - ${(props) => (props.changeHeight ? `${grid.col1} * 0.6666666666666666` : '0px')}
  );
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: opacity ${(props) => (props.active ? 400 : 600)}ms ease;
  object-fit: cover;
  user-select: none;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    position: relative;
    flex: 1 0 calc(${gridMobile.col6});
    overflow: hidden;
    width: calc(${gridMobile.col6});
    margin-left: ${gridMobile.gridMargin};
    scroll-snap-align: start;
    scroll-snap-stop: always;
    opacity: 1;
  }
`;

export const Indicators = styled.div`
  display: none;
  justify-content: center;
  width: calc(${gridMobile.col6});
  height: 2px;
  margin-bottom: 30px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    display: flex;
  }
`;

export const Indicator = styled(({ active, ...rest }) => <i {...rest} />)`
  flex: 0 0 auto;
  display: block;
  width: 18px;
  height: 100%;
  background-color: ${(props) => (props.active ? colors.blue : 'grey')};
  transition: background 300ms ease;

  & + i {
    margin-left: 10px;
  }
`;

export const GalleryThumbs = styled.div`
  display: flex;
  flex: 0 0 auto;
  margin-top: auto;
`;

export const GalleryThumb = styled(({ active, ...rest }) => <div {...rest} />)`
  flex: 0 0 auto;
  width: calc(${grid.col1} - ${grid.gridGutter});
  height: calc((${grid.col1} - ${grid.gridGutter}) * 0.6666666666666666);
  cursor: pointer;
  opacity: ${(props) => (props.active ? 1 : 0.6)};
  transition: opacity 300ms ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${GalleryThumbs} > & + & {
    margin-left: ${grid.gridGutter};
  }
`;
