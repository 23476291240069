import React, { useState } from 'react';
import { useMobile, useTablet } from '../../hooks';

const colors = { hovered: '#00a0cc', default: '#d6d6d6' };

const Twitter = () => {
  const isMobile = useMobile();
  const isTouch = useTablet();
  const [color, setColor] = useState(colors.default);

  const handleMouseEnter = () => {
    if (isMobile || isTouch) return;

    setColor(colors.hovered);
  };

  const handleMouseLeave = () => {
    if (isMobile || isTouch) return;

    setColor(colors.default);
  };

  return (
    <a
      href="https://twitter.com/albufeirahealth"
      rel="noopener noreferrer"
      target="_blank"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <svg
        aria-label="twitter"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10Z"
          fill={color}
        />
        <path
          d="M12.3936 4.79811C10.9748 4.83406 9.83661 5.99536 9.83661 7.42311C9.83661 7.62864 9.85866 7.82957 9.90361 8.02211C7.72098 7.91245 5.78589 6.86701 4.49061 5.27811C4.26455 5.66578 4.13461 6.11737 4.13461 6.5991C4.13461 7.51009 4.59893 8.31328 5.30361 8.7841C4.87332 8.77033 4.46777 8.6518 4.11361 8.45511C4.11341 8.46602 4.11361 8.47793 4.11361 8.4891C4.11361 9.76127 5.01913 10.8215 6.22061 11.0631C6.00027 11.1229 5.76818 11.1551 5.52861 11.1551C5.3592 11.1551 5.19493 11.139 5.03461 11.1081C5.36902 12.1514 6.33761 12.9111 7.48661 12.9321C6.58783 13.6365 5.4572 14.0561 4.22661 14.0561C4.01484 14.0561 3.80514 14.0445 3.59961 14.0201C4.76212 14.7656 6.14172 15.2001 7.62461 15.2001C12.4552 15.2001 15.0966 11.1985 15.0966 7.72811C15.0966 7.6143 15.0945 7.49987 15.0896 7.3871C15.6025 7.01762 16.0478 6.5548 16.3996 6.02811C15.929 6.23727 15.423 6.37896 14.8916 6.4421C15.4341 6.11731 15.8504 5.60284 16.0466 4.9891C15.5391 5.29026 14.977 5.50914 14.3786 5.6271C13.8997 5.11679 13.217 4.79811 12.4616 4.79811C12.439 4.79811 12.4161 4.79753 12.3936 4.79811V4.79811Z"
          fill="white"
        />
      </svg>
    </a>
  );
};

export default Twitter;
