import React from 'react';
import styled from 'styled-components';
import { Section } from '../Layout.styles';
import { gridTablet, gridMobile, grid } from '../Variables';
//colors

export const EmployeeAdditional = styled.div`
  display: flex;
  margin-bottom: 50px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    flex-direction: column;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    margin-bottom: 30px;
  }

  p {
    flex: 0 0 auto;
    font-family: RobotoSlab-Light, sans-serif;
    font-size: 14px;
    line-height: 25px;
    color: #000;

    @media (min-width: 768px) and (max-width: 1279px) {
      font-size: 10px;
      line-height: 13px;
    }

    @media (min-width: 1280px) and (max-width: 1439px) {
      font-size: 12px;
      line-height: 16px;
    }

    @media (min-width: 1920px) {
      font-size: 20px;
      line-height: 35px;
    }
  }

  p:nth-child(1) {
    width: calc(${grid.col2});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: calc(${gridMobile.col6});
      margin-bottom: 12px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: calc(${gridTablet.col2});
    }
  }

  p:nth-child(2) {
    margin-left: ${grid.gridGutter};
    white-space: break-spaces;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: calc(${gridMobile.col6});
      margin-left: 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-left: ${gridTablet.gridGutter};
    }

    b {
      font-family: RobotoSlab-Medium, sans-serif;
      margin-right: 20px;
    }
  }
`;

export const EmployeeBio = styled.div`
  width: calc(${grid.col6});
  margin-left: calc(${grid.col1});
  padding-bottom: 50px;
  font-family: RobotoSlab-Light, sans-serif;
  font-size: 20px;
  line-height: 31px;
  color: #000;
  white-space: pre-wrap;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: calc(${gridMobile.col6});
    margin-left: 0;
    padding-bottom: 30px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col10});
    margin-left: 0;
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    font-size: 14px;
    line-height: 21px;
  }

  @media (min-width: 1280px) and (max-width: 1339px) {
    font-size: 18px;
    line-height: 28px;
  }

  @media (min-width: 1920px) {
    font-size: 28px;
    line-height: 44px;
  }

  strong {
    font-family: RobotoSlab-bold, sans-serif;
  }

  li {
    margin: 30px;
  }
`;

export const EmployeePageContent = styled.div`
  width: calc(${grid.col8});
  flex: 0 0 auto;
  padding-top: 100px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: calc(${gridMobile.col6});
    padding-top: 25px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col12});
    padding-top: 60px;
  }

  h5 {
    display: inline-block;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      display: none;
    }

    & + h5 {
      float: right;
      margin-right: calc(${grid.col1});

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        margin-right: calc(${gridTablet.col2} + ${gridTablet.gridGutter} * 2);
      }
    }
  }
`;

export const EmployeeName = styled.h1`
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: RobotoSlab-Regular, sans-serif;
  font-size: 5vw;
  line-height: 8vh;
  color: #000;
  white-space: break-spaces;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    margin-top: 0;
    font-size: 45px;
    line-height: 57px;
  }

  @media (min-width: 768px) and (max-width: 1279px) {
    font-size: 56px;
    line-height: 65px;
  }

  @media (min-width: 1440px) {
    font-size: 5vw;
    line-height: 9vh;
  }
`;

export const EmployeePagePhoto = styled(({ scrollbarWidth, ...rest }) => <div {...rest} />)`
  position: sticky;
  top: 0;
  width: calc(
    ${grid.col8} + ${grid.gridMargin} -
      ${(props) => (props.scrollbarWidth ? props.scrollbarWidth : 0)}px
  );
  flex: 0 0 auto;
  height: 100vh;
  margin-left: ${grid.gridGutter};

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    position: relative;
    left: calc(-1 * ${gridMobile.gridMargin} * 2);
    width: calc(100% + ${gridMobile.gridMargin} * 2);
    height: calc((${gridMobile.col6} + ${gridMobile.gridMargin} * 2) * 1.2426666666666666);
    order: -1;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    position: relative;
    left: calc(-1 * ${grid.navWidth} - ${gridTablet.gridMargin});
    width: calc(${gridTablet.col12} + ${grid.navWidth} + ${gridTablet.gridMargin});
    height: calc(${gridTablet.col12} + ${grid.navWidth} + ${gridTablet.gridMargin});
    order: -1;
    margin-left: 0;
  }
`;

export const StyledEmployeePage = styled(Section)`
  display: flex;
  min-height: 100vh;
  background-color: #fff;
  padding: 0 ${grid.navWidth} 0 calc(${grid.navWidth} + ${grid.gridMargin});
  z-index: 10;
  scroll-snap-align: unset;
  scroll-snap-stop: unset;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    flex-direction: column;
    padding: 0 ${gridMobile.gridMargin} 60px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    padding: 0 ${gridTablet.gridMargin} 0 calc(${grid.navWidth} + ${gridTablet.gridMargin});
  }
`;
