import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IMaskInput } from 'react-imask';
import moment from 'moment';
import { FormField, Placeholder, ErrorMessage } from './ContactsForm.styles';

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  type: string;
  value: string;
  setFieldDateIsValid?: (isValid: boolean) => void;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  onChangeDate?: (event: string) => void;
  onFocus?: (pref: string) => void;
}

const dateFormat = 'DD/MM/YYYY';

const ContactsFormFiled = ({
  label,
  name,
  placeholder,
  type,
  value,
  setFieldDateIsValid,
  onChange,
  onChangeDate,
  onFocus,
}: Props) => {
  const [dateIsLess, setDateIsLess] = useState(false);
  const [isDayOff, setIsDayOff] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [hasError, setError] = useState(false);
  const pattern = type === 'email' ? '[A-Za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$' : undefined;
  const { t } = useTranslation('contacts');

  const handleFocus = () => {
    setFocused(true);

    if (type === 'phone') {
      onFocus && onFocus(!value ? '+' + value : value);
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    onChange && onChange(event);

    if (type === 'email' && !pristine) {
      const error = !(event.target as HTMLInputElement).checkValidity();
      setError(error);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    setPristine(false);

    if (type === 'date' && setFieldDateIsValid) {
      const isValid = moment(value, dateFormat, true).isValid() === true;
      setFieldDateIsValid(!dateIsLess && !isDayOff && isValid);
      setError(dateIsLess || isDayOff || !isValid);
    }

    if (type === 'email') {
      const error = !event.target.checkValidity();
      setError(error);
    }

    if (type === 'phone') {
      onFocus && onFocus(value === '+' ? '' : value);
    }
  };

  useEffect(() => {
    if (type !== 'date') return;

    const date = moment(value, dateFormat);

    setIsDayOff(date.day() === 0);
    setDateIsLess(date.isBefore(moment(), 'day'));
  }, [setDateIsLess, type, value]);

  return (
    <FormField error={hasError} focused={isFocused} type={type}>
      <Placeholder onTop={isFocused || value}>{label}</Placeholder>

      {type === 'date' ? (
        <IMaskInput
          aria-invalid={moment(value, dateFormat, true).isValid() === false}
          format={(date: string) => moment(date).format(dateFormat)}
          mask={Date}
          name={name}
          overwrite={true}
          parse={(str: string) => moment(str, dateFormat)}
          pattern="d{/}`m{/}`Y"
          placeholder={placeholder}
          required={true}
          value={value}
          onAccept={onChangeDate}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      ) : (
        <input
          aria-invalid={hasError}
          aria-label={label}
          aria-required={true}
          name={name}
          type={type}
          pattern={pattern}
          placeholder={placeholder}
          required={true}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
      {(type === 'email' || type === 'date') && (
        <ErrorMessage visible={hasError}>
          {dateIsLess
            ? t('Invalid date')
            : isDayOff
            ? t('not working day')
            : type === 'email'
            ? t('Email incorrect')
            : ''}
        </ErrorMessage>
      )}
    </FormField>
  );
};

export default ContactsFormFiled;
