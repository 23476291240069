import styled from 'styled-components';
import { colors, gridTablet, grid, gridMobile } from '../Variables';
import { isBrowser } from 'react-device-detect'

export const ContactsData = styled.div`
  width: calc(${grid.col4} + ${grid.gridGutter} * 2 + ${grid.gridMargin} + 50px);
  padding-top: 110px};
  padding-left: 50px;
  background-color: #fafafa;
  border-left: 1px solid ${colors.grey};

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: 100%;
    margin-bottom: ${isBrowser? "80px" : "0px"};
    padding-top: 50px;
    padding-left: 0;
    border-left: 0;
    background-color: unset;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col6} + ${gridTablet.gridGutter});
    padding-top: 141px;
    padding-left: calc(${gridTablet.gridGutter} + ${gridTablet.gridMargin});
  }

  @media (min-width: 1400px) {
    padding-top: 160px;
  }

  @media (min-width: 1920px) {
    padding-top: 230px;
  }

  p,
  a {
    font-family: RobotoSlab-Light, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.black};

    @media (min-width: 1400px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (min-width: 1920px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: color 300ms ease;

    &:hover {
      color: ${colors.blue};

      span {
        border-bottom: 1px solid transparent;
      }

      @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
        color: ${colors.black};

        span {
          border-bottom: 1px solid ${colors.blue};
        }
      }

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        color: ${colors.black};

        span {
          border-bottom: 1px solid ${colors.blue};
        }
      }
    }

    span {
      display: inline;
      transition: border 300ms ease;
      border-bottom: 1px solid ${colors.blue};
    }
  }

  h5 {
    font-size: 12px;
    line-height: 16px;
    width: calc(${grid.col3});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: calc(${gridTablet.col3});
    }

    @media (min-width: 1400px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (min-width: 1920px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  h5 + p {
    margin-top: 20px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 50px;
    }

    @media (min-width: 1400px) {
      margin-top: 50px;
    }

    @media (min-width: 1920px) {
      margin-top: 40px;
    }
  }

  p + p {
    margin-top: 30px;

    @media (min-width: 1920px) {
      margin-top: 40px;
    }
  }

  p + a {
    margin-top: 20px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 10px;
    }

    @media (min-width: 1400px) {
      margin-top: 10px;
    }

    @media (min-width: 1920px) {
      margin-top: 40px;
    }
  }
`;

export const TopContactsHeading = styled.h1`
  font-family: RobotoSlab-Medium, sans-serif;
  font-size: 10px;
  line-height: 17px;
  white-space: pre-wrap;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    font-size: 14px;
    line-height: 25px;
  }

  @media (min-width: 1400px) {
    font-size: 14px;
    line-height: 25px;
  }

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const ContactsHeading = styled.h5`
  font-family: RobotoSlab-Medium, sans-serif;
  font-size: 10px;
  line-height: 17px;
  white-space: pre-wrap;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    font-size: 14px;
    line-height: 25px;
  }

  @media (min-width: 1400px) {
    font-size: 14px;
    line-height: 25px;
  }

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const Section = styled.section`
  display: flex;
  min-height: 100vh;
  padding-left: calc(${grid.navWidth} + ${grid.gridMargin});

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    flex-direction: column;
    padding-right: ${gridMobile.gridMargin};
    padding-left: ${gridMobile.gridMargin};
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    flex-wrap: wrap;
    padding-left: calc(${grid.navWidth} + ${gridTablet.gridMargin});
  }
`;
