import React, { useContext, useEffect, useState, useRef } from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { useScrollPosition } from '../../hooks';
import { LocaleContext } from '../layout';
import { locales } from '../i18n';
import { PreferredLang, PreferredLangBtn } from './PreferredLanguage.styles';


const languages: { [locale: string]: string } = {
  en: 'English',
  pt: 'Português',
  de: 'Deutsch',
  fr: 'Français',
};

const PreferredLanguage = () => {
  const [active, setActive] = useState(true);
  const scrollPosition = useScrollPosition();
  const location = useLocation();
  const { i18n } = useTranslation();
  const [locale, [localeChange, changeLocale]] = useContext(LocaleContext);
  const navigatorLookup = i18n.services.languageDetector.detectors.navigator.lookup;
  const preferred = useRef<string>();
  const isIE11 =
  typeof window !== 'undefined' &&
  !!window.MSInputMethodContext &&
  !!(document as any).documentMode;
  const { t } = useTranslation('home');

  useEffect(() => {
    const langs = navigatorLookup(location.pathname);
    if (Array.isArray(langs)) {

      //xfze: fix for safari and IE (only show full 4 letter code lang)
      for (let i = 0; i < langs.length; i++) {
        if (langs[i].length > 2) {
          langs[i] = langs[i].substring(0,2);
        }
      }

      for (let i = 0; i < langs.length; i++) {
        if (locales.includes(langs[i])) {
          preferred.current = langs[i];
          break;
        }
      }
    }
  }, [navigatorLookup, location.pathname]);

  useEffect(() => {
    if (scrollPosition > 72 * 2) {
      setActive(false);
    }
  }, [scrollPosition]);

  useEffect(() => void (localeChange && setActive(false)), [localeChange]);

  const handleLanguageClick = () => {
    if (typeof preferred.current !== 'undefined') {
      i18n.changeLanguage(preferred.current, () => {
        const newLocale = i18n.language === `en` ? `` : `/${i18n.language}`;
        if (location.pathname.indexOf(locale) === 0) {
          navigate(newLocale + location.pathname.substring(locale.length));
        }
        changeLocale({});
      });
    }
  };

  const handleCloseClick = () => {
    setActive(false);
  };

  const i18nQuestion = () => { //not localized because we want target language instead of current site locale

    switch(preferred.current) { 
      case "de": { 
        return "Bevorzugen Sie die Version in ";
        break; 
      } 
      case "fr": { 
        return "Préférez vous accéder au site en ";
        break; 
      }
      case "pt": { 
        return "Prefere aceder ao site em ";
        break; 
      }
   } 
    return "Is your preferred language ";
  };

  return typeof preferred.current === 'undefined' || !active ? null : (
    
    <PreferredLang visible={isIE11 ? true : preferred.current !== i18n.language}>
      <p>
        {isIE11 ? t('home:getChrome') : i18nQuestion()}
        {isIE11 ? <a href="https://www.google.com/chrome/" target="_blank">Google Chrome</a> : <button onClick={handleLanguageClick}>{languages[preferred.current]}</button>}
        {isIE11 ? '' : ' ?'}
      </p>
      <PreferredLangBtn aria-label="closeLanguageCheck" onClick={handleCloseClick} />
    </PreferredLang>
  );
};

export default PreferredLanguage;
