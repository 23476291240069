import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { EmployeeImage, useTeamImages } from './Team.images';
import {
  Departament,
  Employee,
  EmployeeName,
  EmployeePhoto,
  EmployeePosition,
  SectionTeam,
  TeamGrid,
} from './Team.styles';
import { TopSectionHeading } from '../Layout.styles';

export interface Employee {
  bio: string;
  department: string;
  languages: string[];
  position: string;
}

export interface PagesEmployee {
  name: string;
  slug: string;
  employee: { department: string; position: string };
}

const useTeamList = (): Array<PagesEmployee> => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          pages {
            name
            slug
            employee
          }
        }
      }
    }
  `);
  return site.siteMetadata.pages.filter((p: PagesEmployee) => p && p.employee);
};

const Team = () => {
  const images = useTeamImages() as EmployeeImage[];
  const team = useTeamList();
  const { t } = useTranslation('about');
  const teamList = Array.from(t('teamList', { returnObjects: true })) as any[];

  const getEmployee = (name: string) => teamList.filter((e: PagesEmployee) => e.name === name);

  return (
    <SectionTeam id="the-team">
      <TopSectionHeading>
        <strong>{t('About us')}</strong> — <span>{t('The Team')}</span>
      </TopSectionHeading>
      <TeamGrid>
        {team.map((p: PagesEmployee, index: number) => (
          <Employee key={p.slug.concat("/")} to={p.slug.concat("/")}>
            {images.filter(
              (img: EmployeeImage) => img.name === p.slug.replace(`/about/`, '')
            )[0] && (
              <EmployeePhoto
                alt={p.name}
                fluid={
                  images.filter(
                    (img: EmployeeImage) => {
                      return img.name === p.slug.replace(`/about/`, '');
                    }
                  )[0].src
                }
                key={p.slug.concat("/")}
                style={{
                  width: `100%`,
                  pointerEvents: `none`,
                }}
              />
            )}

            <EmployeeName>
              {getEmployee(p.name)[0] ? getEmployee(p.name)[0].name : p.name}
            </EmployeeName>
            <EmployeePosition>
              {getEmployee(p.name)[0] ? getEmployee(p.name)[0].position : p.employee.position}
            </EmployeePosition>
            <Departament>
              {getEmployee(p.name)[0] ? getEmployee(p.name)[0].department : p.employee.department}
            </Departament>
          </Employee>
        ))}
      </TeamGrid>
    </SectionTeam>
  );
};

export default Team;
