import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Item, useConsultationRoomImages } from './ConsultationRoom.data';
import {
  AnimationHeight,
  ConsultationRoomList,
  ConsultationRoomListItem,
  RoomContent,
  RoomsSection,
} from './Rooms.styles';
import Gallery from '../Gallery/Gallery';
import { MainText, SectionHeading } from '../Layout.styles';

const ConsultationRoom = () => {
  const [activeItem, setActiveItem] = useState(-1);
  const images = useConsultationRoomImages();
  const { t } = useTranslation('about');
  const { consultationRoomlist } = t('rooms.listRooms.0', { returnObjects: true });

  const handleMouseEnter = (index: number) => {
    setActiveItem(index);
  };

  const handleMouseLeave = () => {
    setActiveItem(-1);
  };

  return (
    <RoomsSection id="consultation">
      <RoomContent>
        <SectionHeading>
          <strong>{t('About us')}</strong> — <span>{t('rooms.title')}</span>
        </SectionHeading>
        <MainText>{t('rooms.listRooms.0.title')}</MainText>
        <ConsultationRoomList>
          {consultationRoomlist &&
            consultationRoomlist.map((item: Item, index: number) => (
              <ConsultationRoomListItem
                key={index}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave()}
              >
                <h6>{item.title}</h6>
                <AnimationHeight active={activeItem === index}>
                  <p>{item.text}</p>
                </AnimationHeight>
              </ConsultationRoomListItem>
            ))}
        </ConsultationRoomList>
      </RoomContent>
      <Gallery activeItem={activeItem} images={images} />
    </RoomsSection>
  );
};

export default ConsultationRoom;
