import styled from 'styled-components';
import { gridTablet, gridMobile, grid } from '../Variables';

export const Container = styled.div`
  position: relative;
  height: calc(${grid.col14} * 0.31144465290806755);

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    height: calc(${gridMobile.col6} * 0.6116207951070336);
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    height: calc(${gridTablet.col10} * 0.659919028340081);
  }

  & > div {
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  width: calc(${grid.col7});

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: calc(${gridMobile.col6});
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col10});
  }

  p {
    margin-top: 20px;
    font-family: RobotoSlab-Light, sans-serif;
    font-size: 14px;
    line-height: 23px;

    b {
      font-family: RobotoSlab-Regular, sans-serif;
    }

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 15px;
      line-height: 24px;
    }

    @media (min-width: 1280px) {
      font-size: 13px;
      line-height: 21px;
    }

    @media (min-width: 1400px) {
      font-size: 16px;
      line-height: 26px;
    }

    @media (min-width: 1920px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
`;

export const ResizableElement = styled.div`
  position: absolute;
  flex: 0 0 auto;
  width: 50%;
  background: blue;
  z-index: 1;
  overflow: hidden;
`;

export const StaticElement = styled.div`
  position: absolute;
  flex: 0 0 auto;
  width: 100%;
  left: 0;
`;

export const Resizer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  width: 4px;
  background-color: #fff;
  cursor: ew-resize;
  z-index: 3;

  & > svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -23px;
    margin: auto 0;
    z-index: 3;
  }
`;
