import styled from 'styled-components';

export const Icons = styled.div`
  display: flex;
  margin-top: 27px;

  @media (min-width: 1920px) {
    margin-top: 37px;
  }

  & > a {
    width: 20px;
    height: 20px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: 30px;
      height: 30px;
    }

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  & > a svg path {
    transition: fill 300ms ease;
  }

  & > a + a {
    margin-left: 10px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-left: 15px;
    }
  }
`;
