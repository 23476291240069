import React from 'react';
import styled from 'styled-components';

export const duration = 300;

export const ModalWrapper = styled(({ active, ...rest }) => <div {...rest} />)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  opacity: ${(props) => (props.active ? 1 : 0)};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  transition: visibility ${duration}ms linear, opacity ${duration}ms ease;
  transition-delay: ${(props) => (props.active ? 0 : duration)}ms, 0ms;
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;
