import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from '@reach/router';
import Award from './Award';
import Intro from './Intro';
import Rooms from './Rooms';
import Team from './Team';
import SEO from '../seo';

const About: React.FC<RouteComponentProps> = ({ location }) => {
  const { t } = useTranslation('about');
  const currentPath = location?.pathname ? location?.pathname : '';

  return (
    <>
      <SEO title={t('About us')} description={t('description')} path={currentPath} />
      <Intro />
      <Award />
      <Rooms />
      <Team />
    </>
  );
};

export default About;
