import styled from 'styled-components';
import { Date } from './Media.styles';
import { colors, gridTablet, grid, gridMobile } from '../Variables';

export const ArticleSection = styled.div`
  min-height: 100vh;
  background-color: #fff;
  padding: 150px calc(${grid.navWidth} + ${grid.col3} + ${grid.gridMargin} + ${grid.gridGutter})
    50px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    padding: 79px ${gridMobile.gridMargin};
    margin-bottom: 60px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    padding: 130px calc(${grid.navWidth} + ${gridTablet.col1} + ${gridTablet.gridGutter}) 50px
      calc(${grid.navWidth} + ${gridTablet.gridMargin});
  }

  ${Date} {
    margin-bottom: 2px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-bottom: 10px;
    }

    @media (min-width: 1280px) {
      margin-bottom: 8px;
    }

    @media (min-width: 1400px) {
      margin-bottom: 10px;
    }

    @media (min-width: 1920px) {
      margin-bottom: 16px;
    }
  }
`;

export const Text = styled.div`
  img {
    max-height: 400px;
  }

  p,
  li {
    font-family: RobotoSlab-Light, sans-serif;
    font-size: 14px;
    line-height: 21px;
    white-space: pre-line;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 20px;
      line-height: 31px;
    }

    @media (min-width: 1280px) {
      font-size: 18px;
      line-height: 28px;
    }

    @media (min-width: 1400px) {
      font-size: 20px;
      line-height: 31px;
    }

    @media (min-width: 1920px) {
      font-size: 28px;
      line-height: 44px;
    }
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    list-style-position: inside;
  }

  em {
    font-style: italic;
  }

  strong {
    font-family: RobotoSlab-Medium, sans-serif;
  }

  h1, h2 {
    font-size: 24px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 30px;
    }

    @media (min-width: 1280px) {
      font-size: 28px;
    }

    @media (min-width: 1400px) {
      font-size: 30px;
    }

    @media (min-width: 1920px) {
      font-size: 38px;
    }
  }

  h3 {
    font-size: 22px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 28px;
    }

    @media (min-width: 1280px) {
      font-size: 26px;
    }

    @media (min-width: 1400px) {
      font-size: 28px;
    }

    @media (min-width: 1920px) {
      font-size: 36px;
    }
  }

  h4 {
    font-size: 20px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 26px;
    }

    @media (min-width: 1280px) {
      font-size: 24px;
    }

    @media (min-width: 1400px) {
      font-size: 26px;
    }

    @media (min-width: 1920px) {
      font-size: 34px;
    }
  }

  h5 {
    font-size: 18px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 24px;
    }

    @media (min-width: 1280px) {
      font-size: 22px;
    }

    @media (min-width: 1400px) {
      font-size: 24px;
    }

    @media (min-width: 1920px) {
      font-size: 32px;
    }
  }

  h6 {
    font-size: 16px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 22px;
    }

    @media (min-width: 1280px) {
      font-size: 20px;
    }

    @media (min-width: 1400px) {
      font-size: 22px;
    }

    @media (min-width: 1920px) {
      font-size: 30px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: RobotoSlab-Regular, sans-serif;
    white-space: pre-line;
    margin: 30px 0 20px;
    line-height: 1.4;
  }

  p + ul,
  p + ol,
  ul + p,
  ol + p {
    margin-top: 30px;
  }

  a {
    color: ${colors.black};
    border-bottom: 1px solid ${colors.blue};
    text-decoration: none;
  }

  p + p {
    margin-top: 30px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 40px;
  font-family: RobotoSlab-Regular, sans-serif;
  font-size: 56px;
  line-height: 65px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    margin-bottom: 30px;
    font-size: 45px;
    line-height: 57px;
  }

  @media (min-width: 1280px) {
    margin-bottom: 50px;
    font-size: 76px;
    line-height: 86px;
  }

  @media (min-width: 1400px) {
    font-size: 85px;
    line-height: 96px;
  }

  @media (min-width: 1920px) {
    margin-bottom: 70px;
    font-size: 120px;
    line-height: 137px;
  }
`;
