import React, { useContext, useEffect, useState } from 'react';
import { StyledLogo } from './Logo.styles';
import { PageSectionContext, ModalContext, ThemeContext } from '../layout';
import { useMobile, useScrollPosition } from '../../hooks';

interface Props {
  menuIsOpened: boolean;
  onLogoClick: () => void;
}

const Logo = ({ menuIsOpened, onLogoClick }: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  const isBlue = useContext(ThemeContext)[0];
  const isMobile = useMobile();
  const modalIsOpened = useContext(ModalContext)[0];
  const position = useScrollPosition();
  const setPageSection = useContext(PageSectionContext)[1];

  useEffect(() => setCollapsed(position > 0), [position]);

  const handleClick = () => {
    onLogoClick();
    setPageSection(['/', '']);
  };

  return (
    <StyledLogo
      isBlue={isBlue}
      collapsed={isMobile ? collapsed : collapsed && !menuIsOpened}
      hidden={modalIsOpened}
      to={'/'}
      onClick={handleClick}
    >
      <svg
        width="57"
        height="85"
        viewBox="0 0 57 85"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.02285 47.0637L21.7427 80.8467L26.9224 71.495L13.9239 48.0353C13.5727 47.4051 13.7992 46.6074 14.4327 46.2595C15.0662 45.9083 15.8606 46.1348 16.2085 46.7683L28.4159 68.7968L40.6168 46.7683C40.968 46.1348 41.7624 45.9083 42.3959 46.2595C43.0261 46.6074 43.2526 47.4051 42.9047 48.0353L29.9095 71.495L35.0924 80.8467L53.8024 47.0637V3.53178H3.02285V47.0637ZM35.0924 84.8513C34.6165 84.8513 34.1799 84.592 33.9501 84.1784L28.4159 74.1932L22.885 84.1752C22.6551 84.592 22.2186 84.8513 21.7427 84.8513C21.2668 84.8513 20.8269 84.592 20.5971 84.1784L0.574127 48.0353C0.465912 47.8416 0.410156 47.6217 0.410156 47.4018V2.22536C0.410156 1.50322 0.99442 0.918945 1.71655 0.918945H55.109C55.8311 0.918945 56.4154 1.50322 56.4154 2.22536V47.4018C56.4154 47.6217 56.3596 47.8417 56.2545 48.0353L36.2348 84.1752C36.0049 84.592 35.5684 84.8513 35.0925 84.8513"
          fill={isBlue && !menuIsOpened ? '#ffffff' : '#00B5E2'}
        />
      </svg>

      {/* LOGO TEXT */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="svg1820"
        width="160"
        height="47"
        version="1.1"
        viewBox="0 0 42.333 12.435"
      >
        <g id="layer1">
          <g
            id="g1889"
            fill={isBlue && !menuIsOpened ? '#ffffff' : 'black'}
            fillOpacity="1"
            transform="matrix(.18186 0 0 .18186 -.28509 .44151)"
            stroke-width="1.0665"
          >
  <path d="m6.612 9.888h3.298l-1.62-5.064h-0.062zm-5.214 4.272 1.026-0.146 4.375-12.496h2.951l4.34 12.496 1.03 0.146v1.728h-4.897v-1.728l0.997-0.177-0.63-1.973h-4.662l-0.631 1.973 0.997 0.177v1.728h-4.896z"/>
  <path d="m16.034 2.23v-1.74h4.421v13.376l1.392 0.295v1.727h-5.667v-1.727l1.392-0.295v-11.341z"/>
  <path d="m30.438 10.637c0-1.041-0.152-1.865-0.46-2.474-0.304-0.609-0.826-0.91-1.563-0.91-0.447 0-0.826 0.093-1.137 0.28a1.956 1.956 0 0 0-0.73 0.804v4.512c0.177 0.326 0.426 0.578 0.736 0.749 0.314 0.17 0.696 0.257 1.15 0.257 0.743 0 1.265-0.26 1.56-0.786 0.295-0.522 0.444-1.264 0.444-2.224zm2.871 0.208c0 1.59-0.354 2.864-1.066 3.818-0.708 0.954-1.737 1.433-3.079 1.433-0.624 0-1.168-0.13-1.628-0.392a3.305 3.305 0 0 1-1.165-1.128l-0.205 1.311h-2.489v-13.363l-1.532-0.295v-1.737h4.403v5.85c0.31-0.425 0.68-0.755 1.112-0.988a3.146 3.146 0 0 1 1.486-0.344c1.354 0 2.389 0.512 3.097 1.534 0.712 1.026 1.066 2.39 1.066 4.093z"/>
  <path d="m42.316 14.537a3.57 3.57 0 0 1-1.267 1.155c-0.504 0.268-1.078 0.404-1.725 0.404-1.125 0-2.004-0.36-2.644-1.081-0.637-0.72-0.957-1.84-0.957-3.362v-4.412l-1.174-0.295v-1.737h4.058v6.466c0 0.774 0.127 1.333 0.388 1.672 0.26 0.338 0.646 0.51 1.159 0.51 0.457 0 0.848-0.075 1.18-0.225a2.04 2.04 0 0 0 0.815-0.637v-5.754l-1.265-0.295v-1.737h4.145v8.656l1.296 0.296v1.727h-3.822z"/>
  <path d="m47.819 14.16 1.392-0.295v-6.633h-1.588v-2.023h1.588v-1.184c0-1.2 0.345-2.122 1.038-2.768 0.69-0.65 1.659-0.973 2.91-0.973 0.243 0 0.492 0.019 0.743 0.053 0.255 0.037 0.532 0.087 0.836 0.152l-0.248 2.144c-0.159-0.025-0.311-0.05-0.457-0.068a3.69 3.69 0 0 0-0.519-0.031c-0.47 0-0.82 0.127-1.063 0.385-0.239 0.258-0.36 0.624-0.36 1.106v1.185h2.125v2.023h-2.125v6.633l1.392 0.295v1.728h-5.664z"/>
  <path d="m60.005 7.232c-0.534 0-0.957 0.205-1.265 0.612-0.31 0.41-0.5 0.945-0.574 1.61l0.03 0.05h3.533v-0.259c0-0.612-0.143-1.1-0.428-1.463-0.286-0.367-0.718-0.55-1.296-0.55zm0.255 8.865c-1.538 0-2.769-0.494-3.691-1.482-0.923-0.985-1.383-2.24-1.383-3.76v-0.395c0-1.584 0.435-2.892 1.308-3.918 0.873-1.028 2.041-1.538 3.511-1.531 1.439 0 2.557 0.435 3.356 1.305 0.795 0.866 1.193 2.04 1.193 3.523v1.569h-6.376l-0.022 0.06c0.053 0.701 0.29 1.282 0.706 1.736 0.42 0.454 0.988 0.68 1.706 0.68 0.637 0 1.165-0.062 1.587-0.192 0.42-0.128 0.883-0.33 1.383-0.606l0.78 1.777c-0.441 0.348-1.013 0.64-1.712 0.876-0.702 0.24-1.485 0.358-2.346 0.358"/>
  <path d="m70.301 2.652h-2.883v-2.162h2.883zm-4.271 11.509 1.39-0.295v-6.626l-1.539-0.295v-1.735h4.421v8.657l1.383 0.295v1.727h-5.655z"/>
  <path d="m73.051 14.16 1.38-0.294v-6.626l-1.529-0.295v-1.735h4.216l0.128 1.56c0.248-0.553 0.572-0.985 0.966-1.292a2.16 2.16 0 0 1 1.374-0.467c0.149 0 0.31 0.013 0.478 0.038 0.168 0.022 0.308 0.05 0.42 0.084l-0.308 2.625-1.184-0.031c-0.422 0-0.77 0.084-1.047 0.249a1.604 1.604 0 0 0-0.63 0.699v5.192l1.382 0.295v1.727h-5.647z"/>
  <path d="m85.65 13.965c0.435 0 0.83-0.096 1.184-0.283a2.3 2.3 0 0 0 0.83-0.705v-1.627h-1.47c-0.615 0-1.078 0.143-1.392 0.429-0.317 0.286-0.475 0.63-0.475 1.031 0 0.36 0.118 0.647 0.35 0.848 0.234 0.206 0.557 0.308 0.973 0.308zm2.498 1.923a4.818 4.818 0 0 1-0.348-1.115 3.964 3.964 0 0 1-1.246 0.957c-0.49 0.245-1.053 0.367-1.684 0.367-1.047 0-1.876-0.286-2.492-0.855-0.615-0.568-0.922-1.345-0.922-2.324 0-1 0.4-1.774 1.202-2.318 0.805-0.547 1.982-0.82 3.536-0.82h1.47v-1.047c0-0.513-0.15-0.91-0.445-1.196-0.295-0.28-0.733-0.423-1.314-0.423-0.33 0-0.622 0.037-0.876 0.115a2.202 2.202 0 0 0-0.622 0.27l-0.19 1.147h-2.171l0.01-2.37a8.141 8.141 0 0 1 1.81-0.899 6.61 6.61 0 0 1 2.229-0.367c1.323 0 2.395 0.327 3.222 0.973 0.826 0.65 1.24 1.572 1.24 2.768v4.412c0 0.165 0 0.32 3e-3 0.467 4e-3 0.142 0.014 0.282 0.034 0.413l0.88 0.118v1.727z"/>
  <path d="m1.398 26.393v-2.292h7.855v2.292l-2.032 0.392v5.475h7.74v-5.475l-2.032-0.392v-2.292h7.858v2.292l-2.035 0.392v13.615l2.035 0.395v2.277h-7.858v-2.277l2.032-0.395v-5.21h-7.741v5.21l2.032 0.395v2.277h-7.854v-2.277l2.02-0.395v-13.615z"/>
  <path d="m29.027 31.647c-0.702 0-1.258 0.267-1.668 0.805-0.407 0.54-0.659 1.249-0.755 2.125l0.04 0.065h4.664v-0.338c0-0.808-0.19-1.454-0.569-1.936-0.376-0.482-0.947-0.72-1.712-0.72zm0.339 11.698c-2.032 0-3.657-0.65-4.872-1.954-1.218-1.302-1.824-2.958-1.824-4.965v-0.52c0-2.093 0.575-3.818 1.725-5.172 1.152-1.355 2.697-2.03 4.632-2.02 1.902 0 3.38 0.575 4.431 1.718 1.05 1.147 1.575 2.7 1.575 4.655v2.069h-8.417l-0.025 0.078c0.069 0.932 0.38 1.696 0.933 2.296 0.55 0.6 1.298 0.898 2.246 0.898 0.842 0 1.541-0.084 2.097-0.255 0.556-0.168 1.165-0.435 1.824-0.802l1.032 2.346c-0.584 0.46-1.336 0.848-2.262 1.16-0.926 0.313-1.958 0.468-3.095 0.468"/>
  <path d="m42.778 40.53a3.29 3.29 0 0 0 1.566-0.37c0.47-0.248 0.833-0.559 1.094-0.932v-2.15h-1.942c-0.808 0-1.42 0.19-1.836 0.569-0.42 0.376-0.628 0.83-0.628 1.36 0 0.48 0.156 0.852 0.463 1.122 0.308 0.268 0.737 0.401 1.283 0.401zm3.297 2.542a6.968 6.968 0 0 1-0.273-0.724 6.096 6.096 0 0 1-0.184-0.75c-0.45 0.523-1 0.945-1.647 1.265-0.646 0.32-1.385 0.482-2.221 0.482-1.38 0-2.476-0.376-3.29-1.125-0.811-0.752-1.218-1.774-1.218-3.07 0-1.32 0.528-2.34 1.59-3.063 1.06-0.718 2.613-1.078 4.664-1.078h1.942v-1.383c0-0.677-0.196-1.202-0.587-1.578-0.392-0.373-0.966-0.56-1.734-0.56-0.432 0-0.82 0.05-1.159 0.15-0.338 0.102-0.612 0.22-0.82 0.36l-0.249 1.51h-2.864l0.012-3.126a10.72 10.72 0 0 1 2.389-1.187c0.901-0.32 1.88-0.481 2.94-0.481 1.745 0 3.162 0.429 4.253 1.283 1.09 0.854 1.637 2.072 1.637 3.654v5.826c0 0.217 0 0.42 4e-3 0.612 8e-3 0.193 0.021 0.373 0.046 0.547l1.162 0.155v2.28z"/>
  <path d="m51.621 25.038v-2.293h5.835v17.654l1.837 0.392v2.28h-7.48v-2.28l1.84-0.392v-14.969z"/>
  <path d="m66.414 25.52v3.455h2.477v2.672h-2.477v7.178c0 0.546 0.115 0.938 0.339 1.174 0.227 0.233 0.531 0.351 0.914 0.351 0.26 0 0.487-0.012 0.683-0.034s0.413-0.06 0.659-0.109l0.326 2.747c-0.426 0.13-0.848 0.23-1.265 0.295-0.416 0.065-0.87 0.096-1.354 0.096-1.311 0-2.321-0.36-3.03-1.08-0.708-0.722-1.062-1.865-1.062-3.428v-7.19h-2.074v-2.672h2.073v-3.455z"/>
  <path d="m70.814 40.79 1.836-0.39v-14.97l-2.032-0.392v-2.293h5.822v8.025a4.82 4.82 0 0 1 1.629-1.516 4.294 4.294 0 0 1 2.137-0.54c1.476 0 2.635 0.487 3.474 1.466 0.836 0.976 1.255 2.486 1.255 4.527v5.693l1.827 0.392v2.28h-7.272v-2.28l1.643-0.392v-5.717c0-1.096-0.196-1.87-0.58-2.327-0.386-0.457-0.964-0.683-1.728-0.683-0.529 0-0.991 0.096-1.386 0.285-0.395 0.193-0.727 0.46-0.998 0.808v7.634l1.644 0.392v2.28h-7.27z"/>
  <path d="m94.957 26.393v-2.292h7.857v2.292l-2.035 0.392v13.615l2.035 0.395v2.277h-7.857v-2.277l2.032-0.395v-13.615z"/>
  <path d="m104.41 40.791 1.824-0.391v-8.74l-2.02-0.392v-2.293h5.562l0.17 2.02c0.47-0.721 1.045-1.284 1.731-1.681 0.687-0.401 1.458-0.6 2.309-0.6 1.423 0 2.535 0.447 3.334 1.342 0.798 0.895 1.199 2.3 1.199 4.207v6.137l1.824 0.391v2.28h-7.27v-2.28l1.628-0.391v-6.121c0-0.948-0.19-1.62-0.572-2.014-0.382-0.397-0.96-0.593-1.734-0.593-0.503 0-0.954 0.102-1.355 0.304-0.4 0.205-0.736 0.494-1.003 0.867v7.557l1.538 0.391v2.28h-7.165z"/>
  <path d="m133.33 33.822h-2.553l-0.405-1.758a3.335 3.335 0 0 0-0.981-0.522 3.927 3.927 0 0 0-1.312-0.209c-0.667 0-1.2 0.15-1.59 0.448-0.392 0.301-0.585 0.677-0.585 1.128 0 0.425 0.187 0.78 0.56 1.062 0.373 0.283 1.122 0.532 2.24 0.75 1.746 0.347 3.041 0.857 3.884 1.53 0.841 0.672 1.264 1.601 1.264 2.782 0 1.267-0.547 2.305-1.635 3.107-1.09 0.805-2.525 1.205-4.305 1.205-1.088 0-2.086-0.158-2.993-0.475a7.64 7.64 0 0 1-2.417-1.361l-0.038-3.166h2.657l0.523 1.836c0.223 0.193 0.534 0.333 0.922 0.423 0.391 0.093 0.801 0.14 1.228 0.14 0.773 0 1.36-0.14 1.764-0.42 0.404-0.276 0.606-0.655 0.606-1.13 0-0.42-0.205-0.78-0.612-1.085-0.41-0.302-1.162-0.572-2.256-0.805-1.66-0.339-2.91-0.842-3.76-1.507-0.845-0.665-1.267-1.569-1.267-2.715 0-1.181 0.485-2.2 1.457-3.058 0.972-0.854 2.358-1.283 4.158-1.283 1.093 0 2.13 0.15 3.106 0.444 0.98 0.295 1.746 0.674 2.3 1.134z"/>
  <path d="m141.44 25.52v3.455h2.476v2.672h-2.477v7.178c0 0.546 0.116 0.938 0.339 1.174 0.227 0.233 0.532 0.351 0.914 0.351 0.26 0 0.487-0.012 0.683-0.034 0.195-0.022 0.414-0.06 0.66-0.109l0.325 2.747c-0.425 0.13-0.848 0.23-1.264 0.295-0.417 0.065-0.87 0.096-1.355 0.096-1.311 0-2.321-0.36-3.03-1.08-0.708-0.722-1.062-1.865-1.062-3.428v-7.19h-2.073v-2.672h2.073v-3.455z"/>
  <path d="m151.57 25.6h-3.806v-2.855h3.806zm-5.643 15.19 1.837-0.39v-8.74l-2.032-0.392v-2.293h5.838v11.424l1.824 0.392v2.28h-7.467z"/>
  <path d="m160.53 25.52v3.455h2.473v2.672h-2.474v7.178c0 0.546 0.112 0.938 0.34 1.174 0.226 0.233 0.527 0.351 0.91 0.351 0.26 0 0.49-0.012 0.686-0.034s0.413-0.06 0.656-0.109l0.326 2.747c-0.425 0.13-0.848 0.23-1.264 0.295a8.783 8.783 0 0 1-1.356 0.096c-1.31 0-2.32-0.36-3.028-1.08-0.71-0.722-1.064-1.865-1.064-3.428v-7.19h-2.069v-2.672h2.07v-3.455z"/>
  <path d="m175 41.289a4.713 4.713 0 0 1-1.674 1.522c-0.665 0.357-1.423 0.534-2.275 0.534-1.485 0-2.65-0.475-3.491-1.426-0.843-0.95-1.262-2.43-1.262-4.437v-5.822l-1.554-0.392v-2.293h5.357v8.535c0 1.026 0.17 1.759 0.513 2.206 0.345 0.448 0.854 0.671 1.53 0.671 0.601 0 1.12-0.096 1.558-0.292 0.44-0.195 0.798-0.475 1.077-0.842v-7.593l-1.668-0.392v-2.293h5.472v11.425l1.706 0.391v2.28h-5.043z"/>
  <path d="m187.67 25.52v3.455h2.472v2.672h-2.472v7.178c0 0.546 0.11 0.938 0.339 1.174 0.226 0.233 0.528 0.351 0.91 0.351 0.26 0 0.49-0.012 0.686-0.034s0.413-0.06 0.655-0.109l0.328 2.747c-0.427 0.13-0.849 0.23-1.266 0.295a8.768 8.768 0 0 1-1.354 0.096c-1.311 0-2.32-0.36-3.03-1.08-0.708-0.722-1.062-1.865-1.062-3.428v-7.19h-2.07v-2.672h2.07v-3.455z"/>
  <path d="m198.68 31.647c-0.701 0-1.258 0.267-1.668 0.805-0.407 0.54-0.659 1.249-0.756 2.125l0.041 0.065h4.664v-0.338c0-0.808-0.186-1.454-0.566-1.936-0.379-0.482-0.95-0.72-1.715-0.72zm0.339 11.698c-2.032 0-3.658-0.65-4.872-1.954-1.215-1.302-1.824-2.958-1.824-4.965v-0.52c0-2.093 0.575-3.818 1.727-5.172 1.15-1.355 2.695-2.03 4.63-2.02 1.904 0 3.381 0.575 4.43 1.718 1.054 1.147 1.579 2.7 1.579 4.655v2.069h-8.416l-0.03 0.078c0.07 0.932 0.38 1.696 0.933 2.296 0.554 0.6 1.3 0.898 2.25 0.898 0.838 0 1.538-0.084 2.094-0.255 0.559-0.168 1.165-0.435 1.826-0.802l1.029 2.346c-0.581 0.46-1.336 0.848-2.261 1.16-0.927 0.313-1.959 0.468-3.096 0.468"/>
  <path d="m206.41 26.126h0.4a0.521 0.521 0 0 0 0.29-0.087 0.266 0.266 0 0 0 0.117-0.23c0-0.127-0.033-0.217-0.102-0.27-0.068-0.053-0.19-0.078-0.36-0.078h-0.345zm0 0.342v0.889h-0.391v-2.237h0.736c0.267 0 0.475 0.059 0.624 0.17 0.153 0.119 0.226 0.286 0.226 0.507a0.49 0.49 0 0 1-0.086 0.286 0.638 0.638 0 0 1-0.249 0.205 0.487 0.487 0 0 1 0.252 0.208c0.05 0.093 0.078 0.205 0.078 0.339v0.146c0 0.071 3e-3 0.136 9e-3 0.192a0.433 0.433 0 0 0 0.035 0.143v0.04h-0.402a0.399 0.399 0 0 1-0.03-0.16c-4e-3 -0.072-4e-3 -0.144-4e-3 -0.218v-0.143c0-0.125-0.031-0.218-0.09-0.28-0.06-0.059-0.155-0.087-0.292-0.087zm-1.172-0.199c0 0.466 0.15 0.858 0.455 1.178 0.3 0.32 0.667 0.478 1.096 0.478 0.428 0 0.795-0.158 1.097-0.478 0.301-0.32 0.453-0.712 0.453-1.178 0-0.46-0.152-0.851-0.453-1.168a1.46 1.46 0 0 0-1.097-0.475c-0.43 0-0.796 0.158-1.096 0.472-0.305 0.317-0.455 0.708-0.455 1.171zm-0.317 0c0-0.553 0.18-1.019 0.544-1.398 0.364-0.38 0.805-0.569 1.324-0.569 0.518 0 0.96 0.19 1.323 0.57 0.364 0.378 0.544 0.844 0.544 1.397 0 0.556-0.18 1.022-0.544 1.405a1.775 1.775 0 0 1-1.323 0.571 1.77 1.77 0 0 1-1.324-0.571c-0.364-0.383-0.544-0.849-0.544-1.405z"/>
  <path d="m6.612 9.888h3.298l-1.62-5.064h-0.062zm-5.214 4.272 1.026-0.146 4.375-12.496h2.951l4.34 12.496 1.03 0.146v1.728h-4.897v-1.728l0.997-0.177-0.63-1.973h-4.662l-0.631 1.973 0.997 0.177v1.728h-4.896z"/>
  <path d="m16.034 2.23v-1.74h4.421v13.376l1.392 0.295v1.727h-5.667v-1.727l1.392-0.295v-11.341z"/>
  <path d="m30.438 10.637c0-1.041-0.152-1.865-0.46-2.474-0.304-0.609-0.826-0.91-1.563-0.91-0.447 0-0.826 0.093-1.137 0.28a1.956 1.956 0 0 0-0.73 0.804v4.512c0.177 0.326 0.426 0.578 0.736 0.749 0.314 0.17 0.696 0.257 1.15 0.257 0.743 0 1.265-0.26 1.56-0.786 0.295-0.522 0.444-1.264 0.444-2.224zm2.871 0.208c0 1.59-0.354 2.864-1.066 3.818-0.708 0.954-1.737 1.433-3.079 1.433-0.624 0-1.168-0.13-1.628-0.392a3.305 3.305 0 0 1-1.165-1.128l-0.205 1.311h-2.489v-13.363l-1.532-0.295v-1.737h4.403v5.85c0.31-0.425 0.68-0.755 1.112-0.988a3.146 3.146 0 0 1 1.486-0.344c1.354 0 2.389 0.512 3.097 1.534 0.712 1.026 1.066 2.39 1.066 4.093z"/>
  <path d="m42.316 14.537a3.57 3.57 0 0 1-1.267 1.155c-0.504 0.268-1.078 0.404-1.725 0.404-1.125 0-2.004-0.36-2.644-1.081-0.637-0.72-0.957-1.84-0.957-3.362v-4.412l-1.174-0.295v-1.737h4.058v6.466c0 0.774 0.127 1.333 0.388 1.672 0.26 0.338 0.646 0.51 1.159 0.51 0.457 0 0.848-0.075 1.18-0.225a2.04 2.04 0 0 0 0.815-0.637v-5.754l-1.265-0.295v-1.737h4.145v8.656l1.296 0.296v1.727h-3.822z"/>
  <path d="m47.819 14.16 1.392-0.295v-6.633h-1.588v-2.023h1.588v-1.184c0-1.2 0.345-2.122 1.038-2.768 0.69-0.65 1.659-0.973 2.91-0.973 0.243 0 0.492 0.019 0.743 0.053 0.255 0.037 0.532 0.087 0.836 0.152l-0.248 2.144c-0.159-0.025-0.311-0.05-0.457-0.068a3.69 3.69 0 0 0-0.519-0.031c-0.47 0-0.82 0.127-1.063 0.385-0.239 0.258-0.36 0.624-0.36 1.106v1.185h2.125v2.023h-2.125v6.633l1.392 0.295v1.728h-5.664z"/>
  <path d="m60.005 7.232c-0.534 0-0.957 0.205-1.265 0.612-0.31 0.41-0.5 0.945-0.574 1.61l0.03 0.05h3.533v-0.259c0-0.612-0.143-1.1-0.428-1.463-0.286-0.367-0.718-0.55-1.296-0.55zm0.255 8.865c-1.538 0-2.769-0.494-3.691-1.482-0.923-0.985-1.383-2.24-1.383-3.76v-0.395c0-1.584 0.435-2.892 1.308-3.918 0.873-1.028 2.041-1.538 3.511-1.531 1.439 0 2.557 0.435 3.356 1.305 0.795 0.866 1.193 2.04 1.193 3.523v1.569h-6.376l-0.022 0.06c0.053 0.701 0.29 1.282 0.706 1.736 0.42 0.454 0.988 0.68 1.706 0.68 0.637 0 1.165-0.062 1.587-0.192 0.42-0.128 0.883-0.33 1.383-0.606l0.78 1.777c-0.441 0.348-1.013 0.64-1.712 0.876-0.702 0.24-1.485 0.358-2.346 0.358"/>
  <path d="m70.301 2.652h-2.883v-2.162h2.883zm-4.271 11.509 1.39-0.295v-6.626l-1.539-0.295v-1.735h4.421v8.657l1.383 0.295v1.727h-5.655z"/>
  <path d="m73.051 14.16 1.38-0.294v-6.626l-1.529-0.295v-1.735h4.216l0.128 1.56c0.248-0.553 0.572-0.985 0.966-1.292a2.16 2.16 0 0 1 1.374-0.467c0.149 0 0.31 0.013 0.478 0.038 0.168 0.022 0.308 0.05 0.42 0.084l-0.308 2.625-1.184-0.031c-0.422 0-0.77 0.084-1.047 0.249a1.604 1.604 0 0 0-0.63 0.699v5.192l1.382 0.295v1.727h-5.647z"/>
  <path d="m85.65 13.965c0.435 0 0.83-0.096 1.184-0.283a2.3 2.3 0 0 0 0.83-0.705v-1.627h-1.47c-0.615 0-1.078 0.143-1.392 0.429-0.317 0.286-0.475 0.63-0.475 1.031 0 0.36 0.118 0.647 0.35 0.848 0.234 0.206 0.557 0.308 0.973 0.308zm2.498 1.923a4.818 4.818 0 0 1-0.348-1.115 3.964 3.964 0 0 1-1.246 0.957c-0.49 0.245-1.053 0.367-1.684 0.367-1.047 0-1.876-0.286-2.492-0.855-0.615-0.568-0.922-1.345-0.922-2.324 0-1 0.4-1.774 1.202-2.318 0.805-0.547 1.982-0.82 3.536-0.82h1.47v-1.047c0-0.513-0.15-0.91-0.445-1.196-0.295-0.28-0.733-0.423-1.314-0.423-0.33 0-0.622 0.037-0.876 0.115a2.202 2.202 0 0 0-0.622 0.27l-0.19 1.147h-2.171l0.01-2.37a8.141 8.141 0 0 1 1.81-0.899 6.61 6.61 0 0 1 2.229-0.367c1.323 0 2.395 0.327 3.222 0.973 0.826 0.65 1.24 1.572 1.24 2.768v4.412c0 0.165 0 0.32 3e-3 0.467 4e-3 0.142 0.014 0.282 0.034 0.413l0.88 0.118v1.727z"/>
  <path d="m1.398 26.393v-2.292h7.855v2.292l-2.032 0.392v5.475h7.74v-5.475l-2.032-0.392v-2.292h7.858v2.292l-2.035 0.392v13.615l2.035 0.395v2.277h-7.858v-2.277l2.032-0.395v-5.21h-7.741v5.21l2.032 0.395v2.277h-7.854v-2.277l2.02-0.395v-13.615z"/>
  <path d="m29.027 31.647c-0.702 0-1.258 0.267-1.668 0.805-0.407 0.54-0.659 1.249-0.755 2.125l0.04 0.065h4.664v-0.338c0-0.808-0.19-1.454-0.569-1.936-0.376-0.482-0.947-0.72-1.712-0.72zm0.339 11.698c-2.032 0-3.657-0.65-4.872-1.954-1.218-1.302-1.824-2.958-1.824-4.965v-0.52c0-2.093 0.575-3.818 1.725-5.172 1.152-1.355 2.697-2.03 4.632-2.02 1.902 0 3.38 0.575 4.431 1.718 1.05 1.147 1.575 2.7 1.575 4.655v2.069h-8.417l-0.025 0.078c0.069 0.932 0.38 1.696 0.933 2.296 0.55 0.6 1.298 0.898 2.246 0.898 0.842 0 1.541-0.084 2.097-0.255 0.556-0.168 1.165-0.435 1.824-0.802l1.032 2.346c-0.584 0.46-1.336 0.848-2.262 1.16-0.926 0.313-1.958 0.468-3.095 0.468"/>
  <path d="m42.778 40.53a3.29 3.29 0 0 0 1.566-0.37c0.47-0.248 0.833-0.559 1.094-0.932v-2.15h-1.942c-0.808 0-1.42 0.19-1.836 0.569-0.42 0.376-0.628 0.83-0.628 1.36 0 0.48 0.156 0.852 0.463 1.122 0.308 0.268 0.737 0.401 1.283 0.401zm3.297 2.542a6.968 6.968 0 0 1-0.273-0.724 6.096 6.096 0 0 1-0.184-0.75c-0.45 0.523-1 0.945-1.647 1.265-0.646 0.32-1.385 0.482-2.221 0.482-1.38 0-2.476-0.376-3.29-1.125-0.811-0.752-1.218-1.774-1.218-3.07 0-1.32 0.528-2.34 1.59-3.063 1.06-0.718 2.613-1.078 4.664-1.078h1.942v-1.383c0-0.677-0.196-1.202-0.587-1.578-0.392-0.373-0.966-0.56-1.734-0.56-0.432 0-0.82 0.05-1.159 0.15-0.338 0.102-0.612 0.22-0.82 0.36l-0.249 1.51h-2.864l0.012-3.126a10.72 10.72 0 0 1 2.389-1.187c0.901-0.32 1.88-0.481 2.94-0.481 1.745 0 3.162 0.429 4.253 1.283 1.09 0.854 1.637 2.072 1.637 3.654v5.826c0 0.217 0 0.42 4e-3 0.612 8e-3 0.193 0.021 0.373 0.046 0.547l1.162 0.155v2.28z"/>
  <path d="m51.621 25.038v-2.293h5.835v17.654l1.837 0.392v2.28h-7.48v-2.28l1.84-0.392v-14.969z"/>
  <path d="m66.414 25.52v3.455h2.477v2.672h-2.477v7.178c0 0.546 0.115 0.938 0.339 1.174 0.227 0.233 0.531 0.351 0.914 0.351 0.26 0 0.487-0.012 0.683-0.034s0.413-0.06 0.659-0.109l0.326 2.747c-0.426 0.13-0.848 0.23-1.265 0.295-0.416 0.065-0.87 0.096-1.354 0.096-1.311 0-2.321-0.36-3.03-1.08-0.708-0.722-1.062-1.865-1.062-3.428v-7.19h-2.074v-2.672h2.073v-3.455z"/>
  <path d="m70.814 40.79 1.836-0.39v-14.97l-2.032-0.392v-2.293h5.822v8.025a4.82 4.82 0 0 1 1.629-1.516 4.294 4.294 0 0 1 2.137-0.54c1.476 0 2.635 0.487 3.474 1.466 0.836 0.976 1.255 2.486 1.255 4.527v5.693l1.827 0.392v2.28h-7.272v-2.28l1.643-0.392v-5.717c0-1.096-0.196-1.87-0.58-2.327-0.386-0.457-0.964-0.683-1.728-0.683-0.529 0-0.991 0.096-1.386 0.285-0.395 0.193-0.727 0.46-0.998 0.808v7.634l1.644 0.392v2.28h-7.27z"/>
  <path d="m94.957 26.393v-2.292h7.857v2.292l-2.035 0.392v13.615l2.035 0.395v2.277h-7.857v-2.277l2.032-0.395v-13.615z"/>
  <path d="m104.41 40.791 1.824-0.391v-8.74l-2.02-0.392v-2.293h5.562l0.17 2.02c0.47-0.721 1.045-1.284 1.731-1.681 0.687-0.401 1.458-0.6 2.309-0.6 1.423 0 2.535 0.447 3.334 1.342 0.798 0.895 1.199 2.3 1.199 4.207v6.137l1.824 0.391v2.28h-7.27v-2.28l1.628-0.391v-6.121c0-0.948-0.19-1.62-0.572-2.014-0.382-0.397-0.96-0.593-1.734-0.593-0.503 0-0.954 0.102-1.355 0.304-0.4 0.205-0.736 0.494-1.003 0.867v7.557l1.538 0.391v2.28h-7.165z"/>
  <path d="m133.33 33.822h-2.553l-0.405-1.758a3.335 3.335 0 0 0-0.981-0.522 3.927 3.927 0 0 0-1.312-0.209c-0.667 0-1.2 0.15-1.59 0.448-0.392 0.301-0.585 0.677-0.585 1.128 0 0.425 0.187 0.78 0.56 1.062 0.373 0.283 1.122 0.532 2.24 0.75 1.746 0.347 3.041 0.857 3.884 1.53 0.841 0.672 1.264 1.601 1.264 2.782 0 1.267-0.547 2.305-1.635 3.107-1.09 0.805-2.525 1.205-4.305 1.205-1.088 0-2.086-0.158-2.993-0.475a7.64 7.64 0 0 1-2.417-1.361l-0.038-3.166h2.657l0.523 1.836c0.223 0.193 0.534 0.333 0.922 0.423 0.391 0.093 0.801 0.14 1.228 0.14 0.773 0 1.36-0.14 1.764-0.42 0.404-0.276 0.606-0.655 0.606-1.13 0-0.42-0.205-0.78-0.612-1.085-0.41-0.302-1.162-0.572-2.256-0.805-1.66-0.339-2.91-0.842-3.76-1.507-0.845-0.665-1.267-1.569-1.267-2.715 0-1.181 0.485-2.2 1.457-3.058 0.972-0.854 2.358-1.283 4.158-1.283 1.093 0 2.13 0.15 3.106 0.444 0.98 0.295 1.746 0.674 2.3 1.134z"/>
  <path d="m141.44 25.52v3.455h2.476v2.672h-2.477v7.178c0 0.546 0.116 0.938 0.339 1.174 0.227 0.233 0.532 0.351 0.914 0.351 0.26 0 0.487-0.012 0.683-0.034 0.195-0.022 0.414-0.06 0.66-0.109l0.325 2.747c-0.425 0.13-0.848 0.23-1.264 0.295-0.417 0.065-0.87 0.096-1.355 0.096-1.311 0-2.321-0.36-3.03-1.08-0.708-0.722-1.062-1.865-1.062-3.428v-7.19h-2.073v-2.672h2.073v-3.455z"/>
  <path d="m151.57 25.6h-3.806v-2.855h3.806zm-5.643 15.19 1.837-0.39v-8.74l-2.032-0.392v-2.293h5.838v11.424l1.824 0.392v2.28h-7.467z"/>
  <path d="m160.53 25.52v3.455h2.473v2.672h-2.474v7.178c0 0.546 0.112 0.938 0.34 1.174 0.226 0.233 0.527 0.351 0.91 0.351 0.26 0 0.49-0.012 0.686-0.034s0.413-0.06 0.656-0.109l0.326 2.747c-0.425 0.13-0.848 0.23-1.264 0.295a8.783 8.783 0 0 1-1.356 0.096c-1.31 0-2.32-0.36-3.028-1.08-0.71-0.722-1.064-1.865-1.064-3.428v-7.19h-2.069v-2.672h2.07v-3.455z"/>
  <path d="m175 41.289a4.713 4.713 0 0 1-1.674 1.522c-0.665 0.357-1.423 0.534-2.275 0.534-1.485 0-2.65-0.475-3.491-1.426-0.843-0.95-1.262-2.43-1.262-4.437v-5.822l-1.554-0.392v-2.293h5.357v8.535c0 1.026 0.17 1.759 0.513 2.206 0.345 0.448 0.854 0.671 1.53 0.671 0.601 0 1.12-0.096 1.558-0.292 0.44-0.195 0.798-0.475 1.077-0.842v-7.593l-1.668-0.392v-2.293h5.472v11.425l1.706 0.391v2.28h-5.043z"/>
  <path d="m187.67 25.52v3.455h2.472v2.672h-2.472v7.178c0 0.546 0.11 0.938 0.339 1.174 0.226 0.233 0.528 0.351 0.91 0.351 0.26 0 0.49-0.012 0.686-0.034s0.413-0.06 0.655-0.109l0.328 2.747c-0.427 0.13-0.849 0.23-1.266 0.295a8.768 8.768 0 0 1-1.354 0.096c-1.311 0-2.32-0.36-3.03-1.08-0.708-0.722-1.062-1.865-1.062-3.428v-7.19h-2.07v-2.672h2.07v-3.455z"/>
  <path d="m198.68 31.647c-0.701 0-1.258 0.267-1.668 0.805-0.407 0.54-0.659 1.249-0.756 2.125l0.041 0.065h4.664v-0.338c0-0.808-0.186-1.454-0.566-1.936-0.379-0.482-0.95-0.72-1.715-0.72zm0.339 11.698c-2.032 0-3.658-0.65-4.872-1.954-1.215-1.302-1.824-2.958-1.824-4.965v-0.52c0-2.093 0.575-3.818 1.727-5.172 1.15-1.355 2.695-2.03 4.63-2.02 1.904 0 3.381 0.575 4.43 1.718 1.054 1.147 1.579 2.7 1.579 4.655v2.069h-8.416l-0.03 0.078c0.07 0.932 0.38 1.696 0.933 2.296 0.554 0.6 1.3 0.898 2.25 0.898 0.838 0 1.538-0.084 2.094-0.255 0.559-0.168 1.165-0.435 1.826-0.802l1.029 2.346c-0.581 0.46-1.336 0.848-2.261 1.16-0.927 0.313-1.959 0.468-3.096 0.468"/>
  <path d="m206.41 26.126h0.4a0.521 0.521 0 0 0 0.29-0.087 0.266 0.266 0 0 0 0.117-0.23c0-0.127-0.033-0.217-0.102-0.27-0.068-0.053-0.19-0.078-0.36-0.078h-0.345zm0 0.342v0.889h-0.391v-2.237h0.736c0.267 0 0.475 0.059 0.624 0.17 0.153 0.119 0.226 0.286 0.226 0.507a0.49 0.49 0 0 1-0.086 0.286 0.638 0.638 0 0 1-0.249 0.205 0.487 0.487 0 0 1 0.252 0.208c0.05 0.093 0.078 0.205 0.078 0.339v0.146c0 0.071 3e-3 0.136 9e-3 0.192a0.433 0.433 0 0 0 0.035 0.143v0.04h-0.402a0.399 0.399 0 0 1-0.03-0.16c-4e-3 -0.072-4e-3 -0.144-4e-3 -0.218v-0.143c0-0.125-0.031-0.218-0.09-0.28-0.06-0.059-0.155-0.087-0.292-0.087zm-1.172-0.199c0 0.466 0.15 0.858 0.455 1.178 0.3 0.32 0.667 0.478 1.096 0.478 0.428 0 0.795-0.158 1.097-0.478 0.301-0.32 0.453-0.712 0.453-1.178 0-0.46-0.152-0.851-0.453-1.168a1.46 1.46 0 0 0-1.097-0.475c-0.43 0-0.796 0.158-1.096 0.472-0.305 0.317-0.455 0.708-0.455 1.171zm-0.317 0c0-0.553 0.18-1.019 0.544-1.398 0.364-0.38 0.805-0.569 1.324-0.569 0.518 0 0.96 0.19 1.323 0.57 0.364 0.378 0.544 0.844 0.544 1.397 0 0.556-0.18 1.022-0.544 1.405a1.775 1.775 0 0 1-1.323 0.571 1.77 1.77 0 0 1-1.324-0.571c-0.364-0.383-0.544-0.849-0.544-1.405z"/>
 </g>
 <g stroke-width=".26458" aria-label="Providing Expert Care for +20 years">
    <path d="m -0.09403274,12.06006 v -0.09947 l 0.12867927,-0.0221 v -0.905491 l -0.12867927,-0.0221 V 10.91063 h 0.12867927 0.39945835 q 0.12315317,0 0.20999194,0.04342 0.0868388,0.04263 0.13262649,0.119996 0.0457877,0.07737 0.0457877,0.180782 0,0.104207 -0.0457877,0.181572 -0.0457877,0.07737 -0.13262649,0.119996 -0.0868388,0.04263 -0.20999194,0.04263 h -0.243938 v 0.33946 l 0.12867927,0.0221 v 0.09947 z m 0.28419962,-0.58261 h 0.243938 q 0.11683762,0 0.17446699,-0.06237 0.0584188,-0.06316 0.0584188,-0.158678 0,-0.09552 -0.0584188,-0.159467 -0.0576294,-0.06395 -0.17446699,-0.06395 h -0.243938 z"/>
    <path d="m 0.92198088,12.06006 v -0.09947 l 0.12867932,-0.0221 v -0.610239 l -0.12867932,-0.0221 V 11.205882 H 1.1896022 l 0.013421,0.108943 0.00158,0.015 q 0.036314,-0.06552 0.089207,-0.102627 0.053682,-0.0371 0.1223637,-0.0371 0.024473,0 0.049735,0.0047 0.026052,0.0039 0.037104,0.0079 l -0.020525,0.144468 -0.1081538,-0.0063 q -0.061577,-0.0039 -0.103417,0.02842 -0.04184,0.03158 -0.064734,0.08526 v 0.483929 l 0.1286793,0.0221 v 0.09947 z"/>
    <path d="m 1.9695723,12.076638 q -0.1184165,0 -0.2044659,-0.05526 -0.08526,-0.05605 -0.131837,-0.153942 -0.046577,-0.09868 -0.046577,-0.224991 v -0.01737 q 0,-0.126311 0.046577,-0.224202 0.046577,-0.09868 0.131837,-0.154731 0.086049,-0.05605 0.202887,-0.05605 0.1184165,0 0.2036764,0.05605 0.086049,0.05605 0.1326265,0.153942 0.046577,0.09789 0.046577,0.224991 v 0.01737 q 0,0.1271 -0.046577,0.225781 -0.046577,0.09789 -0.1318371,0.153152 -0.08526,0.05526 -0.2028869,0.05526 z m 0,-0.121574 q 0.074208,0 0.1239426,-0.04026 0.050524,-0.04105 0.075787,-0.111312 0.026052,-0.07105 0.026052,-0.161046 v -0.01737 q 0,-0.09 -0.026052,-0.160257 -0.025262,-0.07105 -0.075787,-0.111311 -0.050524,-0.04105 -0.1255215,-0.04105 -0.074208,0 -0.1247321,0.04105 -0.050524,0.04026 -0.075786,0.111311 -0.025262,0.07026 -0.025262,0.160257 v 0.01737 q 0,0.09 0.025262,0.161046 0.025262,0.07026 0.075786,0.111312 0.051314,0.04026 0.126311,0.04026 z"/>
    <path d="m 2.7969089,12.06006 -0.3039357,-0.742077 -0.088418,-0.01184 v -0.100259 h 0.3584073 v 0.100259 l -0.1113116,0.01579 0.1894664,0.486297 0.022105,0.06868 h 0.00474 l 0.025262,-0.06868 0.1760459,-0.486297 -0.1144693,-0.01579 v -0.100259 h 0.3576178 v 0.100259 l -0.089996,0.01184 -0.2889363,0.742077 z" />
    <path d="m 3.3905704,12.06006 v -0.09947 l 0.1286792,-0.0221 v -0.610239 l -0.1286792,-0.0221 V 11.205882 H 3.67477 v 0.732603 l 0.1286792,0.0221 v 0.09947 z M 3.5113552,10.9951 V 10.828528 H 3.67477 V 10.9951 Z" />
    <path d="m 4.2463268,12.076638 q -0.1042066,0 -0.1792037,-0.05131 -0.074208,-0.0521 -0.1136798,-0.144468 -0.039472,-0.09236 -0.039472,-0.215518 v -0.01658 q 0,-0.137364 0.039472,-0.240781 0.039472,-0.103417 0.1144693,-0.160257 0.074997,-0.05763 0.179993,-0.05763 0.075787,0 0.133416,0.02842 0.058419,0.02763 0.09947,0.08052 V 10.95089 L 4.3521124,10.92879 V 10.828531 H 4.4807916 4.636312 v 1.109957 l 0.1286793,0.0221 v 0.09947 H 4.501317 L 4.488686,11.958219 q -0.041841,0.05842 -0.1026277,0.08842 -0.059998,0.03 -0.1397314,0.03 z m 0.039472,-0.126311 q 0.069471,0 0.1168376,-0.03158 0.047367,-0.03158 0.078155,-0.08921 v -0.3963 q -0.029999,-0.05368 -0.078155,-0.08526 -0.048156,-0.03158 -0.1152587,-0.03158 -0.074208,0 -0.1223637,0.04263 -0.048156,0.04184 -0.071839,0.116837 -0.023683,0.07421 -0.023683,0.172889 v 0.01658 q 0,0.128679 0.052893,0.206834 0.052893,0.07815 0.1634148,0.07815 z" />
    <path d="m 4.8684086,12.06006 v -0.09947 l 0.1286793,-0.0221 v -0.610239 l -0.1286793,-0.0221 v -0.100259 h 0.2841996 v 0.732603 l 0.1286793,0.0221 v 0.09947 z M 4.9891935,10.9951 V 10.828528 H 5.1526082 V 10.9951 Z" />
    <path d="m 5.3886518,12.06006 v -0.09947 l 0.1286793,-0.0221 v -0.610239 l -0.1286793,-0.0221 v -0.100259 h 0.2676213 l 0.011052,0.1271 q 0.04263,-0.06789 0.1065749,-0.104996 0.064734,-0.03789 0.1468365,-0.03789 0.1381526,0 0.2139391,0.08131 0.075787,0.08052 0.075787,0.249464 v 0.417615 l 0.1286793,0.0221 v 0.09947 H 5.9262628 v -0.09947 l 0.1286792,-0.0221 v -0.414458 q 0,-0.11289 -0.044998,-0.160257 -0.044209,-0.04737 -0.1357843,-0.04737 -0.067103,0 -0.1192059,0.03237 -0.051314,0.03237 -0.082102,0.08842 v 0.501296 l 0.1286792,0.0221 v 0.09947 z" />
    <path d="m 6.8183335,12.405046 q -0.061577,0 -0.1334159,-0.01737 -0.071839,-0.01658 -0.1263109,-0.04579 l 0.040262,-0.120785 q 0.043419,0.02289 0.1026276,0.03789 0.059208,0.015 0.1152587,0.015 0.1042066,0 0.1523626,-0.05842 0.048945,-0.05842 0.048945,-0.166573 v -0.08131 q -0.041841,0.05368 -0.1002593,0.08131 -0.058419,0.02763 -0.1342054,0.02763 -0.1042065,0 -0.1799931,-0.05131 -0.075786,-0.0521 -0.1168376,-0.144468 -0.040262,-0.09315 -0.040262,-0.215518 v -0.01658 q 0,-0.137364 0.040262,-0.240781 0.041051,-0.103417 0.1168376,-0.160257 0.075787,-0.05763 0.181572,-0.05763 0.080523,0 0.1413104,0.03158 0.060787,0.03158 0.1018382,0.09157 l 0.01421,-0.107364 H 7.173583 v 0.843125 q 0,0.110522 -0.041841,0.190256 -0.041051,0.07973 -0.1207848,0.122364 -0.078944,0.04342 -0.1926242,0.04342 z m 0.00395,-0.454719 q 0.07026,0 0.1176271,-0.03158 0.047367,-0.03237 0.078155,-0.09079 V 11.43482 Q 6.9880666,11.38035 6.9399106,11.34877 6.8917546,11.3164 6.8238624,11.3164 q -0.074208,0 -0.1239426,0.04263 -0.048946,0.04263 -0.073418,0.117627 -0.024473,0.07421 -0.024473,0.172099 v 0.01658 q 0,0.128679 0.054472,0.206834 0.054472,0.07815 0.1657831,0.07815 z m 0.327619,-0.617345 -0.060787,-0.1271 h 0.1902559 v 0.09947 z" />
    <path d="m 7.7625079,12.06006 v -0.09947 l 0.1286793,-0.0221 v -0.905491 l -0.1286793,-0.0221 V 10.91063 h 0.85102 v 0.276305 H 8.4887958 l -0.017368,-0.15631 H 8.0467076 v 0.374197 h 0.4239311 v 0.122363 H 8.0467076 v 0.416037 h 0.4499827 l 0.017368,-0.158678 h 0.1239426 v 0.275516 z" />
    <path d="m 8.7643115,12.06006 v -0.09947 l 0.094733,-0.01816 0.2549902,-0.314198 -0.2494641,-0.303936 -0.08447,-0.01816 v -0.100259 h 0.334724 v 0.100259 l -0.08526,0.0095 0.1752565,0.217887 0.1705197,-0.218676 -0.091575,-0.0087 v -0.100259 h 0.3291979 v 0.100259 l -0.086839,0.01816 -0.2313069,0.301568 0.2660424,0.316566 0.088418,0.01816 v 0.09947 H 9.286923 v -0.09947 l 0.1026276,-0.0087 -0.1870981,-0.228149 -0.1815719,0.228149 0.092365,0.0087 v 0.09947 z" />
    <path d="m 9.7163804,12.388468 v -0.100259 l 0.1207848,-0.0221 v -0.937858 l -0.1286793,-0.0221 v -0.100259 h 0.2620952 l 0.01421,0.104206 q 0.04263,-0.05842 0.1034169,-0.08921 0.06158,-0.03079 0.1421,-0.03079 0.105785,0 0.180783,0.05763 0.075,0.05684 0.114469,0.160257 0.03947,0.102628 0.03947,0.240781 v 0.01658 q 0,0.123153 -0.04026,0.215518 -0.03947,0.09237 -0.113679,0.144468 -0.07421,0.05131 -0.178415,0.05131 -0.07894,0 -0.138942,-0.02605 -0.05921,-0.02684 -0.1010485,-0.07894 v 0.294462 l 0.1357845,0.0221 v 0.100259 z M 10.18452,11.955064 q 0.110522,0 0.167362,-0.08052 0.05684,-0.08052 0.05684,-0.209202 v -0.01658 q 0,-0.09789 -0.02526,-0.172099 -0.02526,-0.075 -0.07579,-0.117627 -0.04973,-0.04263 -0.124732,-0.04263 -0.06631,0 -0.113679,0.03 -0.04737,0.03 -0.076576,0.0821 v 0.416037 q 0.02921,0.05289 0.075786,0.0821 0.04737,0.02842 0.116048,0.02842 z" />
    <path d="m 11.105011,12.076638 q -0.118416,0 -0.206045,-0.05368 -0.08763,-0.05447 -0.135784,-0.150784 -0.04737,-0.09631 -0.04737,-0.222623 v -0.03474 q 0,-0.121574 0.04973,-0.217096 0.05053,-0.09631 0.133416,-0.151574 0.08368,-0.05605 0.182362,-0.05605 0.115259,0 0.192624,0.04737 0.07816,0.04737 0.117627,0.133415 0.03947,0.08526 0.03947,0.201309 v 0.0971 h -0.554189 l -0.0024,0.0039 q 0.0016,0.0821 0.02842,0.146047 0.02763,0.06316 0.07894,0.09947 0.05131,0.03632 0.123153,0.03632 0.07894,0 0.138153,-0.0221 0.06,-0.02289 0.103417,-0.06237 l 0.06079,0.101049 q -0.04579,0.04421 -0.121574,0.075 -0.075,0.03 -0.180783,0.03 z m -0.223412,-0.528927 h 0.393932 v -0.02053 q 0,-0.06158 -0.02211,-0.109732 -0.02131,-0.04895 -0.06473,-0.07658 -0.04263,-0.02842 -0.107364,-0.02842 -0.0521,0 -0.09473,0.03079 -0.04263,0.03 -0.07026,0.0821 -0.02763,0.0521 -0.03631,0.118417 z" />
    <path d="m 11.551047,12.06006 v -0.09947 l 0.128679,-0.0221 v -0.610239 l -0.128679,-0.0221 v -0.100259 h 0.267621 l 0.01342,0.108943 0.0016,0.015 q 0.03632,-0.06552 0.08921,-0.102627 0.05368,-0.0371 0.122363,-0.0371 0.02447,0 0.04974,0.0047 0.02605,0.0039 0.0371,0.0079 l -0.02053,0.144468 -0.108154,-0.0063 q -0.06158,-0.0039 -0.103417,0.02842 -0.04184,0.03158 -0.06474,0.08526 v 0.483929 l 0.12868,0.0221 v 0.09947 z"/>
    <path d="m 12.555219,12.07348 q -0.09473,0 -0.150784,-0.05526 -0.05526,-0.05605 -0.05526,-0.178414 V 11.321142 H 12.21418 v -0.115259 h 0.134995 v -0.206045 h 0.155521 v 0.206045 h 0.185519 v 0.115259 h -0.185519 v 0.518664 q 0,0.06158 0.02447,0.09079 0.02447,0.02921 0.06552,0.02921 0.02763,0 0.06158,-0.0047 0.03395,-0.0055 0.05289,-0.0095 l 0.02131,0.101838 q -0.03395,0.01026 -0.08368,0.01816 -0.04894,0.0079 -0.09158,0.0079 z" />
    <path d="m 13.757541,12.076638 q -0.172888,0 -0.288936,-0.07579 -0.115259,-0.07579 -0.173678,-0.207623 -0.05763,-0.131837 -0.05763,-0.299199 v -0.01658 q 0,-0.168941 0.06079,-0.299988 0.06079,-0.131837 0.174467,-0.207624 0.11368,-0.07579 0.271568,-0.07579 0.111312,0 0.203677,0.03473 0.09237,0.03474 0.153152,0.08842 v 0.247885 h -0.123943 l -0.01658,-0.181572 q -0.02526,-0.01974 -0.05842,-0.03316 -0.03237,-0.01342 -0.07263,-0.02053 -0.03947,-0.0071 -0.08605,-0.0071 -0.11289,0 -0.191045,0.05842 -0.07816,0.05763 -0.118417,0.159467 -0.04026,0.101839 -0.04026,0.235254 v 0.01816 q 0,0.144468 0.04263,0.247095 0.04342,0.101839 0.125522,0.155521 0.08289,0.05368 0.201308,0.05368 0.05526,0 0.108154,-0.01342 0.05289,-0.01342 0.08763,-0.03474 l 0.01737,-0.160257 h 0.121574 v 0.249464 q -0.06473,0.04026 -0.154731,0.06316 -0.09,0.02211 -0.185519,0.02211 z" />
    <path d="m 14.528827,12.076638 q -0.133416,0 -0.202887,-0.06473 -0.06947,-0.06552 -0.06947,-0.183941 0,-0.08131 0.04421,-0.14131 0.045,-0.06079 0.129468,-0.09394 0.08447,-0.03395 0.202098,-0.03395 h 0.152362 v -0.08368 q 0,-0.07737 -0.04737,-0.119995 -0.04658,-0.04263 -0.131837,-0.04263 -0.05368,0 -0.09316,0.01342 -0.03947,0.01263 -0.07184,0.03552 l -0.01658,0.119206 h -0.119996 v -0.195782 q 0.05763,-0.04658 0.134206,-0.07026 0.07658,-0.02447 0.16973,-0.02447 0.15552,0 0.243938,0.07421 0.08842,0.07421 0.08842,0.21236 v 0.4113 q 0,0.01658 0,0.03237 7.89e-4,0.01579 0.0024,0.03158 l 0.08289,0.0087 v 0.09947 h -0.223413 q -0.0071,-0.03474 -0.01105,-0.06237 -0.0039,-0.02763 -0.0055,-0.05526 -0.045,0.05763 -0.112891,0.09631 -0.0671,0.03789 -0.143678,0.03789 z m 0.02289,-0.130258 q 0.07736,0 0.14131,-0.0371 0.06395,-0.0371 0.09158,-0.08921 v -0.154731 h -0.157888 q -0.107365,0 -0.161047,0.05131 -0.05368,0.05131 -0.05368,0.114469 0,0.05605 0.03474,0.08605 0.03473,0.02921 0.104996,0.02921 z" />
    <path d="m 15.144593,12.06006 v -0.09947 l 0.128679,-0.0221 v -0.610239 l -0.128679,-0.0221 v -0.100259 h 0.267621 l 0.01342,0.108943 0.0016,0.015 q 0.03632,-0.06552 0.08921,-0.102627 0.05368,-0.0371 0.122363,-0.0371 0.02447,0 0.04974,0.0047 0.02605,0.0039 0.0371,0.0079 l -0.02053,0.144468 -0.108153,-0.0063 q -0.06158,-0.0039 -0.103417,0.02842 -0.04184,0.03158 -0.06474,0.08526 v 0.483929 l 0.12868,0.0221 v 0.09947 z" />
    <path d="m 16.195342,12.076638 q -0.118416,0 -0.206045,-0.05368 -0.08763,-0.05447 -0.135784,-0.150784 -0.04737,-0.09631 -0.04737,-0.222623 v -0.03474 q 0,-0.121574 0.04974,-0.217096 0.05052,-0.09631 0.133415,-0.151574 0.08368,-0.05605 0.182362,-0.05605 0.115259,0 0.192624,0.04737 0.07815,0.04737 0.117627,0.133415 0.03947,0.08526 0.03947,0.201309 v 0.0971 h -0.554189 l -0.0024,0.0039 q 0.0016,0.0821 0.02842,0.146047 0.02763,0.06316 0.07894,0.09947 0.05131,0.03632 0.123153,0.03632 0.07895,0 0.138153,-0.0221 0.06,-0.02289 0.103417,-0.06237 l 0.06079,0.101049 q -0.04579,0.04421 -0.121574,0.075 -0.075,0.03 -0.180783,0.03 z M 15.97193,11.547711 h 0.393932 v -0.02053 q 0,-0.06158 -0.0221,-0.109732 -0.02132,-0.04895 -0.06474,-0.07658 -0.04263,-0.02842 -0.107364,-0.02842 -0.0521,0 -0.09473,0.03079 -0.04263,0.03 -0.07026,0.0821 -0.02763,0.0521 -0.03631,0.118417 z" />
    <path d="m 17.040836,12.06006 v -0.09947 l 0.128679,-0.0221 V 11.321141 H 17.0361 v -0.115259 h 0.133415 v -0.108154 q 0,-0.136574 0.07105,-0.210781 0.07184,-0.075 0.19973,-0.075 0.02684,0 0.05763,0.0047 0.03158,0.0039 0.06473,0.01184 l -0.01895,0.118416 q -0.01421,-0.0032 -0.03868,-0.0055 -0.02368,-0.0024 -0.045,-0.0024 -0.06868,0 -0.101839,0.04105 -0.03316,0.04026 -0.03316,0.117627 v 0.108154 h 0.186308 v 0.115259 h -0.186308 v 0.617344 l 0.146836,0.0221 v 0.09947 z" />
    <path d="m 17.995273,12.076638 q -0.118417,0 -0.204466,-0.05526 -0.08526,-0.05605 -0.131837,-0.153942 -0.04658,-0.09868 -0.04658,-0.224991 v -0.01737 q 0,-0.126311 0.04658,-0.224202 0.04658,-0.09868 0.131837,-0.154731 0.08605,-0.05605 0.202887,-0.05605 0.118416,0 0.203676,0.05605 0.08605,0.05605 0.132627,0.153942 0.04658,0.09789 0.04658,0.224991 v 0.01737 q 0,0.1271 -0.04658,0.225781 -0.04658,0.09789 -0.131837,0.153152 -0.08526,0.05526 -0.202887,0.05526 z m 0,-0.121574 q 0.07421,0 0.123942,-0.04026 0.05053,-0.04105 0.07579,-0.111312 0.02605,-0.07105 0.02605,-0.161046 v -0.01737 q 0,-0.09 -0.02605,-0.160257 -0.02526,-0.07105 -0.07579,-0.111311 -0.05052,-0.04105 -0.125521,-0.04105 -0.07421,0 -0.124732,0.04105 -0.05053,0.04026 -0.07579,0.111311 -0.02526,0.07026 -0.02526,0.160257 v 0.01737 q 0,0.09 0.02526,0.161046 0.02526,0.07026 0.07579,0.111312 0.05131,0.04026 0.126311,0.04026 z" />
    <path d="m 18.494991,12.06006 v -0.09947 l 0.128679,-0.0221 v -0.610239 l -0.128679,-0.0221 v -0.100259 h 0.267621 l 0.01342,0.108943 0.0016,0.015 q 0.03631,-0.06552 0.08921,-0.102627 0.05368,-0.0371 0.122363,-0.0371 0.02447,0 0.04974,0.0047 0.02605,0.0039 0.0371,0.0079 l -0.02052,0.144468 -0.108154,-0.0063 q -0.06158,-0.0039 -0.103417,0.02842 -0.04184,0.03158 -0.06473,0.08526 v 0.483929 l 0.12868,0.0221 v 0.09947 z" />
    <path d="m 19.839413,11.944801 v -0.32683 h -0.285778 v -0.198939 h 0.285778 v -0.311041 h 0.218676 v 0.311041 h 0.28341 v 0.198939 h -0.28341 v 0.32683 z" />
    <path d="m 20.444126,12.06006 v -0.150784 l 0.372618,-0.400248 q 0.05684,-0.06395 0.08921,-0.111311 0.03237,-0.04737 0.04579,-0.08605 0.01421,-0.03947 0.01421,-0.07816 0,-0.0671 -0.03868,-0.11368 -0.03789,-0.04658 -0.112101,-0.04658 -0.07973,0 -0.120785,0.0521 -0.04105,0.05131 -0.04105,0.142099 h -0.224202 l -0.0016,-0.0047 q -0.0024,-0.104207 0.04421,-0.187098 0.04737,-0.08368 0.135784,-0.132627 0.08921,-0.04894 0.209992,-0.04894 0.118417,0 0.202887,0.04263 0.08526,0.04184 0.131048,0.117627 0.04579,0.07579 0.04579,0.175256 0,0.06868 -0.02447,0.129469 -0.02447,0.06 -0.07658,0.1271 -0.0521,0.0671 -0.134206,0.153942 l -0.223412,0.23999 0.0016,0.0039 h 0.29762 l 0.01184,-0.109733 h 0.178414 v 0.285779 z" />
    <path d="m 21.746707,12.076638 q -0.179993,0 -0.284989,-0.117627 -0.104996,-0.118417 -0.104996,-0.346566 v -0.253411 q 0,-0.22736 0.104207,-0.345776 0.104996,-0.119206 0.284199,-0.119206 0.178414,0 0.28341,0.119206 0.105786,0.118416 0.105786,0.345776 v 0.253411 q 0,0.228149 -0.104996,0.346566 -0.104207,0.117627 -0.282621,0.117627 z m 0,-0.176835 q 0.07737,0 0.117627,-0.06316 0.04026,-0.06316 0.04026,-0.202097 v -0.299199 q 0,-0.137364 -0.04105,-0.200519 -0.04105,-0.06316 -0.118417,-0.06316 -0.07816,0 -0.118416,0.06316 -0.04026,0.06316 -0.04026,0.200519 v 0.299199 q 0,0.138942 0.04026,0.202097 0.04105,0.06316 0.119995,0.06316 z" />
    <path d="m 22.889823,12.405046 q -0.03,0 -0.06395,-0.0055 -0.03316,-0.0047 -0.06316,-0.01342 l 0.02605,-0.168941 q 0.015,0.0032 0.03316,0.0047 0.01816,0.0016 0.03158,0.0016 0.04973,0 0.08605,-0.02605 0.03631,-0.02605 0.05289,-0.075 l 0.01895,-0.05289 -0.285779,-0.70971 -0.104996,-0.015 v -0.138942 h 0.453141 v 0.138942 l -0.104207,0.01579 0.134206,0.376564 0.01658,0.04658 h 0.0047 l 0.158678,-0.42472 -0.101838,-0.01421 v -0.138942 h 0.422352 v 0.138942 l -0.09631,0.01342 -0.32604,0.828916 q -0.02368,0.05921 -0.06079,0.108154 -0.03632,0.04974 -0.09315,0.07973 -0.05605,0.03 -0.138152,0.03 z" />
    <path d="m 24.059777,12.076638 q -0.122363,0 -0.213939,-0.05368 -0.09157,-0.05447 -0.142099,-0.149205 -0.04973,-0.09473 -0.04973,-0.216308 v -0.03158 q 0,-0.127101 0.04737,-0.224992 0.04737,-0.09868 0.133416,-0.154731 0.08684,-0.05684 0.204466,-0.05605 0.116048,0 0.194992,0.04579 0.07895,0.04579 0.119996,0.131837 0.04105,0.08605 0.04105,0.208413 v 0.125521 h -0.502086 l -0.0016,0.0047 q 0.0047,0.05605 0.02763,0.100259 0.02368,0.04342 0.06631,0.06868 0.04263,0.02447 0.103417,0.02447 0.06316,0 0.117627,-0.01579 0.05526,-0.01658 0.107364,-0.04816 l 0.06237,0.142099 q -0.05289,0.04263 -0.133416,0.07105 -0.07973,0.02763 -0.183151,0.02763 z M 23.894784,11.54929 h 0.284989 v -0.02053 q 0,-0.0521 -0.01421,-0.08921 -0.01342,-0.03789 -0.04421,-0.05842 -0.03,-0.02052 -0.07815,-0.02052 -0.04579,0 -0.07737,0.02447 -0.03158,0.02368 -0.04974,0.06552 -0.01737,0.04184 -0.02368,0.09473 z" />
    <path d="m 24.789222,12.076638 q -0.1271,0 -0.202097,-0.06789 -0.075,-0.06868 -0.075,-0.186309 0,-0.08052 0.04342,-0.14131 0.04421,-0.06079 0.129469,-0.09552 0.08605,-0.03473 0.211571,-0.03473 h 0.11289 v -0.06316 q 0,-0.06158 -0.03631,-0.09947 -0.03552,-0.03868 -0.107365,-0.03868 -0.0371,0 -0.0671,0.0095 -0.02921,0.0087 -0.05289,0.02526 l -0.01816,0.11289 h -0.165783 l -0.0032,-0.221833 q 0.0671,-0.03789 0.145258,-0.06158 0.07815,-0.02368 0.174467,-0.02368 0.167362,0 0.264463,0.07816 0.0971,0.07737 0.0971,0.221833 v 0.352092 q 0,0.01737 0,0.03474 7.89e-4,0.01658 0.0032,0.03237 l 0.08921,0.01263 v 0.138153 h -0.285779 q -0.0087,-0.0221 -0.01737,-0.04737 -0.0087,-0.02526 -0.01263,-0.05052 -0.04105,0.0521 -0.0971,0.08368 -0.05526,0.03079 -0.130259,0.03079 z m 0.06158,-0.17052 q 0.04816,0 0.09158,-0.02131 0.04342,-0.0221 0.0671,-0.05763 v -0.134995 h -0.11368 q -0.07579,0 -0.115258,0.03474 -0.03868,0.03474 -0.03868,0.08368 0,0.04579 0.02842,0.07105 0.02921,0.02447 0.08052,0.02447 z" />
    <path d="m 25.433409,12.06006 v -0.138153 l 0.110522,-0.02368 V 11.36851 l -0.122363,-0.02368 v -0.138942 h 0.337092 l 0.0087,0.105785 0.0016,0.01895 q 0.03158,-0.06947 0.07815,-0.104996 0.04658,-0.03553 0.111311,-0.03553 0.02053,0 0.045,0.0039 0.02447,0.0032 0.04342,0.0087 l -0.02447,0.21236 -0.114469,-0.0063 q -0.04895,-0.0024 -0.07973,0.01658 -0.03,0.01895 -0.05368,0.05447 v 0.418405 l 0.110522,0.02368 v 0.138153 z" />
    <path d="m 26.483368,12.076638 q -0.09552,0 -0.178414,-0.01816 -0.0821,-0.01816 -0.157889,-0.0521 l -0.0024,-0.241569 h 0.160257 l 0.03079,0.123153 q 0.02684,0.01342 0.05842,0.02131 0.03237,0.0079 0.07342,0.0079 0.07736,0 0.108943,-0.02526 0.03158,-0.02526 0.03158,-0.06316 0,-0.03553 -0.03395,-0.06237 -0.03316,-0.02763 -0.133416,-0.04816 -0.151573,-0.03158 -0.223412,-0.09789 -0.07184,-0.06631 -0.07184,-0.169731 0,-0.07105 0.03552,-0.129468 0.03631,-0.05842 0.109732,-0.09394 0.07421,-0.03552 0.189467,-0.03552 0.09631,0 0.176045,0.01895 0.08052,0.01816 0.134995,0.04816 l 0.0024,0.233675 h -0.159468 l -0.02447,-0.112101 q -0.02132,-0.01579 -0.05052,-0.02368 -0.02842,-0.0079 -0.06473,-0.0079 -0.06079,0 -0.09315,0.02447 -0.03237,0.02447 -0.03237,0.06316 0,0.0221 0.01105,0.04105 0.01184,0.01895 0.04579,0.03553 0.03395,0.01658 0.09868,0.03158 0.158679,0.03631 0.231307,0.0971 0.07263,0.06 0.07263,0.16894 0,0.119206 -0.08684,0.192625 -0.08605,0.07342 -0.258148,0.07342 z" />
  </g>
        </g>
      </svg>
    </StyledLogo>
  );
};

export default Logo;
