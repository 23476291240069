import { useStaticQuery, graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';

export interface EmployeeImage {
  name: string;
  src: FluidObject;
}

export const useTeamImages = () => {
  const {
    image1,
    image2,
    image6,
    image9,
    image11,
    image12,
    image13,
    image15,
    image16,
    image17,
    image18,
    image20,
    image21,
    image23,
    image24,
  } = useStaticQuery(graphql`
    query TeamImages {
      image1: file(relativePath: { eq: "about/team/humberto-santos-silva.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "about/team/gracinda-magalhaes.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image6: file(relativePath: { eq: "about/team/fatima-mendes.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image9: file(relativePath: { eq: "about/team/carla-sofia-martins.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image11: file(relativePath: { eq: "about/team/marilia-martins.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image12: file(relativePath: { eq: "about/team/marcia-martins.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image13: file(relativePath: { eq: "about/team/alexandra-correia.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image15: file(relativePath: { eq: "about/team/sara-saldanha-lopes.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    image16: file(relativePath: { eq: "about/team/sofia-gaio.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image17: file(relativePath: { eq: "about/team/jessica-correia.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image18: file(relativePath: { eq: "about/team/mariana-rosa.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image20: file(relativePath: { eq: "about/team/bruno-santos.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image21: file(relativePath: { eq: "about/team/jose-saraiva.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image23: file(relativePath: { eq: "about/team/kateryna-varhotska.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image24: file(relativePath: { eq: "about/team/diogo-veiga.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`);



  return [
    { name: 'humberto-santos-silva', src: image1.childImageSharp.fluid },
    { name: 'gracinda-magalhaes', src: image2.childImageSharp.fluid },
    { name: 'fatima-mendes', src: image6.childImageSharp.fluid },
    { name: 'carla-sofia-martins', src: image9.childImageSharp.fluid },
    { name: 'marilia-martins', src: image11.childImageSharp.fluid },
    { name: 'marcia-martins', src: image12.childImageSharp.fluid },
    { name: 'alexandra-correia', src: image13.childImageSharp.fluid },
    { name: 'sara-saldanha-lopes', src: image15.childImageSharp.fluid },
    { name: 'sofia-gaio', src: image16.childImageSharp.fluid },
    { name: 'jessica-correia', src: image17.childImageSharp.fluid },
    { name: 'mariana-rosa', src: image18.childImageSharp.fluid },
    { name: 'bruno-santos', src: image20.childImageSharp.fluid },
    { name: 'jose-saraiva', src: image21.childImageSharp.fluid },
    { name: 'kateryna-varhotska', src: image23.childImageSharp.fluid },
    { name: 'diogo-veiga', src: image24.childImageSharp.fluid },
    ];
};
