import React from 'react';
import styled from 'styled-components';
import { MainText, SectionHeading } from './components/Layout.styles';
import { colors, gridMobile, grid, gridTablet } from './components/Variables';

export const Heading = styled.h1`
  position: relative;
  font-family: RobotoSlab-Regular, sans-serif;
  font-size: 40px;
  color: ${colors.black};
  white-space: pre-line;
  z-index: 1;
  margin-top: 15vh;
  padding-left: 5px;
`;


export const MainSection = styled.section`
  justify-content: center;
  padding-left: 60px;
  padding-right: 60px;
  scroll-snap-align: unset;
  scroll-snap-stop: unset;
  overscroll-behavior: none;

  strong {
    font-family: RobotoSlab-bold, sans-serif;
  }

`;