import styled from 'styled-components';
import { MainText, Section } from '../Layout.styles';
import { gridTablet, grid } from '../Variables';

export const SectionHeritage = styled(Section)`
  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    position: relative;
    margin-bottom: 0;
    z-index: 2;
  }

  ${MainText} {
    width: calc(${grid.col13});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      width: calc(${gridTablet.col10});
    }
  }

  h5 {
    display: inline-block;
    margin-bottom: 30px;

    @media (min-width: 1400px) {
      margin-bottom: 50px;
    }

    @media (min-width: 1920px) {
      margin-bottom: 60px;
    }

    & + h5 {
      width: calc(${grid.col1});
      margin-right: calc(${grid.col8});
      float: right;
      white-space: nowrap;

      @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
        display: none;
      }

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        width: auto;
        margin-right: 0;
      }
    }
  }
`;
