import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { PagesEmployee } from './Team';
import { EmployeeImage, useTeamImages } from './Team.images';
import { EmployeePhoto } from './Team.styles';
import {
  EmployeeAdditional,
  EmployeeBio,
  EmployeePageContent,
  EmployeeName,
  EmployeePagePhoto,
  StyledEmployeePage,
} from './TeamEmployeePage.styles';
import { PageSectionContext, Pages, LocaleContext, ScrollbarWidthContext } from '../layout';
import { SectionHeading } from '../Layout.styles';
import Modal from '../Modal/Modal';
import SEO from '../seo';

const useEmployee = (slug: string | undefined) => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          pages {
            name
            slug
            employee
          }
        }
      }
    }
  `);
  return site.siteMetadata.pages.filter((p: Pages) => p && p.employee && p.slug === slug);
};

const TeamEmployeePage: React.FC<RouteComponentProps> = ({ location }) => {
  const [locale] = useContext(LocaleContext);
  const slug = location && location.pathname.replace(/\/$/g, '');
  const employee = useEmployee(slug && slug.replace(locale, ''));
  const image = useTeamImages().filter((img: EmployeeImage) =>
    slug ? img.name === slug.replace(`${locale}/about/`, '') : ''
  )[0];
  const setPageSection = useContext(PageSectionContext)[1];
  const scrollbarWidth = useContext(ScrollbarWidthContext);
  const { t } = useTranslation('about');
  const team = Array.from(t('teamList', { returnObjects: true })) as any[];

  const activeEmployee = team.filter(
    (e: PagesEmployee) => employee[0] && e.name === employee[0].name
  );

  const handleClose = () => {
    navigate(`${locale}/about/`);

    setPageSection(['/about/', 'the-team']);
  };

  if (employee === null) return null;

  const currentPath = location?.pathname ? location?.pathname : '';


  return (
    <>
    <SEO
      title={employee[0].name || 'Albufeira Health Institute ®'}
      description={employee[0].name + ' CV | Albufeira Health Institute ®'}
      path={currentPath}
    />
    <Modal handleClose={handleClose}>
      <StyledEmployeePage>
        <EmployeePageContent>
          <SectionHeading>
            <strong>{t('About us')}</strong> — <span>{t('The Team')}</span>
          </SectionHeading>
          {activeEmployee[0] && (
            <>
              <SectionHeading>
                <span>{activeEmployee[0].department}</span>
              </SectionHeading>
              <EmployeeName>{activeEmployee[0].name}</EmployeeName>

              <EmployeeAdditional>
                <p>{activeEmployee[0].position}</p>
                {activeEmployee[0].languages.list.length > 0 && (
                  <p>
                    <b>{activeEmployee[0].languages.title}:</b>{' '}
                    {activeEmployee[0].languages.list.join('  ').toLocaleUpperCase()}
                  </p>
                )}
              </EmployeeAdditional>
              <EmployeeBio>
                <Trans components={{ bold: <strong />, ul: <ul />, li: <li />}}>
                {activeEmployee[0].bio}
                </Trans>
                </EmployeeBio>
            </>
          )}
        </EmployeePageContent>

        <EmployeePagePhoto scrollbarWidth={scrollbarWidth}>
          {image && (
            <EmployeePhoto
              alt={activeEmployee[0] && activeEmployee[0].name}
              fluid={image.src}
              style={{
                width: `100%`,
                height: `100%`,
              }}
            />
          )}
        </EmployeePagePhoto>
      </StyledEmployeePage>
    </Modal>
    </>
  );
};

export default TeamEmployeePage;
