/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { LocaleContext } from './layout';

interface Props {
  description: string;
  meta: any;
  title: string;
  path: string;
}

function trailPath (untrailedPath: string) {
  if (untrailedPath.substr(untrailedPath.length - 1) != "/") return untrailedPath + "/";
  else return untrailedPath;
}

function delocalizePath (localizedPath: string) {
  //if (!localizedPath) return ""; // trigger when not all <SEO path=> are defined
  if (localizedPath.substring(0,3) == "/pt" || localizedPath.substring(0,3) ==  "/fr" || localizedPath.substring(0,3) == "/de") return localizedPath.substring(3);
  else return localizedPath;
};

function SEO(this: any, { description, meta, title, path }: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteURL
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const [locale] = useContext(LocaleContext);

  return (
    <Helmet
      htmlAttributes={{
        lang: locale === '' ? 'en' : locale.replace('/', ''),
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteURL + trailPath(path),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `@albufeirahealth`,
        },
        {
          name: `twitter:site`,
          content: `@albufeirahealth`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `msapplication-TileColor`,
          content: `#2d89ef`,
        },
      ].concat(meta)}>
      <link rel="mask-icon" href="/safari-pinned-tab.svg?v=73cedbcb8848041cfcda54d6360fb790" color="#00b5e2" />
      <link rel="shortcut icon" href="/favicon.ico?v=73cedbcb8848041cfcda54d6360fb790" />
      <link rel="canonical" href={`${site.siteMetadata.siteURL}${trailPath(path)}`} />
      <link rel="alternate" href={`${site.siteMetadata.siteURL}${delocalizePath(trailPath(path))}`} hrefLang="x-default" />
      <link rel="alternate" href={`${site.siteMetadata.siteURL}${delocalizePath(trailPath(path))}`} hrefLang="en" />
      <link rel="alternate" href={`${site.siteMetadata.siteURL}/pt${delocalizePath(trailPath(path))}`} hrefLang="pt" />
      <link rel="alternate" href={`${site.siteMetadata.siteURL}/fr${delocalizePath(trailPath(path))}`} hrefLang="fr" />
      <link rel="alternate" href={`${site.siteMetadata.siteURL}/de${delocalizePath(trailPath(path))}`} hrefLang="de" />
    </Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
  description: ``,
};

export default SEO;
