import React, { useContext, useEffect } from 'react';
import { ModalContext } from '../layout';
import { usePrevious } from '../../hooks';
import { duration, ModalWrapper } from './Modal.styles';

interface Props {
  children: React.ReactChild;
  handleClose: () => void;
}

const Modal = ({ children, handleClose }: Props) => {
  const [active, setActive] = useContext(ModalContext);
  const prevActive = usePrevious(active);

  useEffect(() => setActive(true), [setActive]);

  useEffect(() => {
    let timeoutID: number | undefined;
    if (prevActive && !active) timeoutID = setTimeout(() => handleClose(), duration);

    return () => clearTimeout(timeoutID);
  }, [active, prevActive, handleClose]);

  if (typeof children === 'undefined') return null;

  return <ModalWrapper active={active}>{children}</ModalWrapper>;
};

export default Modal;
