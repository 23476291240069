import React, { useState } from 'react';
import MapGL, { Marker, ViewportProps } from 'react-map-gl';
import { MapWrapper } from './ContactsMap.styles';
//import { colors } from '../Variables';

const MAPBOX_TOKEN =
  'pk.eyJ1IjoiZGltYXN1cmtvdiIsImEiOiJja2E2aGtocnAwNzB4MnFvaHlzdjUxaGs0In0.n40i-M5tBFEPyc6GpHatbA';

const Map = () => {
  const [viewport, setViewport] = useState({
    latitude: 37.092588,
    longitude: -8.249392,
    zoom: 15.4,
    bearing: 0,
    pitch: 0,
  });

  return (
    <MapWrapper>
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onViewportChange={(nextViewport: ViewportProps) => setViewport(nextViewport)}
        mapboxApiAccessToken={MAPBOX_TOKEN}
      >
        <Marker latitude={37.092588} longitude={-8.249392} offsetTop={-42} offsetLeft={-22}>
          <svg
            width="42"
            height="52"
            viewBox="0 0 42 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
  <path
     fillRule="evenodd"
     clipRule="evenodd"
     d="m 20.99995,51.0968 c 0,0 20.5807,-19.1498 20.5807,-30.5162 C 41.58065,9.21427 32.36635,0 20.99995,0 9.63362,0 0.41935,9.21427 0.41935,20.5806 c 0,11.3664 20.5806,30.5162 20.5806,30.5162 z"
     fill="#00b5e2"
     id="path1002" />
  <path
     id="path2094"
     d="m 11.843508,24.996849 6.749985,12.146412 1.868369,-3.36224 -4.688895,-8.43411 c -0.125755,-0.227946 -0.04246,-0.514512 0.184555,-0.639883 0.228645,-0.125373 0.514454,-0.04395 0.640211,0.183987 l 4.403087,7.919584 4.399817,-7.919584 c 0.125758,-0.227946 0.413201,-0.30936 0.640213,-0.183987 0.228648,0.125371 0.310305,0.411937 0.184551,0.638258 l -4.68563,8.435735 1.868373,3.36224 6.746721,-12.146412 V 9.346538 H 11.843508 Z m 11.564636,13.585756 c -0.171488,0 -0.329904,-0.09282 -0.411567,-0.242621 l -1.995757,-3.590201 -1.994125,3.590201 c -0.0833,0.149804 -0.241718,0.242621 -0.413202,0.242621 -0.171485,0 -0.329904,-0.09282 -0.4132,-0.242621 L 10.959948,25.346911 c -0.03918,-0.07002 -0.05879,-0.14979 -0.05879,-0.227946 V 8.875988 c 0,-0.258886 0.210678,-0.470552 0.47036,-0.470552 h 19.255338 c 0.26131,0 0.471994,0.211666 0.471994,0.470552 v 16.242977 c 0,0.07816 -0.02124,0.157933 -0.06043,0.226321 l -7.217081,12.994698 c -0.0833,0.149804 -0.241712,0.242621 -0.413198,0.242621"
     fill="#ffffff"
     />
</svg>
        </Marker>
      </MapGL>
    </MapWrapper>
  );
};

export default Map;
