import React from 'react';
import styled from 'styled-components';
import { Section } from '../Layout.styles';
import { colors, gridTablet, gridMobile, grid } from '../Variables';

export const Quotes = () => (
  <svg width="34" height="26" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.30442 12.885V13.7593H12.2407V26H0V13.8513C0 4.61711 4.08024 0 12.2407 0V5.15398C10.1546 5.49145 8.63599 6.28909 7.68496 7.5469C6.7646 8.77404 6.30442 10.5534 6.30442 12.885ZM27.1504 12.885V13.7593H33.0867V26H20.892V13.8513C20.892 4.61711 24.9569 0 33.0867 0V5.15398C29.1292 5.67552 27.1504 8.25251 27.1504 12.885Z"
      fill="#dedede"
    />
  </svg>
);

export const TestimonialsSection = styled(Section)`
padding-bottom: 50px;
padding-top: 90px;
font-family: RobotoSlab-Light, sans-serif;
font-size: 10px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    padding-top: 0;
    padding-bottom: 30px;
  }

  h4 {
    text-align: center;
    width: 100%;
  }

  a {
    position: relative;
    display: inline-block;
    text-decoration: none;
    transition: color 300ms ease;
    font-family: RobotoSlab-light, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.black};

    &:hover {
      color: ${colors.blue};

      span {
        border-bottom: 1px solid transparent;
      }

      @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
        color: ${colors.black};

        span {
          border-bottom: 1px solid ${colors.blue};
        }
      }

      @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        color: ${colors.black};

        span {
          border-bottom: 1px solid ${colors.blue};
        }
      }
    }

    span {
      display: inline;
      transition: border 300ms ease;
      border-bottom: 1px solid ${colors.blue};
    }
  }

`;

export const BigText = styled.div`
  width: calc(${grid.col13});

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: calc(${gridMobile.col6});
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col12});
  }

  svg {
    margin-bottom: 25px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-bottom: 30px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-bottom: 20px;
    }
  }

  h5 + & {
    margin-top: 50px;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 40px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-top: 30px;
    }
  }
`;

export const Caption = styled.p`
  margin-top: 30px;
  font-family: RobotoSlab-Light, sans-serif;
  font-size: 10px;
  line-height: 13px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    margin-top: 20px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    margin-top: 20px;
  }

  @media (min-width: 1920px) {
    font-size: 14px;
    line-height: 18px;
  }

  b {
    font-family: RobotoSlab-Regular, sans-serif;
  }
`;

export const PlainText = styled.div`
  position: relative;
  display: inline-block;
  width: calc(${grid.col7});
  vertical-align: top;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: calc(${gridMobile.col6});
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col6});
  }

  & + & {
    margin-left: calc(${grid.col1} + ${grid.gridGutter});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 50px;
      margin-left: 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-left: ${gridTablet.gridGutter};
    }
  }

  ${Caption},
  p {
    padding-left: calc(${grid.col1});

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      padding-left: 0;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      padding-left: 0;
    }
  }

  p {
    font-family: RobotoSlab-Light, sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: ${colors.black};

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (min-width: 1400px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (min-width: 1920px) {
      font-size: 22px;
      line-height: 34px;
    }
  }

  svg {
    position: absolute;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      position: static;
      display: block;
      margin-bottom: 30px;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      display: none;
    }
  }
`;

export const PlainTextContainer = styled.div`
  width: calc(${grid.col15});
  margin-top: 70px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    width: calc(${gridMobile.col6});
    margin-top: 50px;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    width: calc(${gridTablet.col12});
    margin-top: 60px;
  }

  & + ${BigText} {
    margin-top: 70px;
    padding-bottom: 50px;

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      margin-top: 60px;
    }
  }
`;
