import React from 'react';
import styled from 'styled-components';
import { gridMobile, gridTablet, grid, colors } from '../Variables';

export const Nav = styled(({ forwardRef, opened, scrollbarWidth, ...rest }) => (
  <nav ref={forwardRef} {...rest} />
))`
  position: fixed;
  top: 0;
  right: ${grid.navWidth};
  bottom: 0;
  left: 0;
  padding-right: ${(props) => props.scrollbarWidth || 0}px;
  background-color: #fff;
  z-index: 9;
  transform: translateX(${(props) => (props.opened ? 0 : 100)}vw);
  opacity: ${(props) => (props.opened ? 1 : 0)};
  transition: transform 500ms ease, opacity ${(props) => (props.opened ? 500 : 700)}ms ease;
  overflow-y: auto;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    right: 0;
    z-index: 10;
  }

  a {
    text-decoration: none;
  }
`;

export const MenuList = styled(({ IOSPadding, ...rest }) => <ul {...rest} />)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 97px;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    padding-top: 0;
    padding-bottom: ${(props) => (props.IOSPadding ? 14 : 0)}vh;
  }

  @media (min-width: 1400px) {
    padding-top: 105px;
  }
`;

export const MenuListItem = styled.li`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  border-top: 1px solid #e1e1e1;
  transition: background 300ms ease;
  white-space: pre-wrap;

  &:hover {
    background-color: #fafafa;
  }

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: calc(100vw - ${grid.navWidth});
    padding: 37px 0 35px 0;
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  & > a {
    flex: 1 0 auto;
    display: block;
    padding: 46px 0 51px calc(${grid.navWidth} + ${grid.gridMargin});
    font-family: RobotoSlab-Regular;
    font-size: 50px;
    line-height: 59px;
    color: ${colors.black};
    transition: color 300ms ease;

    &:hover {
      color: ${colors.blue};
    }

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      width: 100%;
      flex: 0 0 auto;
      padding: 0 0 0 ${gridMobile.gridMargin};
      font-size: calc(${gridMobile.col5} / 11);
      line-height: 32px;
      &:hover {
        color: ${colors.black};
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      flex: 0 0 auto;
      width: 100%;
      padding: 0 0 0 calc(${gridTablet.navWidth} + ${gridTablet.gridMargin});
      font-size: 35px;
      line-height: 46px;
      &:hover {
        color: ${colors.black};
      }
    }
  }
`;
export const MenuSubList = styled.ul`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    flex: 1 0 auto;
    margin-top: 20px;
    padding-left: ${gridMobile.gridMargin};
  }

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    margin-top: 20px;
    padding-left: calc(${grid.navWidth} + ${gridTablet.gridMargin});
  }
`;
export const MenuSubListItem = styled(({ active, ...rest }) => <li {...rest} />)`
  flex: 0 0 auto;
  margin-right: ${grid.gridMargin};

  button {
    cursor: pointer;
  }

  span {
    font-family: ${(props) => (props.active ? 'RobotoSlab-Medium' : 'RobotoSlab-Light')};
    font-size: 16px;
    line-height: 21px;
    color: ${colors.black};
    transition: color 300ms ease;

    &:hover {
      color: ${colors.blue};
    }

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 12px;
      line-height: 16px;

      &:hover {
        color: ${colors.black};
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      font-size: 12px;
      line-height: 16px;

      &:hover {
        color: ${colors.black};
      }
    }
  }
`;
