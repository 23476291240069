import styled from 'styled-components';
import { Section } from '../Layout.styles';
import { colors, grid, gridMobile } from '../Variables';

export const BG = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 300vh;
  background-color: ${colors.blue};
  opacity: 0;
  z-index: 0;
  pointer-events: none;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    top: 0;
    height: 390vh;
    background-color: transparent;
    opacity: 1;
    transition: background 300ms ease;
  }
`;

export const SectionAward = styled(Section)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  z-index: 3;

  @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
    justify-content: flex-start;
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 100vh;
    z-index: 4;
  }

  h2 {
    flex: 0 0 auto;
    font-family: RobotoSlab-Bold, sans-serif;
    font-size: calc(${grid.col16} / 8);
    color: #fff;
    z-index: 1;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      margin-top: 15vh;
      font-size: calc(${gridMobile.col5} / 6);
      line-height: 1.2;
    }
  }

  p {
    flex: 0 0 auto;
    color: #fff;

    @media (max-width: 767.98px), (max-width: 896px) and (max-height: 414px) {
      font-size: 16px;
      line-height: 23px;
    }

    @media (min-width: 1400px) and (max-width: 1919px) {
      font-size: 16px;
      line-height: 23px;
    }
  }
`;
