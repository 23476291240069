/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { Router, useLocation } from '@reach/router';
// import postcssCustomProperties from 'postcss-custom-properties';
// import cssVars from 'css-vars-ponyfill';
import smoothscroll from 'smoothscroll-polyfill';
import 'intersection-observer';
import '@ungap/global-this';
import './i18n';
import About from './About/About';
import { Employee } from './About/Team';
import TeamEmployeePage from './About/TeamEmployeePage';
import ClinicalCases from './ClinicalCases/Clinical-cases';
import Contacts from './Contacts/Contacts';
import Home from './Home/Home';
import { Main, ScrollContainer } from './Layout.styles';
import Logo from './Logo/Logo';
import Media from './Media/Media';
import MediaArticle from './Media/MediaArticle';
import Navigation from './Navigation/Navigation';
import SecondaryNavigation from './Navigation/SecondaryNavigation';
import TertiaryNavigation from './Navigation/TertiaryNavigation';
import PreferredLanguage from './PreferredLanguage';
import Services from './Services/Services';
// import TempGrid from './TempGrid';
import '../css/layout.css';
import '../css/typography.css';
import { useMobile, usePrevious, useResize } from '../hooks';
import NotFoundPage from '../pages/404';
//import { isSafari } from 'react-device-detect';

import PrivacyPolicyPage from '../pages/privacy-policy'

/* 
//simplesmente adiciona smoothscroll ao safari (link smooth scroll not "edge jump")
//e nem fica muito smooth portanto removido (continua a haver smoothscroll no Samsung/Chrome/Firefox/Opera...)
//UPDATE: voltei a por porque o menu IE e outras coisas deixavam de dar
*/
if (typeof window !== 'undefined') {
  smoothscroll.polyfill(); 
  // cssVars({ onlyLegacy: true, preserveVars: true });
}


export interface Sections {
  name: string;
  onlyLeftSide?: boolean;
  onlyRightSide?: boolean;
  slug: string;
}

export interface Pages {
  media?: boolean;
  employee?: Employee;
  name: string;
  slug: string;
  sections?: Array<Sections>;
  text?: string;
}

const useSections = (pathname: string): Array<Pages> => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          pages {
            isBlue
            name
            slug
            sections {
              name
              slug
              onlyLeftSide
              onlyRightSide
            }
          }
        }
      }
    }
  `);

  return site.siteMetadata.pages
    .filter((p: Pages) => p.slug === pathname)
    .map((p: Pages) => p.sections)[0];
};

type leftNavSections = [string[], React.Dispatch<React.SetStateAction<string[]>>];
export const LeftSectionsItemsContext = React.createContext<leftNavSections>([[], () => {}]);

type Locale = [string, [any, React.Dispatch<React.SetStateAction<any>>]];
export const LocaleContext = React.createContext<Locale>(['', [null, () => {}]]);

type Modal = [boolean, React.Dispatch<React.SetStateAction<boolean>>];
export const ModalContext = React.createContext<Modal>([false, () => {}]);

type PageSection = [string[], React.Dispatch<React.SetStateAction<string[]>>];
export const PageSectionContext = React.createContext<PageSection>([[], () => {}]);

export const ScrollbarWidthContext = React.createContext<number>(0);

export const ScrollContainerContext = React.createContext<React.RefObject<HTMLDivElement>>(
  React.createRef()
);

type Theme = [boolean, React.Dispatch<React.SetStateAction<boolean>>];
export const ThemeContext = React.createContext<Theme>([false, () => {}]);

const Layout = ({
  pageContext,
}: {
  pageContext: { name: string; slug: string; isBlue: boolean; sections: Sections[] };
}) => {
  const [isBlue, setIsBlue] = useState(false);
  const [isTouch, setTouch] = useState(false);
  const [leftNavSections, setLeftNavSections] = useState(['', '']);
  const [menuIsOpened, setMenuOpened] = useState(false);
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [pageSection, setPageSection] = useState(['', '']);
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const [W, H] = useResize();
  const isMobile = useMobile();
  const prevMenuIsOpened = usePrevious(menuIsOpened);
  const prevSlug = usePrevious(pageContext.slug);
  const prevPageSection = usePrevious(pageSection);
  const sections = useSections(pageContext.slug);

  /**
   * Close modal if change url
   */
  useEffect(() => {
    if (modalIsOpened && pageContext.slug !== prevSlug) {
      setModalIsOpened(false);

      const el = document.getElementById(pageSection[1]);
      if (scrollContainer.current && el) {
        scrollContainer.current.scrollTo({ top: el.offsetTop });
      }
    }
  }, [modalIsOpened, pageSection, pageContext, prevSlug]);

  /**
   * Detect touch device
   */
  useEffect(() => setTouch(matchMedia('(pointer: coarse)').matches), [W, H]);

  /**
   * Set Is Blue
   * Set Page section
   */
  useEffect(() => {
    if (prevSlug !== pageContext.slug) {
      setIsBlue(pageContext.isBlue);
      setPageSection(([_p, section]) => [pageContext.slug as string, section]);
    }
  }, [pageContext.isBlue, pageContext.slug, prevSlug]);

  /** Detect scrollbar width */
  useEffect(() => {
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    (outer.style as any).msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    document.body.appendChild(outer);
    const inner = document.createElement('div');
    outer.appendChild(inner);
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    outer.parentNode && outer.parentNode.removeChild(outer);
    setScrollbarWidth(scrollbarWidth);
  }, [isTouch]);

  /**
   * i18n
   */
  const { i18n } = useTranslation();
  const { lookup } = i18n.services.languageDetector.detectors.whitelist;
  const [locale, setLocale] = useState(lookup(location.pathname));
  const localeChange = useState(null);
  const lang = locale === '' ? 'en' : locale.replace('/', '');

  // update locale on location.pathname change
  useEffect(() => setLocale(lookup(location.pathname)), [lookup, location.pathname]);
  const prevLocale = usePrevious(locale);

  // force language change if no locale is specified
  useEffect(() => {
    if (locale === '' && i18n.language !== 'en') {
      i18n.changeLanguage(lang);
    }
  }, [i18n, locale, lang]);

  // force language change when SSR
  if (typeof window === 'undefined') {
    i18n.changeLanguage(lang);
  }

  /** Save scroll position when language is changed */
  useEffect(() => {
    if (prevLocale !== locale) {
      const el = document.getElementById(leftNavSections[1]);
      if (scrollContainer.current && el) scrollContainer.current.scrollTo({ top: el.offsetTop });
    }
  }, [locale, leftNavSections, prevLocale, sections]);

  /**
   * Change Page Section when scrolling (bolinhas do lado)
   */
  useEffect(() => {
    if (isMobile) return;

    /**
     *   el             (required) - HTMLElement
     *  ----------------------------------------------------------------------------------
     *   threshold      (required) - Either a single number or an array of numbers
     *                               which indicate at what percentage of the target's
     *                               visibility the observer's callback should be executed.
     * ------------------------------------------------------------------------------------
     *   onlyLeftSide   (required) - section only for TertiaryNavigation (left navigation)
     * ------------------------------------------------------------------------------------
     *   onlyRightSide   (required) - section only for SecondaryNavigation (right navigation)
     */
    function changePageSection(
      el: HTMLElement,
      threshold: number,
      onlyLeftSide: boolean | undefined,
      onlyRightSide: boolean | undefined
    ) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              /**
               * Sets left sections items
               */

              if (!onlyRightSide)
                setLeftNavSections(([_p]) => [pageContext.slug as string, entry.target.id]);

              /**
               * Sets page section
               */

              if (!onlyLeftSide)
                setPageSection(([_p]) => [pageContext.slug as string, entry.target.id]);
            }
          });
        },
        { threshold: threshold }
      );
      observer.observe(el);

      if (locale !== prevLocale) {
        observer.unobserve(el);
        observer.observe(el);
      }
    }

    sections &&
      sections.forEach((s: Sections) => {
        const el = document.getElementById(s.slug);
        const threshold = el && el.offsetHeight > H ? (H / el.offsetHeight) * 0.5 : 0.5;

        if (el) changePageSection(el, Math.min(threshold, 1), s.onlyLeftSide, s.onlyRightSide);
      });
  }, [H, isMobile, locale, prevLocale, pageContext.slug, sections]);

  /**
   * Scroll to section when menu is opened
   */
  useEffect(() => {
    if (!pageSection || !prevPageSection || !scrollContainer.current) return;

    const el = document.getElementById(pageSection[1]);

    if (!menuIsOpened && prevMenuIsOpened && pageSection[1] !== prevPageSection![1] && el) {
      scrollContainer.current.scrollTo({ top: el.offsetTop });
    }
  }, [menuIsOpened, prevMenuIsOpened, pageSection, pageContext, prevPageSection]);

  /**
   * Handle inner navigation page change
   */

  useEffect(() => {
    if (typeof prevPageSection === 'undefined') return;

    if (pageSection[0] !== prevPageSection![0] && pageSection[0] !== pageContext.slug) {
      navigate(locale + pageSection[0]);

      setTimeout(() => {
        const el = document.getElementById(pageSection[1]);
        if (scrollContainer.current && el) {
          scrollContainer.current.scrollTo({ top: el.offsetTop });
        }
      }, 1000);
    }
  }, [pageSection, prevPageSection, locale, pageContext.slug]);

/*   useEffect(() => {                                                        //ESTE CODIGO RESOLVIA O FIRST CLICK BUG MAS DEPOIS TINHAMOS O JUMP AFTER IMAGE LOAD BUG...
    if (typeof prevPageSection === 'undefined') return;

    const scrollToSection = () => {
      console.log ("scroll");
      const el = document.getElementById(pageSection[1]);
      if (scrollContainer.current && el) {
        scrollContainer.current.scrollTo({ top: el.offsetTop });
      }
    };

    if (pageSection[0] !== prevPageSection![0] && pageSection[0] !== pageContext.slug) {
      navigate(locale + pageSection[0]);
      console.log("navigate");
      scrollToSection();
      //scrollContainer.current?.addEventListener('change', scrollToSection, true);
    }

          scrollContainer.current?.removeEventListener('change', scrollToSection);

  }, [pageSection, prevPageSection, locale, pageContext.slug]); */

  const handleOpenMenu = (close?: boolean) => {
    setMenuOpened(close ? false : !menuIsOpened);
  };

  const handleClickLogo = () => {
    setMenuOpened(false);
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo({ top: 0 });
    }
  };

  return (
    <ScrollContainerContext.Provider value={scrollContainer}>
      <LocaleContext.Provider value={[locale, localeChange]}>
        <PageSectionContext.Provider value={[pageSection, setPageSection]}>
          <ThemeContext.Provider value={[isBlue, setIsBlue]}>
            <ScrollbarWidthContext.Provider value={scrollbarWidth}>
              <LeftSectionsItemsContext.Provider value={[leftNavSections, setLeftNavSections]}>
                <ModalContext.Provider value={[modalIsOpened, setModalIsOpened]}>
                  <ScrollContainer ref={scrollContainer}>
                    {/* {process.env.NODE_ENV === 'development' && <TempGrid />} */}
                    <PreferredLanguage />
                    <Logo menuIsOpened={menuIsOpened} onLogoClick={handleClickLogo} />
                    <Navigation opened={menuIsOpened} onOpenMenu={handleOpenMenu} />
                    {!isMobile && (
                      <TertiaryNavigation
                        location={pageContext.slug}
                        visible={!menuIsOpened && !isBlue}
                        withHorizontalScroll={pageContext.slug === '/'}
                      />
                    )}
                    {!isMobile && (
                      <SecondaryNavigation location={pageContext.slug} visible={!menuIsOpened} />
                    )}
                    <Main>
                      <Router>
                        <Home path={`${locale}/`} />
                        <About path={`${locale}/about`} />
                        <TeamEmployeePage path={`${locale}/about/:aboutId`} />
                        <Services path={`${locale}/services`} />
                        <ClinicalCases path={`${locale}/clinical-cases`} />
                        <Media path={`${locale}/news`} />
                        <MediaArticle path={`${locale}/news/:mediaId`} />
                        <Contacts path={`${locale}/contacts`} />
                        <NotFoundPage path={`${locale}/404`} />
                        <PrivacyPolicyPage path={`${locale}/privacy-policy`} />
                      </Router>
                    </Main>
                  </ScrollContainer>
                </ModalContext.Provider>
              </LeftSectionsItemsContext.Provider>
            </ScrollbarWidthContext.Provider>
          </ThemeContext.Provider>
        </PageSectionContext.Provider>
      </LocaleContext.Provider>
    </ScrollContainerContext.Provider>
  );
};

export default Layout;
