import React from 'react';
import { FormArrow } from './ContactsForm.styles';
import { colors } from '../Variables';

const Arrow = () => {
  return (
    <FormArrow>
      <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.30577 1.14975L0.949423 0.799006L1.30577 1.14975ZM2.01285 1.14415L2.36359 0.787802V0.787802L2.01285 1.14415ZM1.31137 1.85683L1.66211 1.50049L1.31137 1.85683ZM6.76546 7.22518L7.1162 6.86884H7.1162L6.76546 7.22518ZM7.26754 7.34549L7.41843 6.86881C7.32024 6.83772 7.21484 6.83772 7.11664 6.86881L7.26754 7.34549ZM7.76961 7.22518L7.41887 6.86884H7.41887L7.76961 7.22518ZM13.2237 1.85683L12.873 1.50049V1.50049L13.2237 1.85683ZM13.2293 1.14975L13.5857 0.799006L13.2293 1.14975ZM12.5222 1.14415L12.1715 0.787802V0.787802L12.5222 1.14415ZM7.26754 6.31622L6.9168 6.67257C7.1114 6.86411 7.42368 6.86411 7.61828 6.67257L7.26754 6.31622ZM1.66211 1.50049L1.66211 1.50049L2.36359 0.787802C1.96999 0.400384 1.33684 0.4054 0.949423 0.799006L1.66211 1.50049ZM1.66211 1.50049H1.66211L0.949423 0.799006C0.562005 1.19261 0.567021 1.82576 0.960627 2.21317L1.66211 1.50049ZM7.1162 6.86884L1.66211 1.50049L0.960627 2.21317L6.41472 7.58153L7.1162 6.86884ZM7.11664 6.86881L7.1162 6.86884L6.41472 7.58153C6.68858 7.85108 7.07792 7.92997 7.41843 7.82218L7.11664 6.86881ZM7.11664 7.82218C7.45716 7.92997 7.84649 7.85108 8.12035 7.58153L7.41887 6.86884L7.41843 6.86881L7.11664 7.82218ZM8.12035 7.58153L13.5744 2.21317L12.873 1.50049L7.41887 6.86884L8.12035 7.58153ZM13.5744 2.21318C13.9681 1.82576 13.9731 1.19261 13.5857 0.799006L12.873 1.50049V1.50049L13.5744 2.21318ZM13.5857 0.799006C13.1982 0.4054 12.5651 0.400384 12.1715 0.787802L12.873 1.50049V1.50049L13.5857 0.799006ZM12.1715 0.787802L6.9168 5.95988L7.61828 6.67257L12.873 1.50049L12.1715 0.787802ZM1.66211 1.50049L6.9168 6.67257L7.61828 5.95988L2.36359 0.787802L1.66211 1.50049Z"
          fill={colors.blue}
        />
      </svg>
    </FormArrow>
  );
};

export default Arrow;
