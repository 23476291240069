import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoomData } from './Rooms';
import { RoomsSection, RoomContent } from './Rooms.styles';
import Gallery, { GalleryImage } from '../Gallery/Gallery';
import { SectionHeading, MainText, SecondaryText } from '../Layout.styles';

interface Props {
  roomData: RoomData;
  images: GalleryImage[];
}

const Room = ({ images, roomData }: Props) => {
  const { t } = useTranslation('about');
  return (
    <RoomsSection id={`${roomData.slug}`}>
      <RoomContent>
        <SectionHeading>
          <strong>{t('About us')}</strong> — <span>{t('rooms.title')}</span>
        </SectionHeading>
        <MainText>{roomData.title}</MainText>
        <SecondaryText>{roomData.text}</SecondaryText>
      </RoomContent>
      <Gallery images={images} />
    </RoomsSection>
  );
};

export default Room;
