import React from 'react';
import { Option } from './ContactsFormData';
import { FormSelect, Placeholder } from './ContactsForm.styles';
import Arrow from './ContactsSelectArrow';

interface Props {
  options: Option[];
  placeholder: string;
  type?: string;
  value: string;
  onChange: (event: React.FormEvent<HTMLSelectElement>) => void;
}

const ContactsFormSelect = ({ options, placeholder, type, value, onChange }: Props) => {
  return (
    <FormSelect type={type}>
      <select
        name={placeholder}
        required={true}
        value={value}
        onChange={onChange}
        onBlur={onChange}
        aria-label={placeholder}
      >
        <option disabled={true} value={''}>
          {placeholder}
        </option>
        {options.map((t: Option, index: number) => (
          <option key={index} value={t.value}>
            {t.name ? t.name : t.value}
          </option>
        ))}
      </select>
      <Arrow />
    </FormSelect>
  );
};

export default ContactsFormSelect;
