import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStaticQuery, graphql } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { useServicesImages } from './Services.images';
import { BG, MainSection } from './Services.styles';
import ServicesList from './ServicesList';
import Service from './Service';
import { Pages, Sections, ThemeContext } from '../layout';
import { TopSectionHeading } from '../Layout.styles';
import SEO from '../seo';
import { useMobile, useResize, useScrollPosition } from '../../hooks';
import { isSafari } from 'react-device-detect';

const useSections = (pathname: string): Array<Pages> => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          pages {
            slug
            sections {
              slug
            }
          }
        }
      }
    }
  `);

  return site.siteMetadata.pages
    .filter((p: Pages) => p.slug === pathname)
    .map((p: Pages) => p.sections)[0];
};

const Services: React.FC<RouteComponentProps> = ({ location }) => {
  const bg = useRef<HTMLDivElement>(null);
  const [W, H] = useResize();
  const images = useServicesImages();
  const isMobile = useMobile();
  const section = useRef<HTMLElement>(null);
  const sections = useSections('/services');
  const setIsBlue = useContext(ThemeContext)[1];
  const scrollPosition = useScrollPosition();
  const { t } = useTranslation('services');

  /** Set minimal height of main section and background */
  useEffect(() => {
    if (!section.current || !bg.current) return;

    if (isMobile) {
      section.current.style.minHeight = 'unset';
      return;
    }

    // d - The difference between the main section and the window height
    const d = section.current.offsetHeight > H ? section.current.offsetHeight - H : 0;
    bg.current.style.minHeight = `calc(300vh + ${d}px)`;
    section.current.style.minHeight = d ? '200vh' : '';
  }, [isMobile, W, sections, H]);

  /** Set opacity for background and blue theme */
  useEffect(() => {
    if (!section.current || !bg.current) return;

    const { top } = section.current.getBoundingClientRect();
    const h = section.current.offsetHeight;

    let v = Math.min((h - scrollPosition) / H, 1); // Value for opacity
    bg.current.style.opacity = `${v}`; // Set opacity

    /** Set blue theme */
    const mTrigger = top <= 54 && top >= -section.current.offsetHeight + 54; // MOBILE TRIGGER
    const dTrigger = top <= h / 2 && top >= -h / 2; // DESKTOP TRIGGER
    const isBlueTheme = isMobile ? mTrigger : dTrigger;
    setIsBlue(isBlueTheme);
  }, [H, isMobile, scrollPosition, setIsBlue]);

  const currentPath = location?.pathname ? location?.pathname : '';


  return (
    <>
      <SEO title={t('servicesTitle')} description={t('mainText')} path={currentPath} />
      <MainSection ref={section} style={isSafari && !isMobile ? {scrollSnapAlign: 'center'} : {}}>
        <TopSectionHeading>
          <strong style={{color: 'white'}}> {t('Services')}</strong>
        </TopSectionHeading>
        <h6>{t('mainText')}</h6>
        <ServicesList sections={sections}/>
        <BG ref={bg} />
      </MainSection>

      {sections.map((s: Sections, index: number) => (
        <Service
          images={images[index]}
          key={s.slug}
          name={t(`servicesList.${index}.name`)}
          slug={s.slug}
          text={t(`servicesList.${index}.text`)}
        />
      ))}
    </>
  );
};

export default Services;
